import { useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Loading from "../../../components/LoadingIndicator/Loading";
import MarginTile from "./ProfitabilityTiles/MarginTile/MarginTile";
import MarginComparisionTile from "./ProfitabilityTiles/MarginComparisionTile/MarginComparisionTile";
import HighlightedExpenseTile from "./ProfitabilityTiles/HighlightedExpenseTile/HighlightedExpenseTile";
import GrowthBarChartTile from "./ProfitabilityTiles/GrowthBarChartTile/GrowthBarChartTile";
import GrowthTile from "./ProfitabilityTiles/GrowthTile/GrowthTile";
import noData from "../../../assets/no-data-found.png";
import "./Profitability.css";
import { useOutletContext } from "react-router-dom";

export default function Profitability() {
  // const sideBarOpen = useOutletContext().open;
  const response = {
    currentMonth: {
      revenue: parseInt(2000),
      grossProfit: parseInt(3000),
      netProfit: parseInt(1000),
    },
    previousMonth: {
      revenue: parseInt(4000),
      grossProfit: parseInt(200),
      netProfit: parseInt(200),
    },
  };
  const xeroConnectionStatus =
    // JSON.parse(
    //   localStorage.getItem("xeroConnectionStatus")
    // );
    true;
  const responseMonth = [
    {
      name: "Jul",
      "Gross Profit Margin": (
        (response.previousMonth.grossProfit / response.previousMonth?.revenue) *
        100
      ).toFixed(1),
      "Net Profit Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
        100
      ).toFixed(1),
      "EBITDA Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
          100 +
        23
      ).toFixed(1),
    },
    {
      name: "Aug",
      "Gross Profit Margin": (
        (response.previousMonth.grossProfit / response.previousMonth?.revenue) *
          100 +
        12
      ).toFixed(1),
      "Net Profit Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
          100 +
        34
      ).toFixed(1),
      "EBITDA Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
          100 +
        13
      ).toFixed(1),
    },
    {
      name: "Sep",
      "Gross Profit Margin": (
        (response.previousMonth.grossProfit / response.previousMonth?.revenue) *
          100 -
        3
      ).toFixed(1),
      "Net Profit Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
          100 +
        34
      ).toFixed(1),
      "EBITDA Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
          100 -
        14
      ).toFixed(1),
    },
    {
      name: "Oct",
      "Gross Profit Margin": (
        (response.currentMonth.grossProfit / response.currentMonth?.revenue) *
        100
      ).toFixed(1),
      "Net Profit Margin": (
        (response.currentMonth.netProfit / response.currentMonth?.revenue) *
        100
      ).toFixed(1),
      "EBITDA Margin": (
        (response.previousMonth.netProfit / response.previousMonth?.revenue) *
          100 +
        78
      ).toFixed(1),
    },
  ];

  const highlightedExpenses = [
    {
      type: "Rent",
      value: parseInt(1000),
      percentile: (
        (parseInt(1000) / response.currentMonth?.revenue) *
        100
      ).toFixed(2),
    },
    {
      type: "Wages",
      value: parseInt(500),
      percentile: (
        (parseInt(500) / response.currentMonth?.revenue) *
        100
      ).toFixed(2),
    },
    {
      type: "Utilities",
      value: parseInt(500),
      percentile: (
        (parseInt(500) / response.currentMonth?.revenue) *
        100
      ).toFixed(2),
    },
  ];
  // eslint-disable-next-line
  const [percentage, setPercentage] = useState(0);
  const sideBarOpen = useOutletContext().open;

  useEffect(() => {
    setTimeout(() => {
      setPercentage(67);
    }, 500);
  }, []);
  const monthFilter = [
    { option: "1 Month", value: 1 },
    { option: "3 Months", value: 3 },
    { option: "6 Months", value: 6 },
    { option: "12 Months", value: 12 },
  ];
  const isFetching = false;

  const monthly = [
    {
      name: "Jan",
      "This Year": 10,
      "Last Year": 20,
    },
    {
      name: "Feb",
      "This Year": 20,
      "Last Year": 30,
    },
    {
      name: "Mar",
      "This Year": 5,
      "Last Year": 15,
    },
    {
      name: "Apr",
      "This Year": 20,
      "Last Year": 30,
    },
    {
      name: "May",
      "This Year": 40,
      "Last Year": 50,
    },
    {
      name: "Jun",
      "This Year": 10,
      "Last Year": 40,
    },
    {
      name: "Jul",
      "This Year": 20,
      "Last Year": 10,
    },
  ];
  return (
    <div>
      {!isFetching && xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <h1 style={{ color: "#062D65" }}>Profitability</h1>
            </Grid>
            <Grid item display={"flex"} justifyContent={"center"}>
              <h3>Filter: &nbsp;</h3>
              <FormControl
                sx={{ minWidth: 125, alignSelf: "center" }}
                hiddenLabel
              >
                <InputLabel id="select-label" size="small">
                  Month
                </InputLabel>
                <Select
                  labelId="select-label"
                  size="small"
                  label="Filter"
                  defaultValue={1}
                >
                  {monthFilter.map((data) => {
                    return (
                      <MenuItem key={`month${data.value}`} value={data.value}>
                        {data.option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MarginTile />
                </Grid>
                <Grid item xs={12}>
                  <MarginComparisionTile responseMonth={responseMonth} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GrowthTile response={response} />
                </Grid>
                <Grid item xs={12}>
                  <GrowthBarChartTile monthly={monthly} />
                </Grid>
                <Grid item xs={12}>
                  <HighlightedExpenseTile
                    highlightedExpenses={highlightedExpenses}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {!isFetching && !xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            spacing={4}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={noData}
                alt="Logo"
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Typography variant="h5">No Data Found</Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isFetching && <Loading sideBarOpen={sideBarOpen} />}
    </div>
  );
}
