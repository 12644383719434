import React, { useEffect, useState } from "react";
import "./Login.css";
import { Formik } from "formik";
import { TextField, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/formulr-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, reset } from "../../../redux/User/UserSlice";
import "react-toastify/dist/ReactToastify.css";
export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const { isSuccess, isError } = useSelector((state) => state.user);

  const location = useLocation();
  const data = location.state;
  const onSubmit = (data) => {
    dispatch(loginUser(data));
    setSubmitting(true);
  };

  useEffect(() => {
    dispatch(reset());
    if (data && data.isLoggedOut) {
      window.history.replaceState({}, "", location.pathname);
    }
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          navigate("/");
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [data, dispatch, location.pathname, navigate]);

  useEffect(() => {
    if (isError) {
      dispatch(reset());
      setSubmitting(false);
    }
    if (isSuccess) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);
  return (
    <div
      style={{
        // backgroundImage: url(`${background}`),
        height: window.innerHeight,
      }}
    >
      <div className="login-wrapper">
        <Formik
          initialValues={{
            email: localStorage.getItem("email") ?? "",
            password: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.isEmailEmpty = true;
              errors.email = "Required Field";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.password) {
              errors.isPassowrdEmpty = true;
              errors.password = "Required Field";
            }
            if (
              values.password.length !== 0 &&
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.password)
            ) {
              errors.password =
                "Invalid Password Pattern! Your Password must be at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
            }
            return errors;
          }}
          onSubmit={(data, { setSubmitting }) => {
            onSubmit(data, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form className="form-wrapper-login" onSubmit={handleSubmit}>
              <div
                style={{
                  paddingTop: "13%",
                  paddingBottom: "5%",
                  alignContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={logo}
                  alt="Logo"
                />
              </div>
              <Grid justifyContent="center" container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.email && !!touched.email}
                    id="input-emailAddress"
                    variant="outlined"
                    label="Email"
                    fullWidth
                    size="medium"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    helperText={
                      errors.email &&
                      touched.email &&
                      !errors.isEmailEmpty &&
                      errors.email
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.password && !!touched.password}
                    fullWidth
                    id="input-password"
                    label="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                    helperText={
                      errors.password &&
                      touched.password &&
                      !errors.isPassowrdEmpty &&
                      errors.password
                    }
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LoadingButton
                    id="button-login"
                    loading={submitting}
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={submitting}
                    className="mt-10"
                  >
                    Login
                  </LoadingButton>
                </Grid>
              </Grid>
              <Link
                id="link-forgotPassword"
                underline="none"
                onClick={() => {
                  navigate("../forgot-password");
                }}
              >
                <h5 className="forgot-password custom mt-20">
                  Forgot your password?
                </h5>
              </Link>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
