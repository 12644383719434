import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { hideNotification } from "../../redux/Notification/NotificationSlice";
import "react-toastify/dist/ReactToastify.css";

const withGlobalToast = (Component) => {
  const GlobalToast = (props) => {
    const dispatch = useDispatch();
    const { message, type, isActive, toastId } = useSelector(
      (state) => state.notification
    );
    useEffect(() => {
      if (isActive) {
        toast(message, { type, toastId });
        dispatch(hideNotification());
      }
    }, [isActive, message, type, toastId, dispatch]);

    return (
      <Fragment>
        <div
          id={
            type === "error"
              ? `error-${message?.split(" ")?.join("")}`
              : "toast-component"
          }
        >
          <Component {...props} />
          <ToastContainer autoClose={2000} />
        </div>
      </Fragment>
    );
  };

  return GlobalToast;
};

export default withGlobalToast;
