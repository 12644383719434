import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export default function NetProfitTile(props) {
  const {
    revenueFilter,
    types,
    monthFilter,
    currentMonth,
    previousMonth,
    setDateFilter,
    setFilter,
    dateFilter,
    budget,
  } = props;
  const type = {
    revenue: "Revenue",
    grossProfit: "Gross Profit",
    netProfit: "Net Profit",
  };
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid
        item
        className="container"
        xs={12}
        sm={8}
        md={8}
        lg={8}
        order={{ xs: 2, sm: 1, md: 1, lg: 1 }}
      >
        <Grid container padding={1} spacing={1}>
          <Grid item xs={12}>
            <label className="dashboard-h2">{type[revenueFilter]}</label>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Grid container>
              <Grid item xs={6} sm={12} paddingTop={{ xs: 1, sm: 0 }}>
                <label style={{}}>This Month</label>
              </Grid>

              <Grid item xs={6} marginTop={{ xs: 0, sm: 2.3 }}>
                <label className="dashboard-h1">
                  {(currentMonth[revenueFilter] / 1000).toFixed(2)}K
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container>
              <Grid item xs={12}>
                <label>Budget</label>
              </Grid>
              <Grid item xs={12}>
                <label style={{ color: "#06A561", fontWeight: "bold" }}>
                  15%
                </label>{" "}
                <ArrowUpward
                  style={{
                    marginLeft: "-3px",
                    marginBottom: "-2px",
                    fontSize: "16px",
                    color: "#06A561",
                  }}
                />
              </Grid>
              <Grid item>
                <label className="dashboard-h1">{budget}</label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Grid container>
              <Grid item xs={12}>
                <label>Last Month</label>
              </Grid>
              <Grid item xs={12}>
                <label
                  style={{
                    color:
                      parseInt(currentMonth[revenueFilter]) <
                      parseInt(previousMonth[revenueFilter])
                        ? "#F0142F"
                        : "#06A561",
                    fontWeight: "bold",
                  }}
                >
                  {(
                    (Math.abs(
                      parseInt(currentMonth[revenueFilter]) -
                        parseInt(previousMonth[revenueFilter])
                    ) /
                      Math.abs(parseInt(previousMonth[revenueFilter]))) *
                    100
                  ).toFixed(1)}
                  %
                </label>{" "}
                {parseInt(currentMonth[revenueFilter]) <
                  parseInt(previousMonth[revenueFilter]) && (
                  <ArrowDownward
                    style={{
                      marginLeft: "-3px",
                      marginBottom: "-2px",
                      fontSize: "16px",
                      color: "#F0142F",
                    }}
                  />
                )}
                {parseInt(currentMonth[revenueFilter]) >
                  parseInt(previousMonth[revenueFilter]) && (
                  <ArrowUpward
                    style={{
                      marginLeft: "-3px",
                      marginBottom: "-2px",
                      fontSize: "16px",
                      color: "#06A561",
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                <label className="dashboard-h1">
                  {" "}
                  {(previousMonth[revenueFilter] / 1000).toFixed(2)}K
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        lg={4}
        marginTop={2}
        marginBottom={2}
        order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
      >
        <Grid container spacing={4}>
          <Grid item xs={6} sm={12} md={12} marginLeft={{ sm: 8 }}>
            <FormControl
              sx={{
                minWidth: "100%",
              }}
            >
              <InputLabel id="select-label" size="small">
                Type
              </InputLabel>
              <Select
                labelId="select-label"
                size="small"
                label="Filter"
                defaultValue={revenueFilter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                {types.map((data) => {
                  return (
                    <MenuItem key={data.value} value={data.value}>
                      {data.option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={12} md={12} marginLeft={{ sm: 8 }}>
            <FormControl
              sx={{
                minWidth: "100%",
              }}
            >
              <InputLabel id="select-label" size="small">
                Date
              </InputLabel>
              <Select
                labelId="select-label"
                size="small"
                label="Filter"
                defaultValue={1}
                onChange={(e) => {
                  setDateFilter(e.target.value);
                }}
                value={dateFilter}
              >
                {monthFilter.map((data) => {
                  return (
                    <MenuItem key={`month${data.value}`} value={data.value}>
                      {data.option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
