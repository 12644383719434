import { Grid } from "@mui/material";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import "./MonthlyCashBankBalanceTile.css";

export default function MonthlyCashBankBalanceTile(props) {
  const {
    cashIn,
    cashOut,
    bankData: { bank, creditCard },
    colors,
  } = props;

  return (
    <Grid
      container
      justifyContent={"space-between"}
      marginTop={{ xs: 0, sm: 0, md: 0 }}
    >
      <Grid
        item
        className="container"
        xs={12}
        sm={3.8}
        md={4}
        lg={4}
        marginBottom={{ xs: 2, sm: 0 }}
      >
        <Grid container padding={1}>
          <Grid item xs={12}>
            <label className="dashboard-h2">Monthly Cash Movement</label>
          </Grid>
          <Grid item xs={12} marginTop={{ xs: 2, sm: 2, md: 4 }}>
            <label className="dashboard-h1">
              {((cashIn - cashOut) / 1000).toFixed(2)} K
            </label>
            <div
              style={{
                backgroundColor: cashIn > cashOut ? "#DEF7E8" : "#F19BA5",
                width: "25px",
                height: "25px",
                display: "inline-flex",
                marginLeft: "10px",
                borderRadius: "4px",
                alignItems: "center",
              }}
            >
              {cashIn > cashOut && (
                <ArrowUpward
                  style={{
                    marginLeft: "2px",
                    fontSize: "20px",
                    color: "#00EACF",
                  }}
                />
              )}
              {cashIn < cashOut && (
                <ArrowDownward
                  style={{
                    marginLeft: "2px",
                    fontSize: "20px",
                    color: "#F0142F",
                  }}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} marginTop={{ xs: 2, sm: 2, md: 4 }}>
                <Grid container>
                  <Grid item xs={7} sm={6}>
                    <div
                      className="dashboard-color-block"
                      style={{
                        backgroundColor: "#00EACF",
                      }}
                    />
                    <label className="dashboard-h3-normal">Cash In</label>
                  </Grid>
                  <Grid item xs={5} sm={6}>
                    <label className="dashboard-h3-normal">$ {cashIn}</label>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} marginBottom={2}>
                <Grid container>
                  <Grid item xs={7} sm={6}>
                    <div
                      className="dashboard-color-block"
                      style={{
                        backgroundColor: "#0F71FD",
                      }}
                    />
                    <label className="dashboard-h3-normal">Cash Out</label>
                  </Grid>
                  <Grid item xs={5} sm={6}>
                    <label className="dashboard-h3-normal">$ {cashOut}</label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={7.8}
        md={7.6}
        lg={7.6}
        className="container bank-balance"
        marginTop={{ xs: 2, sm: 0 }}
      >
        <Grid container spacing={1} padding={1}>
          <Grid item xs={12}>
            <label className="dashboard-h2">Bank balance</label>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item lg={4} md={4} sm={5} xs={12} marginBottom={2}>
                <label className="dashboard-h1">
                  {bank?.totalBalance > 1000000 ? (
                    <>${(bank?.totalBalance / 1000000).toFixed(2)} M</>
                  ) : (
                    <>${(bank?.totalBalance / 1000).toFixed(2)} K</>
                  )}
                </label>
              </Grid>
              <Grid
                id="balance-tile"
                item
                lg={8}
                md={8}
                sm={7}
                xs={12}
                style={{
                  maxHeight: "100px",
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollbarColor: "transparent",
                }}
              >
                {Object.keys(bank?.banks || {}).map((key, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={8}>
                        <div
                          className="dashboard-color-block"
                          style={{
                            backgroundColor:
                              index < 22 ? colors[index] : colors[index % 22],
                          }}
                        />

                        <span style={{ margin: "5px" }}>{key}</span>
                      </Grid>
                      <Grid item xs={4}>
                        <label>
                          {bank?.banks[key] > 1000000 ? (
                            <>${(bank?.banks[key] / 1000000).toFixed(2)} M</>
                          ) : (
                            <>${(bank?.banks[key] / 1000).toFixed(2)} K</>
                          )}
                        </label>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} marginTop={{ xs: 1, sm: 0 }}>
            <label className="dashboard-h2">Credit Card balance</label>
          </Grid>
          <Grid item xs={12} marginBottom={1}>
            <Grid container>
              <Grid item lg={4} md={4} sm={5} xs={12} marginBottom={2}>
                <label className="dashboard-h1">
                  {creditCard?.totalBalance > 1000000 ? (
                    <>${(creditCard?.totalBalance / 1000000).toFixed(2)} M</>
                  ) : (
                    <>${(creditCard?.totalBalance / 1000).toFixed(2)} K</>
                  )}
                </label>
              </Grid>
              <Grid
                id="balance-tile"
                item
                lg={8}
                md={8}
                sm={7}
                xs={12}
                style={{
                  maxHeight: "100px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {Object.keys(creditCard?.cards || {}).map((key, index) => {
                  return (
                    <Grid container key={index}>
                      <Grid item xs={8}>
                        <div
                          className="dashboard-color-block"
                          style={{
                            backgroundColor: colors[index],
                          }}
                        />

                        <label style={{ margin: "5px" }}>{key}</label>
                      </Grid>
                      <Grid item xs={4}>
                        <label>
                          {creditCard?.cards[key] > 1000000 ? (
                            <>
                              ${(creditCard?.cards[key] / 1000000).toFixed(2)} M
                            </>
                          ) : (
                            <>${(creditCard?.cards[key] / 1000).toFixed(2)} K</>
                          )}
                        </label>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
