import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const { REACT_APP_BASE_API_URL } = process.env;

export const dashboardData = createAsyncThunk(
  "dasboard/data",
  async (data, thunkAPI) => {
    try {
      const { entityId, email, date, accountingFirmId } = data;
      const token = localStorage.getItem("accessToken");
      const response = await axios({
        method: "post",
        url:
          REACT_APP_BASE_API_URL +
          `/accountingFirm/${accountingFirmId}/dashboard/entity/${entityId}`,
        data: { email, date },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const initialState = {
  invoiceData: {},
  revenueCardData: {},
  revenueGraphData: {},
  netProfitCardData: {},
  netProfitGraphData: {},
  cashMovementData: {},
  bankBalanceData: {},
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: {
    [dashboardData.fulfilled]: (state, { payload }) => {
      state.invoiceData = payload.data.invoiceData;
      state.revenueCardData = payload.data.revenueCardData;
      state.revenueGraphData = payload.data.revenueGraphData;
      state.netProfitCardData = payload.data.netProfitCardData;
      state.netProfitGraphData = payload.data.netProfitGraphData;
      state.cashMovementData = payload.data.cashMovementData;
      state.bankBalanceData = payload.data.bankBalanceData;

      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [dashboardData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
    },
    [dashboardData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});
export const { resetData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
