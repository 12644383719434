import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: "",
  type: "",
  isActive: false,
  toastId: "",
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.message = action.payload.message;
      state.isActive = true;
      state.toastId = action.payload.toastId;
      if (action.payload.status === 200) {
        state.type = "success";
      } else {
        state.type = "error";
      }
    },
    hideNotification: (state) => {
      state.isActive = false;
      state.message = "";
      state.type = "";
      state.toastId = "";
    },
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
