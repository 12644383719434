import { Grid } from "@mui/material";
import RadialChart from "../../../../../components/RadialChart/RadialChart";

export default function AnnualisedTile(props) {
  // const { percentage } = props;

  return (
    <Grid
      container
      justifyContent={"space-between"}
      paddingBottom={{ xs: 0, sm: 0, lg: 2 }}
    >
      <Grid item className="container" xs={12}>
        <Grid container>
          <Grid item xs={12} sm={6} paddingTop={1}>
            <RadialChart
              percentage={10}
              title={"Annualised Sales v Budget"}
              colorType={1}
            />
          </Grid>
          <Grid item xs={12} sm={6} paddingTop={1} marginTop={{ xs: 4, sm: 0 }}>
            <RadialChart
              percentage={50}
              title={"Annualised Net Profit v Budget"}
              colorType={1}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
