import creditCard from "../../../../../assets/icons/credit-card.svg";
import employees from "../../../../../assets/icons/employees.svg";
import invoice from "../../../../../assets/icons/invoice.svg";
import testPlus from "../../../../../assets/icons/testPlus.svg";
import testMinus from "../../../../../assets/icons/testMinus.svg";
import testEquals from "../../../../../assets/icons/testEquals.svg";
import moneyBag from "../../../../../assets/icons/money-bag.svg";
import tax from "../../../../../assets/icons/tax.svg";
import { Grid } from "@mui/material";
import "./EstimatedWithPlusMinus.css";

export default function EstimatedWithPlusMinus() {
  const openingBankBalance = 1000;
  // const closingBankBalance = 2000;
  return (
    <div className="custom-scroll">
      <div className="custom-container">
        <Grid container justifyContent={"space-between"}>
          <Grid item className="container" sm={1.55} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                marginTop={{ xl: 1, xs: 1 }}
                marginleft={{ lg: 1, xs: 50 }}
              >
                <img src={moneyBag} alt="Credit Card" />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item marginLeft={{ lg: 0, sm: 2 }}>
                  <label className="dashboard-h4">Current Cash balance</label>
                </Grid>
              </Grid>

              <Grid item marginTop={1}>
                <label className="dashboard-h2">{openingBankBalance}K</label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={0.3}
            display="flex"
            justifyContent="center"
            style={{ display: "grid", alignContent: "center" }}
            marginTop={{ xs: 1, sm: 0 }}
            marginBottom={{ xs: 1, sm: 0 }}
            marginLeft={{ xs: 0, sm: 1 }}
            marginRight={{ xs: 0, sm: 1 }}
          >
            <img className="custom-icon" src={testPlus} alt="Plus" />
          </Grid>
          <Grid item className="container" sm={1.55} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                marginTop={{ xl: 1, xs: 1 }}
                marginleft={{ lg: 1, xs: 50 }}
              >
                <img src={invoice} alt="Credit Card" />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item marginLeft={{ lg: 0, sm: 2 }}>
                  <label className="dashboard-h4">Estimated Income</label>
                </Grid>
              </Grid>

              <Grid item marginTop={1}>
                <label className="dashboard-h2">{openingBankBalance}K</label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={0.3}
            display="flex"
            justifyContent="center"
            marginTop={{ xs: 1, sm: 0 }}
            marginBottom={{ xs: 1, sm: 0 }}
            marginLeft={{ xs: 0, sm: 1 }}
            marginRight={{ xs: 0, sm: 1 }}
            style={{ display: "grid", alignContent: "center" }}
          >
            <img className="custom-icon" src={testMinus} alt="Plus" />
          </Grid>
          <Grid item className="container" sm={1.55} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                marginTop={{ xl: 1, xs: 1 }}
                marginleft={{ lg: 1, xs: 50 }}
              >
                <img src={creditCard} alt="Credit Card" />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item marginLeft={{ lg: 0, sm: 2 }}>
                  <label className="dashboard-h4">Estimated Payables</label>
                </Grid>
              </Grid>

              <Grid item marginTop={1}>
                <label className="dashboard-h2">{openingBankBalance}K</label>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={0.3}
            display="flex"
            justifyContent="center"
            style={{ display: "grid", alignContent: "center" }}
            marginTop={{ xs: 1, sm: 0 }}
            marginBottom={{ xs: 1, sm: 0 }}
            marginLeft={{ xs: 0, sm: 1 }}
            marginRight={{ xs: 0, sm: 1 }}
          >
            <img className="custom-icon" src={testMinus} alt="Plus" />
          </Grid>

          <Grid item className="container" sm={1.55} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                marginTop={{ xl: 1, xs: 1 }}
                marginleft={{ lg: 1, xs: 50 }}
              >
                <img src={employees} alt="Credit Card" />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item marginLeft={{ lg: 0, sm: 2 }}>
                  <label className="dashboard-h4">Wages and Super</label>
                </Grid>
              </Grid>

              <Grid item marginTop={1}>
                <label className="dashboard-h2">{openingBankBalance}K</label>
              </Grid>
            </Grid>
          </Grid>
          {/* taxes*/}
          <Grid
            item
            xs={12}
            sm={0.3}
            display="flex"
            justifyContent="center"
            style={{ display: "grid", alignContent: "center" }}
            marginTop={{ xs: 1, sm: 0 }}
            marginBottom={{ xs: 1, sm: 0 }}
            marginLeft={{ xs: 0, sm: 1 }}
            marginRight={{ xs: 0, sm: 1 }}
          >
            <img className="custom-icon" src={testMinus} alt="Plus" />
          </Grid>
          <Grid item className="container" sm={1.55} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                marginTop={{ xl: 1, xs: 1 }}
                marginleft={{ lg: 1, xs: 50 }}
              >
                <img src={tax} alt="Credit Card" />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item marginLeft={{ lg: 0, sm: 2 }}>
                  <label className="dashboard-h4">Taxes</label>
                </Grid>
              </Grid>

              <Grid item marginTop={1}>
                <label className="dashboard-h2">{openingBankBalance}K</label>
              </Grid>
            </Grid>
          </Grid>
          {/* closing cash */}
          <Grid
            item
            xs={12}
            sm={0.3}
            display="flex"
            justifyContent="center"
            style={{ display: "grid", alignContent: "center" }}
            marginTop={{ xs: 1, sm: 0 }}
            marginBottom={{ xs: 1, sm: 0 }}
            marginLeft={{ xs: 0, sm: 1 }}
            marginRight={{ xs: 0, sm: 1 }}
          >
            <img className="custom-icon" src={testEquals} alt="Plus" />
          </Grid>
          <Grid item className="container" sm={1.55} xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid
                item
                marginTop={{ xl: 1, xs: 1 }}
                marginleft={{ lg: 1, xs: 50 }}
              >
                <img src={moneyBag} alt="Credit Card" />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item marginLeft={{ lg: 0, sm: 2 }}>
                  <label className="dashboard-h4">Closing Cash</label>
                </Grid>
              </Grid>

              <Grid item marginTop={1}>
                <label className="dashboard-h2">{openingBankBalance}K</label>
              </Grid>
            </Grid>
          </Grid>
          {/*  */}
        </Grid>
      </div>
    </div>
  );
}
