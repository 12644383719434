export const defaultTypeMap = {
  BANK: "Current Asset",
  CURRENT: "Current Asset",
  CURRLIAB: "Current Liability",
  DEPRECIATN: "Depreciation",
  DIRECTCOSTS: "Direct Costs",
  EQUITY: "Equity",
  EXPENSE: "Operating Expenses",
  FIXED: "Non-current Asset",
  INVENTORY: "Non-current Asset",
  LIABILITY: "Current Liability",
  NONCURRENT: "Non-current Asset",
  OTHERINCOME: "Other Income",
  OVERHEADS: "Operating Expenses",
  PREPAYMENT: "Current Asset",
  REVENUE: "Revenue",
  SALES: "Revenue",
  TERMLIAB: "Non-current Liability",
  PAYGLIABILITY: "Current Liability",
  SUPERANNUATIONEXPENSE: "Operating Expenses",
  SUPERANNUATIONLIABILITY: "Current Liability",
  WAGESXPENSE: "Operating Expenses",
};

export const balanceSheetList = [
  "BANK",
  "CURRENT",
  "CURRLIAB",
  "EQUITY",
  "FIXED",
  "INVENTORY",
  "LIABILITY",
  "NONCURRENT",
  "PREPAYMENT",
  "TERMLIAB",
  "PAYGLIABILITY",
  "SUPERANNUATIONLIABILITY",
];

export const profitLossList = [
  "DEPRECIATN",
  "DIRECTCOSTS",
  "EXPENSE",
  "OTHERINCOME",
  "OVERHEADS",
  "REVENUE",
  "SALES",
  "SUPERANNUATIONEXPENSE",
  "WAGESXPENSE",
];

export const defaultPLFormat = [
  "Revenue",
  "Other Income",
  "Direct Costs",
  "Operating Expenses",
  "Depreciation",
  "Tax Expense",
  "Amortisation",
  "Interest Income",
  "Interest Expense",
];
