import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
// import { reset } from "../../../redux/User/UserSlice";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import "./Dashboard.css";
import { useOutletContext, useParams } from "react-router-dom";
import { dashboardData } from "../../../redux/Dashboard/DashboardSlice";
// import RevenueTile from "./DashboardTiles/RevenueTile/RevenueTile";
import NetProfitTile from "./DashboardTiles/NetProfitTile/NetProfitTile";
import MonthlyCashBankBalanceTile from "./DashboardTiles/MonthlyCashBankBalanceTile/MonthlyCashBankBalanceTile";
import RevenueLineChart from "./DashboardTiles/RevenueLineChart/RevenueLineChart";
import NetProfitLineChart from "./DashboardTiles/NetProfitLineChart/NetProfitLineChart";
import CustomerTile from "./DashboardTiles/CustomerTile/CustomerTile";
import AnnualisedTile from "./DashboardTiles/AnnualisedTile/AnnualisedTile";
import ExpenseTile from "./DashboardTiles/ExpenseTile/ExpenseTile";
import UnreconciledTransactionTile from "./DashboardTiles/UnreconciledTransactionTile/UnreconciledTransactionTile";
import PaymentsDueTile from "./DashboardTiles/PaymentsDueTile/PaymentsDueTile";
import Loading from "../../../components/LoadingIndicator/Loading";
import noData from "../../../assets/no-data-found.png";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const { entityId, accountingFirmId } = useParams();
  const navigate = useNavigate();
  const {
    isFetching,
    invoiceData: {
      topFiveCreditors,
      paymentDue14Days,
      totalDueAmount,
      averageCollectionDays,
    },
    revenueCardData: {
      // currentMonth, previousMonth,
      budget,
    },
    revenueGraphData: { revenueGraphData, grossProfitGraphData },
    netProfitCardData,
    netProfitGraphData: { netProfitGraphData },
    cashMovementData: { cashIn, cashOut },
    bankBalanceData,
  } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [dateFilter, setDateFilter] = useState(1);
  const [revenueFilterValue, setRevenueFilter] = useState("revenue");
  const xeroConnectionStatus =
    // JSON.parse(
    //   localStorage.getItem("xeroConnectionStatus")
    // );
    true;
  const sideBarOpen = useOutletContext().open;
  window.addEventListener("popstate", function (event) {
    navigate("/groups");
  });
  useEffect(() => {
    // if (isSuccess) {
    //   toast("Successfully logged in", { type: "success" });
    // }
  });
  useEffect(() => {
    dispatch(
      dashboardData({
        entityId,
        email: JSON.parse(localStorage.getItem("user_data"))?.email,
        date: dateFilter,
        accountingFirmId,
      })
    );
  }, [entityId, dateFilter, accountingFirmId, dispatch]);
  const monthFilter = [
    { option: "1 Month", value: 1 },
    { option: "3 Months", value: 3 },
    { option: "6 Months", value: 6 },
    { option: "9 Months", value: 9 },
    { option: "12 Months", value: 12 },
  ];
  const revenueFilter = [
    {
      option: "Revenue",
      value: "revenue",
    },
    {
      option: "Gross Profit",
      value: "grossProfit",
    },
    {
      option: "Net Profit",
      value: "netProfit",
    },
  ];

  return (
    <div>
      <ToastContainer />
      {!isFetching && xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <h1 id="text-dashboard" style={{ color: "#062D65" }}>
                Dashboard
              </h1>
            </Grid>
          </Grid>
          <Grid container spacing={4} justifyContent={"space-between"}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {/* {revenueFilterValue !== "netProfit" && (
                    <RevenueTile
                      setFilter={setRevenueFilter}
                      dateFilter={dateFilter}
                      setDateFilter={setDateFilter}
                      revenueFilter={revenueFilterValue}
                      types={revenueFilter}
                      monthFilter={monthFilter}
                      currentMonth={currentMonth}
                      previousMonth={previousMonth}
                      budget={budget}
                    />
                  )} */}
                  {revenueFilterValue === "netProfit" && (
                    <NetProfitTile
                      setFilter={setRevenueFilter}
                      dateFilter={dateFilter}
                      setDateFilter={setDateFilter}
                      revenueFilter={revenueFilterValue}
                      types={revenueFilter}
                      monthFilter={monthFilter}
                      currentMonth={netProfitCardData.currentMonth}
                      previousMonth={netProfitCardData.previousMonth}
                      budget={budget}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {revenueFilterValue !== "netProfit" && (
                    <RevenueLineChart
                      filter={revenueFilterValue}
                      plDataChart={{
                        revenue: revenueGraphData,
                        grossProfit: grossProfitGraphData,
                      }}
                    />
                  )}
                  {revenueFilterValue === "netProfit" && (
                    <NetProfitLineChart
                      filter={revenueFilterValue}
                      plDataChart={{
                        netProfit: netProfitGraphData,
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <AnnualisedTile percentage={12} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6} marginTop={{ xs: 0, sm: 0, md: 0 }}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <MonthlyCashBankBalanceTile
                    cashIn={cashIn}
                    cashOut={cashOut}
                    colors={colors}
                    bankData={bankBalanceData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomerTile
                    totalDueAmount={totalDueAmount}
                    averageCollectionDays={averageCollectionDays}
                    topFiveCreditors={topFiveCreditors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"space-between"}>
                    <Grid
                      item
                      xs={12}
                      sm={4.1}
                      md={3.7}
                      marginBottom={{ xs: 4, sm: 4 }}
                      className="container"
                    >
                      <Grid container padding={1}>
                        <ExpenseTile />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3.5}
                      md={3.7}
                      className="container"
                      marginBottom={{ xs: 4, sm: 4 }}
                    >
                      <Grid container padding={1}>
                        <PaymentsDueTile paymentDue={paymentDue14Days} />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3.5}
                      md={3.7}
                      className="container"
                      marginBottom={{ xs: 4, sm: 4 }}
                    >
                      <Grid container padding={1}>
                        <UnreconciledTransactionTile />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {!isFetching && !xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            spacing={4}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={noData}
                alt="Logo"
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Typography variant="h5">No Data Found</Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isFetching && <Loading sideBarOpen={sideBarOpen} />}
    </div>
  );
}
