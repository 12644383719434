import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { FormControl, InputLabel } from "@mui/material";
import "./Xero.css";

export const Xero = (props) => {
  const {
    tenants,
    value,
    setValue,
    // refreshToken, fetchXeroData
  } = props;

  return (
    <Grid container>
      <Grid container>
        <Typography variant="h6" fontWeight={600}>
          Select default organisation
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid container className="mt-30">
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Organization
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value}
                  label="Organization"
                  onChange={({ target }) => {
                    setValue(target.value);
                  }}
                >
                  {tenants.map((tenant, index) => {
                    return (
                      <MenuItem key={index} value={tenant.tenantId}>
                        {tenant.tenantName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* <Grid container className="mt-20">
            <Grid item>
              <Button variant="contained" onClick={() => refreshToken()}>
                Refresh Xero Token
              </Button>
            </Grid>
            <Grid item className="ml-10">
              <Button
                variant="contained"
                onClick={() => fetchXeroData()}
                disabled={value === ""}
              >
                Fetch Xero Data
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
