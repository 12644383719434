import { Grid } from "@mui/material";
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";

export default function GrowthBarChartTile(props) {
  const { monthly } = props;

  return (
    <Grid container justifyContent={"space-between"} marginBottom={2}>
      <Grid item xs={12} lg={12} xl={12} className="container">
        <Grid container padding={1} spacing={1}>
          <Grid item xs={12} marginBottom={2}>
            <label className="dashboard-h2">Monthly Growth</label>
          </Grid>
          <Grid container marginTop={2}>
            <ResponsiveContainer width="99%" height={280}>
              <BarChart
                margin={{ top: 20, right: 30, left: 5, bottom: 5 }}
                data={monthly}
              >
                <XAxis dataKey="name" axisLine={false} tickLine={false} />
                <YAxis unit={"%"} axisLine={false} tickLine={false} />
                <Tooltip />
                <Legend
                  wrapperStyle={{ top: -10, left: 10 }}
                  verticalAlign="top"
                  align="left"
                  iconType={"square"}
                  formatter={(value, entry, index) => (
                    <span
                      style={{
                        color: "black",
                        fontSize: "16px",
                        marginTop: "5px",
                      }}
                    >
                      {value}
                    </span>
                  )}
                />
                <Bar
                  dataKey="This Year"
                  fill="#0F71FD"
                  barSize={10}
                  radius={[10, 10, 0, 0]}
                />
                <Bar
                  dataKey="Last Year"
                  fill="#00EACF"
                  barSize={10}
                  radius={[10, 10, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
