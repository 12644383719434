import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const { REACT_APP_BASE_API_URL } = process.env;
export const profitabilityData = createAsyncThunk(
  "profitability/data",
  async ({ email, accountingFirmId }, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        REACT_APP_BASE_API_URL +
          `/accountingFirm/${accountingFirmId}/profitability/getProfitabilityData`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const initialState = {
  data: { response: {}, responseMonth: {} },
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const profitabilitySlice = createSlice({
  name: "profitability",
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: {
    [profitabilityData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [profitabilityData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
    },
    [profitabilityData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});
export const { resetData } = profitabilitySlice.actions;

export default profitabilitySlice.reducer;
