import { Grid } from "@mui/material";
import RadialChart from "../../../../../components/RadialChart/RadialChart";

export default function MarginTile(props) {
  // const { percentage } = props;

  return (
    <Grid item xs={12} lg={12} xl={12} md={12} className="container">
      <Grid
        container
        justifyContent={"space-between"}
        paddingBottom={{ xs: 1, sm: 1, md: 2 }}
        padding={1}
        spacing={1}
      >
        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <RadialChart
            percentage={15}
            title={"Gross Profit Margin"}
            colorType={1}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <RadialChart percentage={50} title={"EBITDA Margin"} colorType={2} />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
          <RadialChart
            percentage={69}
            title={"Net Profit Margin"}
            colorType={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
