import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { ColorPalette } from "../../../constants/colorPalette";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { userProfile } from "../../../redux/User/UserSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { checkDataAvailibility } from "../../../helper/utils";
import Loading from "../../../components/LoadingIndicator/Loading";

const UserProfile = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const fileInputRef = useRef();
  const { accountingFirmId } = useParams();
  const [formValues, setFormValues] = useState(null);
  const [passwordValue, setPasswordValue] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(users.isFetching);
  const fileSize = 2097152;
  const userData = localStorage.getItem("user_data");

  const stateData = checkDataAvailibility(users);

  useEffect(() => {
    setIsLoading(users.isFetching);
  }, [users.isFetching]);

  const handleIconClick = (setFieldValue) => {
    if (fileInputRef.current) {
      setFieldValue("file", null);
      fileInputRef.current.click();
      return;
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (formValues) {
      formData.append("profilePic", formValues.file);
      formData.append("id", JSON.parse(userData).userId);
      formData.append("firstName", formValues.firstName);
      formData.append("lastName", formValues.lastName);
      formData.append("currentPassword", formValues.currentPassword);
      formData.append("password", formValues.newPassword);
      formData.append("passwordConfirm", formValues.confirmNewPassword);
      dispatch(userProfile({ formData, accountingFirmId }));
      setConfirmModalOpen(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {confirmModalOpen && (
        <ConfirmModal
          message={"Are you sure ypu want to save the changed inputs?"}
          confirmFunction={handleSubmit}
          declineFunction={() => setConfirmModalOpen(false)}
          modalOpen={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
        />
      )}
      <Formik
        initialValues={{
          firstName: stateData.name?.split(" ")[0],
          lastName: stateData.name?.split(" ")[1],
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
          firmName: "",
          file: null,
        }}
        validate={(values) => {
          const errors = {};

          // File validation
          if (values.file) {
            if (values.file.size >= fileSize) {
              errors.file = "Please upload an image smaller than 2MB";
            }
          }
          if (values.firstName && !values.lastName) {
            errors.lastName = "Please enter last name";
          }
          if (!values.firstName && values.lastName) {
            errors.firstName = "Please enter first name";
          }
          if (
            values.firstName.length !== 0 &&
            /[!@#$%^&*]/g.test(values.firstName)
          ) {
            errors.firstName = "Special characters not allowed";
          }
          if (
            values.lastName.length !== 0 &&
            /[!@#$%^&*]/g.test(values.lastName)
          ) {
            errors.lastName = "Special characters not allowed";
          }
          if (
            values.currentPassword &&
            !values.newPassword &&
            !values.confirmNewPassword
          ) {
            errors.newPassword = "Please enter your new password";
          }
          if (
            values.currentPassword &&
            values.newPassword &&
            !values.confirmNewPassword
          ) {
            errors.confirmNewPassword = "Please confirm your new password";
          }
          if (
            values.newPassword.length !== 0 &&
            !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.newPassword)
          ) {
            errors.newPassword =
              "Invalid Password Pattern! Your Password must be at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
          }
          if (values.newPassword !== values.confirmNewPassword) {
            errors.confirmNewPassword = "Passwords do not match.";
          }
          if (
            values.firmName.length !== 0 &&
            /[!@#$%^&*]/g.test(values.firmName)
          ) {
            errors.firmName = "Special characters not allowed";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setFormValues(values);
          setConfirmModalOpen(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
        }) => (
          <Grid container>
            <form onSubmit={handleSubmit}>
              <Grid container p={3}>
                <Typography variant="h4" fontWeight="bold">
                  User Profile
                </Typography>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <Grid container mt={4} p={2}>
                      <Grid container>
                        <Grid item>
                          {values.file && !errors.file ? (
                            <Grid>
                              <img
                                src={URL.createObjectURL(values.file)}
                                alt=""
                                width={80}
                                height={80}
                                style={{ borderRadius: "50%" }}
                              />
                            </Grid>
                          ) : (
                            <Avatar
                              src="/broken-image.jpg"
                              alt="Profile Image"
                              sx={{ height: 88, width: 88 }}
                            />
                          )}
                          <Grid
                            item
                            sx={{ position: "relative", bottom: 94, left: 61 }}
                          >
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              onChange={(e) =>
                                setFieldValue("file", e.currentTarget.files[0])
                              }
                            />
                            {values.file && !errors.file ? (
                              <IconButton
                                onClick={() => {
                                  setFieldValue("file", null);
                                  fileInputRef.current.value = null;
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            ) : (
                              <div
                                onClick={() => handleIconClick(setFieldValue)}
                              >
                                <CameraAltIcon
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container flexDirection="column" gap={3}>
                        {errors.file && (
                          <Typography sx={{ color: ColorPalette.danger }}>
                            {errors.file}
                          </Typography>
                        )}
                        <div>
                          <InputLabel className="mb-10">Name</InputLabel>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <TextField
                              name="firstName"
                              size="small"
                              placeholder="First name"
                              value={values.firstName}
                              error={!!errors.firstName && !!touched.firstName}
                              helperText={touched.firstName && errors.firstName}
                              onChange={handleChange}
                            />
                            <TextField
                              placeholder="Last name"
                              name="lastName"
                              size="small"
                              value={values.lastName}
                              error={!!errors.lastName && !!touched.lastName}
                              helperText={touched.lastName && errors.lastName}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          <InputLabel className="mb-10">
                            Current Password
                          </InputLabel>
                          <OutlinedInput
                            id="profile-page-password"
                            name="currentPassword"
                            size="small"
                            type={
                              passwordValue.currentPassword
                                ? "text"
                                : "password"
                            }
                            value={values.currentPassword}
                            error={
                              !!errors.currentPassword &&
                              !!touched.currentPassword
                            }
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      currentPassword: !state.currentPassword,
                                    }))
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {passwordValue.currentPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Grid mt={0.5} ml={2}>
                            <Typography
                              variant="caption"
                              sx={{ color: "#d32f2f" }}
                            >
                              {touched.currentPassword &&
                                errors.currentPassword}
                            </Typography>
                          </Grid>
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          <InputLabel className="mb-10">
                            New Password
                          </InputLabel>
                          <OutlinedInput
                            name="newPassword"
                            size="small"
                            type={
                              passwordValue.newPassword ? "text" : "password"
                            }
                            value={values.newPassword}
                            error={
                              !!errors.newPassword && !!touched.newPassword
                            }
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      newPassword: !state.newPassword,
                                    }))
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {passwordValue.newPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Grid mt={0.5} ml={2}>
                            <Typography
                              variant="caption"
                              sx={{ color: "#d32f2f" }}
                            >
                              {touched.newPassword && errors.newPassword}
                            </Typography>
                          </Grid>
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          <InputLabel className="mb-10">
                            Confirm New Password
                          </InputLabel>
                          <OutlinedInput
                            name="confirmNewPassword"
                            size="small"
                            type={
                              passwordValue.confirmPassword
                                ? "text"
                                : "password"
                            }
                            value={values.confirmNewPassword}
                            error={
                              !!errors.confirmNewPassword &&
                              !!touched.confirmNewPassword
                            }
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setPasswordValue((state) => ({
                                      ...state,
                                      confirmPassword: !state.confirmPassword,
                                    }))
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge="end"
                                >
                                  {passwordValue.confirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <Grid mt={0.5} ml={2}>
                            <Typography
                              variant="caption"
                              sx={{ color: "#d32f2f" }}
                            >
                              {touched.confirmNewPassword &&
                                errors.confirmNewPassword}
                            </Typography>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"right"}
                      gap={3}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={!dirty}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => resetForm()}
                        disabled={!dirty}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Grid>
        )}
      </Formik>
    </div>
  );
};

export default UserProfile;
