import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import xero from "../../../assets/xero.svg";
import { Typography } from "@mui/material";

const { REACT_APP_BASE_API_URL } = process.env;
export default function XeroRedirect() {
  const navigate = useNavigate();
  const entityId = localStorage.getItem("entityId");
  const clientSpaceId = localStorage.getItem("clientSpaceId");
  const accountingFirmId = localStorage.getItem("accountingFirmId");
  const initiateXero = async () => {
    const token = localStorage.getItem("accessToken");
    const email = localStorage.getItem("email");
    await axios({
      method: "post",
      url:
        REACT_APP_BASE_API_URL +
        `/accountingFirm/${accountingFirmId}/xero/callback`,
      data: {
        url: location.pathname + location.search,
        id: entityId,
        email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const location = useLocation();
  initiateXero();
  setTimeout(() => {
    navigate(
      `../accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/onboarding`
    );
    localStorage.removeItem("entityId");
  }, 2000);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ textAlign: "center" }}>
        {" "}
        <img
          style={{ height: "200px", widht: "200px" }}
          alt="xero"
          src={xero}
        ></img>
        <Typography variant="h5">Xero Connected Successfully.</Typography>
        <Typography variant="h5">Redirecting to Onboarding.</Typography>
      </div>
    </div>
  );
}
