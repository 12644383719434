import {
  Grid,
  IconButton,
  InputLabel,
  Typography,
  TextField,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import { ColorPalette } from "../../../constants/colorPalette";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Formik } from "formik";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accFirmProfile } from "../../../redux/User/UserSlice";
import { checkDataAvailibility } from "../../../helper/utils";
import Loading from "../../../components/LoadingIndicator/Loading";

function FirmProfile() {
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const { accountingFirmId } = useParams();
  const users = useSelector((state) => state.user);
  const [formValues, setFormValues] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(users.isFetching);
  const fileSize = 2097152;

  const stateData = checkDataAvailibility(users);

  useEffect(() => {
    setIsLoading(users.isFetching);
  }, [users.isFetching]);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      return;
    }
  };
  const handleSubmit = () => {
    const formData = new FormData();
    if (formValues) {
      formData.append("accFirmImg", formValues.file);
      formData.append("firmName", formValues.firmName);
      dispatch(accFirmProfile({ formData, accountingFirmId }));
      setConfirmModalOpen(false);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {confirmModalOpen && (
        <ConfirmModal
          message={"Are you sure ypu want to save the changed inputs?"}
          confirmFunction={handleSubmit}
          declineFunction={() => setConfirmModalOpen(false)}
          modalOpen={confirmModalOpen}
          setModalOpen={setConfirmModalOpen}
        />
      )}
      <Formik
        initialValues={{
          firmName: stateData.accFirmName,
          adminEmail: "",
        }}
        validate={(values) => {
          const errors = {};

          // File validation
          if (values.file) {
            if (values.file.size >= fileSize) {
              errors.file = "Please upload an image smaller than 2MB";
            } else if (
              values.file.type !== "image/svg+xml" &&
              values.file.type !== "image/svg"
            ) {
              errors.file = "Please upload an SVG image";
            }
          }

          if (
            values.firmName.length !== 0 &&
            /[!@#$%^&*]/g.test(values.firmName)
          ) {
            errors.firmName = "Special characters not allowed";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setFormValues(values);
          setConfirmModalOpen(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
        }) => (
          <Grid container>
            <form onSubmit={handleSubmit}>
              <Grid container p={4}>
                <Typography variant="h4" fontWeight="bold">
                  Accounting Firm Details
                </Typography>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <Grid
                      container
                      flexDirection="column"
                      gap={3}
                      pt={4}
                      pl={2}
                    >
                      <Grid item>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "4px",
                            flexDirection: "column",
                            border: "dashed",
                            height: "60px",
                            width: "150px",
                            padding: "20px",
                          }}
                        >
                          {values.file && !errors.file ? (
                            <Grid sx={{ objectFit: "contain" }}>
                              <img
                                src={URL.createObjectURL(values.file)}
                                alt=""
                                width={500}
                                height={100}
                                style={{
                                  objectFit: "contain",
                                  width: "auto",
                                  height: "auto",
                                  maxHeight: "55px",
                                  maxWidth: "140px",
                                }}
                              />
                            </Grid>
                          ) : (
                            <>
                              <Typography>PLease select any Image</Typography>
                              <div
                                onClick={() => handleIconClick(setFieldValue)}
                              >
                                <CameraAltIcon
                                  sx={{
                                    cursor: "pointer",
                                    "&:hover": {
                                      cursor: "pointer",
                                    },
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </Box>
                        <Grid
                          item
                          sx={{ position: "relative", bottom: 98, left: 135 }}
                        >
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(e) => {
                              if (e.currentTarget.files[0]) {
                                setFieldValue("file", e.currentTarget.files[0]);
                              }
                            }}
                          />
                          {values.file && !errors.file && (
                            <IconButton
                              onClick={() => {
                                setFieldValue("file", null);
                                fileInputRef.current.value = null;
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                        </Grid>
                        {errors.file && (
                          <Typography
                            sx={{
                              color: ColorPalette.danger,
                              marginTop: "5px",
                            }}
                          >
                            {errors.file}
                          </Typography>
                        )}
                      </Grid>

                      <Grid pt={1}>
                        <div>
                          <InputLabel className="mb-10">
                            Accounting Firm Name
                          </InputLabel>
                          <TextField
                            name="firmName"
                            size="small"
                            value={values.firmName}
                            error={!!errors.firmName && !!touched.firmName}
                            helperText={touched.firmName && errors.firmName}
                            onChange={handleChange}
                          />
                        </div>
                        <div
                          style={{ flexDirection: "column", marginTop: "30px" }}
                        >
                          <InputLabel className="mb-10">
                            {" "}
                            Admin Email
                          </InputLabel>
                          <TextField
                            name="adminEmail"
                            size="small"
                            inputProps={{ readOnly: true }}
                            value={values.adminEmail}
                            error={!!errors.adminEmail && !!touched.adminEmail}
                            helperText={touched.adminEmail && errors.adminEmail}
                            onChange={handleChange}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"right"}
                      gap={3}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={!(dirty && isValid)}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => resetForm()}
                        disabled={!dirty}
                      >
                        Reset
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Grid>
        )}
      </Formik>
    </div>
  );
}

export default FirmProfile;
