import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

export default function GrowthTile(props) {
  const { response } = props;

  return (
    <Grid container justifyContent={"space-between"}>
      <Grid
        item
        className="container"
        xs={12}
        sm={3.8}
        justifyContent={"center"}
        marginBottom={{ xs: 4, sm: 2 }}
      >
        <Grid container justifyContent={"left"} padding={1}>
          <Grid item xs={12}>
            <Grid container>
              <label className="dashboard-h2">Revenue Growth</label>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            sm={12}
            marginTop={2}
            marginLeft={{ xs: 2, sm: 0 }}
            order={{ xs: 2, sm: 1 }}
          >
            <Grid container justifyContent={"left"}>
              <div style={{ display: "contents" }}>
                <div
                  style={{
                    backgroundColor:
                      response.currentMonth?.revenue >
                      response.previousMonth?.revenue
                        ? "#DEF7E8"
                        : "#F19BA5",
                    width: "30px",
                    height: "30px",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  {response.currentMonth?.revenue >
                    response.previousMonth?.revenue && (
                    <ArrowUpward
                      style={{
                        marginLeft: "2px",
                        fontSize: "24px",
                        color: "#00EACF",
                        marginTop: "3px",
                      }}
                    />
                  )}
                  {response.currentMonth?.revenue <
                    response.previousMonth?.revenue && (
                    <ArrowDownward
                      style={{
                        marginLeft: "2px",
                        fontSize: "24px",
                        color: "#F0142F",
                        marginTop: "3px",
                      }}
                    />
                  )}
                </div>
                <div style={{ marginTop: "3px" }}>
                  <Typography
                    sx={{ fontWeight: "bold", marginLeft: "10px" }}
                    fontSize={{
                      xl: 20,
                      lg: 20,
                      md: 20,
                      sm: 25,
                      xs: 20,
                    }}
                    variant="h1"
                    style={{
                      color:
                        response.currentMonth?.revenue >
                        response.previousMonth?.revenue
                          ? "#3DD598"
                          : "#FC5A5A",
                    }}
                  >
                    {Math.abs(
                      (
                        ((response.currentMonth?.revenue -
                          response.previousMonth?.revenue) /
                          response.currentMonth?.revenue) *
                        100
                      ).toFixed(1)
                    )}
                    %
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={7} sm={12} marginTop={2} order={{ xs: 1, sm: 2 }}>
            <Grid container justifyContent={"left"}>
              <Typography
                sx={{ color: "grey", fontWeight: "bold", textAlign: "left" }}
                fontSize={13}
              >
                Compared to 5.3% increase same month last year
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="container"
        xs={12}
        sm={3.8}
        justifyContent={"center"}
        marginBottom={{ xs: 4, sm: 2 }}
      >
        <Grid container justifyContent={"left"} padding={1}>
          <Grid item xs={12}>
            <Grid container>
              <label className="dashboard-h2">Gross Profit Growth</label>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            sm={12}
            marginTop={2}
            marginLeft={{ xs: 2, sm: 0 }}
            order={{ xs: 2, sm: 1 }}
          >
            <Grid container justifyContent={"left"}>
              <div style={{ display: "contents" }}>
                <div
                  style={{
                    backgroundColor:
                      response.currentMonth.grossProfit >
                      response.previousMonth.grossProfit
                        ? "#DEF7E8"
                        : "#F19BA5",
                    width: "30px",
                    height: "30px",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  {response.currentMonth.grossProfit >
                    response.previousMonth.grossProfit && (
                    <ArrowUpward
                      style={{
                        marginLeft: "2px",
                        fontSize: "24px",
                        color: "#00EACF",
                        marginTop: "3px",
                      }}
                    />
                  )}
                  {response.currentMonth.grossProfit <
                    response.previousMonth.grossProfit && (
                    <ArrowDownward
                      style={{
                        marginLeft: "2px",
                        fontSize: "24px",
                        color: "#F0142F",
                        marginTop: "3px",
                      }}
                    />
                  )}
                </div>
                <div style={{ marginTop: "3px" }}>
                  <Typography
                    sx={{ fontWeight: "bold", marginLeft: "10px" }}
                    fontSize={{
                      xl: 20,
                      lg: 20,
                      md: 20,
                      sm: 25,
                      xs: 20,
                    }}
                    variant="h1"
                    style={{
                      color:
                        response.currentMonth.grossProfit >
                        response.previousMonth.grossProfit
                          ? "#3DD598"
                          : "#FC5A5A",
                    }}
                  >
                    {Math.abs(
                      (
                        ((response.currentMonth.grossProfit -
                          response.previousMonth.grossProfit) /
                          response.currentMonth.grossProfit) *
                        100
                      ).toFixed(1)
                    )}
                    %
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={7} sm={12} marginTop={2} order={{ xs: 1, sm: 2 }}>
            <Grid container justifyContent={"left"}>
              <Typography
                sx={{ color: "grey", fontWeight: "bold", textAlign: "left" }}
                fontSize={13}
              >
                Compared to 5.3% increase same month last year
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        className="container"
        xs={12}
        sm={3.8}
        justifyContent={"center"}
        marginBottom={{ xs: 4, sm: 2 }}
      >
        <Grid container justifyContent={"left"} padding={1}>
          <Grid item xs={12}>
            <Grid container>
              <label className="dashboard-h2">Net Profit Growth</label>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            sm={12}
            marginTop={2}
            marginLeft={{ xs: 2, sm: 0 }}
            order={{ xs: 2, sm: 1 }}
          >
            <Grid container justifyContent={"left"}>
              <div style={{ display: "contents" }}>
                <div
                  style={{
                    backgroundColor:
                      response.currentMonth.netProfit >
                      response.previousMonth.netProfit
                        ? "#DEF7E8"
                        : "#F19BA5",
                    width: "30px",
                    height: "30px",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  {response.currentMonth.netProfit >
                    response.previousMonth.netProfit && (
                    <ArrowUpward
                      style={{
                        marginLeft: "2px",
                        fontSize: "24px",
                        color: "#00EACF",
                        marginTop: "3px",
                      }}
                    />
                  )}
                  {response.currentMonth.netProfit <
                    response.previousMonth.netProfit && (
                    <ArrowDownward
                      style={{
                        marginLeft: "2px",
                        fontSize: "24px",
                        color: "#F0142F",
                        marginTop: "3px",
                      }}
                    />
                  )}
                </div>
                <div style={{ marginTop: "3px" }}>
                  <Typography
                    sx={{ fontWeight: "bold", marginLeft: "10px" }}
                    fontSize={{
                      xl: 20,
                      lg: 20,
                      md: 20,
                      sm: 25,
                      xs: 20,
                    }}
                    variant="h1"
                    style={{
                      color:
                        response.currentMonth.netProfit >
                        response.previousMonth.netProfit
                          ? "#3DD598"
                          : "#FC5A5A",
                    }}
                  >
                    {Math.abs(
                      (
                        ((response.currentMonth.netProfit -
                          response.previousMonth.netProfit) /
                          response.currentMonth.netProfit) *
                        100
                      ).toFixed(1)
                    )}
                    %
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={7} sm={12} marginTop={2} order={{ xs: 1, sm: 2 }}>
            <Grid container justifyContent={"left"}>
              <Typography
                sx={{ color: "grey", fontWeight: "bold", textAlign: "left" }}
                fontSize={13}
              >
                Compared to 5.3% increase same month last year
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
