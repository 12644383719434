import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const groupAccountTypeData = createAsyncThunk(
  "settings/groupAccountType",
  async ({ entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/chart-of-account/getAccountTypeFormat/pl`;
    return handleRequest(
      endpoint,
      { entityId },
      "getAccountTypeFormat",
      thunkAPI,
      null,
      "POST",
      true
    );
  }
);
export const saveAccountTypeFormat = createAsyncThunk(
  "chart-of-account/saveAccountTypeFormat",
  async ({ id, data, type, enabled, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/chart-of-account/saveAccountTypeFormat`;
    return handleRequest(
      endpoint,
      { id, data, type, enabled },
      "saveAccountTypeFormat",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const moveToActive = createAsyncThunk(
  "chart-of-account/moveToActive",
  async ({ id, ids, type, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/chart-of-account/moveToActive/${id}`;
    return handleRequest(
      endpoint,
      { accountIds: ids, type },
      "moveToActive",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const refreshXero = createAsyncThunk(
  "xero/refreshXero",
  async ({ id, email, accountingFirmId, modelsToFetch , clientSpaceId}, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/xero/fetchXeroData/${id}`;
    return handleRequest(
      endpoint,
      { email, modelsToFetch, clientSpaceId },
      "refreshXero",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const checkXeroStatus = createAsyncThunk(
  "xero/xero-fetch-status",
  async ({ id, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/xero/xero-fetch-status/${id}`;
    return handleRequest(
      endpoint,
      null,
      "checkXeroStatus",
      thunkAPI,
      null,
      "GET"
    );
  }
);

const initialState = {
  data: [],
  hiddenAccountList: [],
  xeroStatusData: [],
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  isXeroRefreshing: false,
  xeroStatusLoading: false,
};
export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    resetData: () => initialState,
    setIsXeroRefreshing: (state) => {
      state.isXeroRefreshing = true;
      return state;
    },
  },
  extraReducers: {
    [groupAccountTypeData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.hiddenAccountList = payload.hiddenAccountList;
      state.isXeroRefreshing = payload.isXeroRefreshing;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [groupAccountTypeData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
      return state;
    },
    [groupAccountTypeData.pending]: (state) => {
      state.isFetching = true;
      return state;
    },
    [saveAccountTypeFormat.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [saveAccountTypeFormat.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
      return state;
    },
    [saveAccountTypeFormat.pending]: (state) => {
      state.isFetching = true;
      return state;
    },
    [moveToActive.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.hiddenAccountList = payload.hiddenAccountList;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [moveToActive.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
      return state;
    },
    [moveToActive.pending]: (state) => {
      state.isFetching = true;
      return state;
    },
    [refreshXero.fulfilled]: (state, { payload }) => {
      return state;
    },
    [refreshXero.rejected]: (state, { payload }) => {
      return state;
    },
    [checkXeroStatus.fulfilled]: (state, { payload }) => {
      state.xeroStatusData = payload.xeroFetchStatus;
      state.xeroStatusLoading = false;
      return state;
    },
    [checkXeroStatus.rejected]: (state, { payload }) => {
      state.xeroStatusLoading = false;
      return state;
    },
    [checkXeroStatus.pending]: (state) => {
      state.xeroStatusLoading = true;
      return state;
    },
  },
});
export const { resetData, setIsXeroRefreshing } = settingSlice.actions;

export default settingSlice.reducer;
