import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { Image, GridOnRounded, Close, Download } from "@mui/icons-material";
import { toPng } from "html-to-image";
import CustomModal from "../../../components/CustomModal/CustomModal";

const SelectionContainer = ({
  type,
  handleImageClick,
  handleSpreadsheetClick,
}) => {
  const [downloadType, setDownloadType] = useState("");
  const getSelectionColor = (value, type) => {
    return type === value
      ? {
          backgroundColor: "#F2F2F2",
          border: "0.5px solid #03A561",
        }
      : {
          backgroundColor: "#FFFFFF",
        };
  };
  return (
    <Grid item xs={12}>
      <Typography variant={"body-1"}>
        How would you like to {type} spreadsheet ?
      </Typography>
      <Grid container className="mt-20">
        <div
          style={{
            ...getSelectionColor("image", downloadType),
          }}
          className="selection-tile"
          onClick={() => {
            setDownloadType("image");
            handleImageClick();
          }}
        >
          <Image color="disabled" sx={{ fontSize: 45 }} className="mt-5" />
          <p className="m-5"> Image </p>
        </div>
        <div
          variant="contained"
          className="ml-20 selection-tile"
          style={getSelectionColor("excel", downloadType)}
          onClick={() => {
            setDownloadType("excel");
            handleSpreadsheetClick();
          }}
        >
          <GridOnRounded
            color="disabled"
            sx={{ fontSize: 45 }}
            className="mt-5"
          />
          <p className="m-5"> Spreadsheet </p>
        </div>
      </Grid>
    </Grid>
  );
};

const DownloadModal = ({ modalOpen, setModalOpen }) => {
  const ref = useRef(null);
  const [value, setValue] = useState("1");
  const [sendEmailField, setSendEmailField] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const initialState = {
    isEmailEmpty: false,
    email: "",
  };
  const [errors, setErrors] = useState(initialState);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  let isFetching;

  const onDownloadClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "profit-and-loss.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const cancelInviteMembers = () => {
    setSelectedUsers([]);
    setSendEmailField("");
    setErrors(initialState);
  };

  const handleSendEmailChange = (event) => {
    setSendEmailField(event.target.value);
    if (event.target.value?.includes(",")) {
      setSelectedUsers(selectedUsers?.concat(sendEmailField.split(",")));
      setSendEmailField("");
    }
  };

  const handleChipDelete = (data) => {
    setSelectedUsers(selectedUsers?.filter((item) => item !== data));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!sendEmailField) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          isEmailEmpty: true,
          email: "Required Field",
        }));
      } else if (!emailRegex.test(sendEmailField)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid email address",
        }));
      } else if (selectedUsers?.includes(sendEmailField)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Can't enter same email",
        }));
      } else {
        setErrors(initialState);
        setSelectedUsers(selectedUsers?.concat(sendEmailField));
        setSendEmailField("");
      }
    }
  };
  const sendEmail = () => {
    const payload = selectedUsers?.map((item) => item);
    // dispatch(inviteUser({ payload, accountingFirmId }));
    console.log("payload", payload);
    setSelectedUsers([]);
  };

  return (
    <CustomModal
      modalOpen={modalOpen}
      maxWidth="md"
      setModalOpen={setModalOpen}
      onClose={setModalOpen}
      overflow="hidden"
    >
      <Box sx={{ width: "100%", marginTop: "23px" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 0, borderColor: "#0000001F" }}>
            <Grid
              container
              sx={{
                dipslay: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <TabList onChange={handleTabChange}>
                <Tab
                  label="Download"
                  value="1"
                  style={{
                    fontWeight: value === "1" ? "bold" : "",
                    color: "black",
                    textTransform: "none",
                    fontSize: "17px",
                  }}
                />
                <Tab
                  label="Email"
                  value="2"
                  style={{
                    fontWeight: value === "2" ? "bold" : "",
                    color: "black",
                    textTransform: "none",
                    fontSize: "17px",
                  }}
                />
              </TabList>
              <Close />
            </Grid>
            <Divider />
          </Box>
          <TabPanel value="1">
            <Grid container>
              <Grid className="mt-30">
                <SelectionContainer
                  type="download"
                  handleImageClick={onDownloadClick}
                  handleSpreadsheetClick={() => console.log("spredsheet click")}
                />
              </Grid>
              <Grid
                container
                display={"flex"}
                alignItems={"center"}
                justifyContent={"right"}
                gap={2}
                marginTop={4}
              >
                <Button variant="contained" startIcon={<Download />}>
                  Download
                </Button>
                <Button variant="outlined">Cancel</Button>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <div>
              {/* {isFetching && (
                <Grid>
                  <Loading wait={true} />
                </Grid>
              )} */}

              <Grid container padding={2}>
                <Grid item xs={12}>
                  <Grid container mb={2}>
                    <Grid>
                      <SelectionContainer
                        type="mail"
                        handleImageClick={() => console.log("img click")}
                        handleSpreadsheetClick={() =>
                          console.log("spredsheet click")
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} marginTop={2} pt={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Stack direction="row">
                          <div>
                            {selectedUsers?.map((data, key) => {
                              return (
                                <Chip
                                  key={key}
                                  label={data}
                                  variant="outlined"
                                  onDelete={() => {
                                    handleChipDelete(data);
                                  }}
                                  style={{ margin: 2 }}
                                />
                              );
                            })}
                          </div>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} marginTop={2}>
                        <FormControl fullWidth>
                          <TextField
                            id="input-sendEmail"
                            variant="outlined"
                            fullWidth
                            label="Enter an email and press enter to add more"
                            value={sendEmailField}
                            onChange={handleSendEmailChange}
                            onKeyDown={handleKeyDown}
                            disabled={isFetching}
                            error={errors.isEmailEmpty || errors.email !== ""}
                            helperText={
                              errors.isEmailEmpty
                                ? "Required Field"
                                : errors.email
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                          gap: "10px",
                          marginTop: "30px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          fullwidth
                          id="button-inviteMembers"
                          onClick={cancelInviteMembers}
                        >
                          <Typography textTransform={"none"}>Cancel</Typography>
                        </Button>
                        <Button
                          id="button-invite"
                          variant="contained"
                          onClick={sendEmail}
                          disabled={!selectedUsers?.length > 0 || isFetching}
                        >
                          <Typography textTransform={"none"}>
                            Send Email
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </CustomModal>
  );
};

export default DownloadModal;
