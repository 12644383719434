import { Grid } from "@mui/material";

export default function PaymentsDueTile(props) {
  const { amount, invoiceCount, overDueCount } = props?.paymentDue || {
    amount: 0,
    invoiceCount: 0,
    overDueCount: 0,
  };
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={12}>
        <label className="dashboard-h2">Payments Due Next 14 Days</label>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Grid container>
          <Grid item xs={12}>
            <label className="dashboard-h2" style={{ color: "grey" }}>
              ${amount}
            </label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Grid container>
          <Grid item xs={12}>
            <label className="dashboard-h2" style={{ color: "grey" }}>
              {invoiceCount} invoices
            </label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={2}>
        <Grid container>
          <Grid item xs={12}>
            <label className="dashboard-h2" style={{ color: "grey" }}>
              {overDueCount} currently overdue
            </label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
