import React from "react";
import { getCellProperty } from "@silevis/reactgrid";
import { IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export class MappingSpanCell {
  getCompatibleCell(uncertainCell) {
    let mapData = () => {};
    let id = "";
    try {
      mapData = getCellProperty(uncertainCell, "mapData", "function");
    } catch {
      mapData = () => {};
    }

    try {
      id = getCellProperty(uncertainCell, "id", "string");
    } catch {
      id = "";
    }

    return {
      ...uncertainCell,
      mapData,
      id,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      isExpanded: cellToMerge.isExpanded,
      text: cellToMerge.text,
    });
  }

  getStyle(cell) {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.4;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  render(cell) {
    return (
      <>
        <div style={{ marginLeft: "40%" }}>
          {cell.mappable && (
            <IconButton
              size="small"
              onClick={() => {
                cell.mapData(cell.id);
              }}
            >
              <KeyboardArrowRightIcon fontSize="10" />
            </IconButton>
          )}
        </div>
      </>
    );
  }
}
