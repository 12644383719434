import { handleRequest } from "../../helper/utils";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const addSpreadSheet = createAsyncThunk(
  "spreadsheet/add",
  async ({ payload, entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/${entityId}/create`;
    return handleRequest(
      endpoint,
      payload,
      "spreadSheet",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const duplicateSpreadsheet = createAsyncThunk(
  "spreadsheet/duplicate",
  async ({ payload, entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/${entityId}/duplicate`;
    return handleRequest(
      endpoint,
      payload,
      "addSpreadSheet",
      thunkAPI,
      null,
      "POST"
    );
  }
);

export const getSpreadSheets = createAsyncThunk(
  "spreadsheet/getSheets",
  async ({ accountingFirmId, entityId, search }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/${entityId}?search=${search}`;
    return handleRequest(
      endpoint,
      null,
      "getSpreadsheets",
      thunkAPI,
      null,
      "GET"
    );
  }
);

export const getSpreadSheet = createAsyncThunk(
  "spreadsheet/getSheet",
  async ({ entityId, spreadSheetId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/${entityId}/${spreadSheetId}`;
    return handleRequest(
      endpoint,
      null,
      "getSpreadsheet",
      thunkAPI,
      null,
      "GET"
    );
  }
);

export const updateSpreadsheetKey = createAsyncThunk(
  "spreadsheet/updateKey",
  async ({ payload, spreadSheetId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/key/${spreadSheetId}`;
    return handleRequest(
      endpoint,
      payload,
      "spreadSheet",
      thunkAPI,
      null,
      "PATCH",
      true
    );
  }
);

export const updateSpreadsheet = createAsyncThunk(
  "spreadsheet/update",
  async (
    { payload, spreadSheetId, accountingFirmId, notifyChange = false },
    thunkAPI
  ) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/${spreadSheetId}`;
    return handleRequest(
      endpoint,
      payload,
      "spreadSheet",
      thunkAPI,
      null,
      "PATCH",
      !notifyChange
    );
  }
);

export const syncSpreadsheet = createAsyncThunk(
  "spreadsheet/syncSpreadsheet",
  async ({ spreadSheetId, unSyncedRows, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/sync-to-xero/${spreadSheetId}`;
    return handleRequest(
      endpoint,
      { refreshData: true, unSyncedRows },
      "spreadSheet",
      thunkAPI,
      null,
      "POST",
      true
    );
  }
);

export const getActiveAccounts = createAsyncThunk(
  "spreadsheet/getActiveAccounts",
  async ({ entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/get-active-accounts/${entityId}`;
    return handleRequest(
      endpoint,
      {},
      "activeAccounts",
      thunkAPI,
      null,
      "POST",
      true
    );
  }
);

export const syncPriorPeriod = createAsyncThunk(
  "spreadsheet/syncPriorPeriod",
  async ({ spreadSheetId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/sync-prior-period/${spreadSheetId}`;
    return handleRequest(
      endpoint,
      { refreshData: true },
      "spreadSheet",
      thunkAPI,
      null,
      "POST",
      true
    );
  }
);

export const syncManualMetricToXero = createAsyncThunk(
  "spreadsheet/syncManualMetricToXero",
  async (
    { spreadSheetId, type, accountName, rowId, accountingFirmId },
    thunkAPI
  ) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/sync-manual-metric-to-xero/${spreadSheetId}`;
    return handleRequest(
      endpoint,
      { type, accountName, rowId },
      "spreadSheet",
      thunkAPI,
      null,
      "POST",
      true
    );
  }
);

export const deleteSpreadsheet = createAsyncThunk(
  "spreadsheet/deleteSheet",
  async ({ spreadsheetId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/${spreadsheetId}`;
    return handleRequest(
      endpoint,
      null,
      "deleteSpreadSheet",
      thunkAPI,
      null,
      "DELETE"
    );
  }
);

const initialState = {
  isFetching: true,
  isSuccess: false,
  isError: false,
  spreadsheets: [],
  spreadsheet: null,
  refreshData: true,
  isRefreshing: false,
  activeIds: [],
  coaNames: { revenue: [], expense: [] },
};

export const spreadsheetSlice = createSlice({
  name: "spreadsheet",
  initialState,
  reducers: {
    reset: () => initialState,
    resetCurrentSpreadSheet: (state) => {
      return { ...state, spreadsheet: null };
    },
    setRefreshing: (state) => {
      return { ...state, isRefreshing: true };
    },
    setRefreshData: (state) => {
      return { ...state, refreshData: true };
    },
    resetActiveIds: (state) => {
      return { ...state, activeIds: [] };
    },
    setSpreadsheetDataReference: (state, { payload }) => {
      return { ...state, spreadsheet: payload.spreadsheet };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addSpreadSheet.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.spreadsheets.unshift(payload.spreadSheet);
        return state;
      })
      .addCase(addSpreadSheet.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(addSpreadSheet.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(duplicateSpreadsheet.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.spreadsheets.unshift(payload.spreadSheet);
        return state;
      })
      .addCase(duplicateSpreadsheet.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(duplicateSpreadsheet.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(getSpreadSheets.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.spreadsheets = payload.spreadSheets;
        return state;
      })
      .addCase(getSpreadSheets.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(getSpreadSheets.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(getSpreadSheet.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.spreadsheet = payload.spreadSheet;
        state.refreshData = true;
        return state;
      })
      .addCase(getSpreadSheet.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(getSpreadSheet.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(deleteSpreadsheet.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        let index = state.spreadsheets
          .map((sheet) => sheet._id)
          .indexOf(payload.deletedSpreadsheet.id);
        state.spreadsheets.splice(index, 1);
        return state;
      })
      .addCase(deleteSpreadsheet.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        return state;
      })
      .addCase(deleteSpreadsheet.pending, (state) => {
        state.isFetching = true;
        return state;
      })
      .addCase(updateSpreadsheet.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isRefreshing = false;
        state.refreshData = payload.spreadSheet.refreshData;
        state.spreadsheet = payload.spreadSheet.data;
        return state;
      })
      .addCase(updateSpreadsheet.rejected, (state, { payload }) => {
        state.isRefreshing = false;
        state.isError = true;
        return state;
      })
      .addCase(updateSpreadsheet.pending, (state) => {
        return state;
      })
      .addCase(updateSpreadsheetKey.fulfilled, (state, { payload }) => {
        state.isRefreshing = false;
        state.isSuccess = true;
        state.refreshData = payload.spreadSheet.refreshData;
        state.spreadsheet = payload.spreadSheet.data;
        return state;
      })
      .addCase(updateSpreadsheetKey.rejected, (state, { payload }) => {
        state.isRefreshing = false;
        state.isError = true;
        return state;
      })
      .addCase(updateSpreadsheetKey.pending, (state) => {
        return state;
      })
      .addCase(syncSpreadsheet.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isRefreshing = false;
        state.refreshData = payload.spreadSheet.refreshData;
        state.spreadsheet = payload.spreadSheet.data;
        return state;
      })
      .addCase(syncSpreadsheet.rejected, (state, { payload }) => {
        state.isError = true;
        state.isRefreshing = false;
        return state;
      })
      .addCase(syncSpreadsheet.pending, (state) => {
        state.isRefreshing = true;
        return state;
      })
      .addCase(syncPriorPeriod.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isRefreshing = false;
        state.refreshData = payload.spreadSheet.refreshData;
        state.spreadsheet = payload.spreadSheet.data;
        return state;
      })
      .addCase(syncPriorPeriod.rejected, (state, { payload }) => {
        state.isError = true;
        state.isRefreshing = false;
        return state;
      })
      .addCase(syncPriorPeriod.pending, (state) => {
        return state;
      })
      .addCase(syncManualMetricToXero.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isRefreshing = false;
        state.refreshData = true;
        state.spreadsheet = payload.spreadSheet;
        return state;
      })
      .addCase(syncManualMetricToXero.rejected, (state, { payload }) => {
        state.isError = true;
        state.isRefreshing = false;
        return state;
      })
      .addCase(syncManualMetricToXero.pending, (state) => {
        return state;
      })
      .addCase(getActiveAccounts.fulfilled, (state, { payload }) => {
        state.isSuccess = true;
        state.isRefreshing = false;
        state.activeIds = payload.activeIds;
        state.coaNames = payload.coaNames;
        return state;
      })
      .addCase(getActiveAccounts.rejected, (state, { payload }) => {
        state.isError = true;
        state.isRefreshing = false;
        return state;
      })
      .addCase(getActiveAccounts.pending, (state) => {
        return state;
      });
  },
});
export const {
  reset,
  resetCurrentSpreadSheet,
  setRefreshing,
  resetActiveIds,
  setRefreshData,
  setSpreadsheetDataReference,
} = spreadsheetSlice.actions;

export default spreadsheetSlice.reducer;
