import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Xero } from "../../pages/Xero/Xero";
import { useParams } from "react-router-dom";

const { REACT_APP_BASE_API_URL } = process.env;

export default function XeroAuthentication({ setTenantId, tenantId }) {
  const [value] = useState(tenantId);
  const { entityId } = useParams();
  const { tenants } = useSelector((state) => state.xero);

  const xeroConnect = async () => {
    const response = await axios.post(REACT_APP_BASE_API_URL + "/xero/connect");
    window.location.href = response.data.url;
  };

  const refreshToken = async () => {
    const token = localStorage.getItem("accessToken");
    await axios({
      method: "post",
      url: REACT_APP_BASE_API_URL + "/xero/refreshToken",
      data: {
        email: JSON.parse(localStorage.getItem("user_data")).email,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const fetchXeroData = async () => {
    const token = localStorage.getItem("accessToken");

    await axios({
      method: "post",
      url: REACT_APP_BASE_API_URL + "/xero/fetchXeroData",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: entityId,
        tenant: value,
      },
    });
  };

  return (
    <Xero
      value={value}
      setValue={setTenantId}
      tenants={tenants}
      xeroConnect={xeroConnect}
      refreshToken={refreshToken}
      fetchXeroData={fetchXeroData}
    />
  );
}
