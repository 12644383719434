import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const getProfitLossData = createAsyncThunk(
  "profitAndLoss/getProfitLossData",
  async (
    { entityId, accountingFirmId, spreadSheetId, reportingMonth },
    thunkAPI
  ) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/profit-and-loss/${entityId}/${spreadSheetId}?reportingMonth=${reportingMonth}`;
    return handleRequest(
      endpoint,
      {},
      "getProfitLossData",
      thunkAPI,
      null,
      "GET",
      true
    );
  }
);

const initialState = {
  data: [],
  plData: { rows: [], columns: [] },
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const profitAndLossSlice = createSlice({
  name: "profitAndLoss",
  initialState,
  reducers: {
    resetData: (state) => {
      state.plData = { rows: [], columns: [] };
      return state;
    },
  },
  extraReducers: {
    [getProfitLossData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.plData = payload.plData;
      localStorage.setItem(
        "reportList",
        JSON.stringify(payload.plData?.reportList)
      );
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getProfitLossData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
      return state;
    },
    [getProfitLossData.pending]: (state) => {
      state.isFetching = true;
      return state;
    },
  },
});
export const { resetData } = profitAndLossSlice.actions;

export default profitAndLossSlice.reducer;
