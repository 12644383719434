import { useState } from "react";

export default function RadialChart(props) {
  const { percentage, title, colorType } = props;
  const [value, setValue] = useState(1);
  setTimeout(() => {
    setValue(-(125.5 - ((100 - percentage) * 125.5) / 100));
  }, 500);
  return (
    <div style={{ textAlign: "center" }}>
      <label className="dashboard-h2">{title}</label>
      <svg width="100%" viewBox="0 0 100 55" fill="none" strokeWidth="7">
        <circle stroke="silver" cx="50%" cy="100%" r="40" />
        <circle
          style={{ transition: "1s ease-in" }}
          stroke={
            colorType === 1
              ? "#0F71FD"
              : colorType === 2
              ? "#50B5FF"
              : "#00EACF"
          }
          cx="50%"
          cy="100%"
          r="40"
          strokeDashoffset={value}
          strokeDasharray={"126 126"}
          strokeWidth="7"
        />
        <g transform="translate(50 55) rotate(-90) rotate(360)">
          <line
            x1="36"
            y1="0"
            x2="44.5"
            y2="0"
            stroke="#FFC700"
            strokeWidth="1"
          />
          <text
            transform="translate(44 -2) rotate(90) translate(-5 -2)"
            fontSize="6"
            fill="grey"
            fontWeight={"bold"}
          >
            13.76K
          </text>
          <text
            transform="translate(12 -3) rotate(90) translate(-5 -2)"
            fontSize="6"
            fill="#06A561"
            fontWeight={"bold"}
          >
            10.5%
          </text>

          <text
            transform="translate(2 -10) rotate(90) translate(-5 -2)"
            fontSize="9"
            fill="black"
            fontWeight={"bold"}
          >
            10.5M
          </text>
        </g>
      </svg>
    </div>
  );
}
