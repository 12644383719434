import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const { BASE_API_URL } = process.env;
export const budgetComparisionData = createAsyncThunk(
  "budgetComparision/data",
  async (_, thunkAPI) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BASE_API_URL + "/budgetComparision/getBudgetComparisionData",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const initialState = {
  dataRows: [],
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const budgetComparisionSlice = createSlice({
  name: "budgetComparision",
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: {
    [budgetComparisionData.fulfilled]: (state, { payload }) => {
      state.dataRows = payload.dataRows;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [budgetComparisionData.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
    },
    [budgetComparisionData.pending]: (state) => {
      state.isFetching = true;
    },
  },
});
export const { resetData } = budgetComparisionSlice.actions;

export default budgetComparisionSlice.reducer;
