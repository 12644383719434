import * as React from "react";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  BoltRounded,
  GridViewRounded,
  MonetizationOnOutlined,
  GridOnRounded,
  TableChartOutlined,
} from "@mui/icons-material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import { FolderOpenRounded } from "@mui/icons-material";
import { LeaderboardRounded } from "@mui/icons-material";
import { HomeRounded } from "@mui/icons-material";
import { PeopleOutlineTwoTone } from "@mui/icons-material";
import { Settings } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../CustomModal/ConfirmModal";
import { Divider } from "@mui/material";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.down("sm")]: {
    width: 0,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  zIndex: 2,
  position: "absolute",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const renderIcon = (type) => {
  switch (type) {
    case "dashboard":
      return <GridViewRounded />;
    case "profitability":
      return <BoltRounded />;
    case "cashPosition":
      return <FolderOpenRounded />;
    case "financialPosition":
      return <MonetizationOnOutlined />;
    case "profitAndLoss":
      return <TableChartOutlined />;
    case "budgetComparision":
      return <LeaderboardRounded />;
    case "settings":
      return <Settings />;
    case "clientSpaces":
      return <HomeRounded />;
    case "team":
      return <PeopleOutlineTwoTone />;
    case "spreadSheets":
      return <GridOnRounded />;
    case "firmProfile":
      return <AdminPanelSettingsOutlinedIcon />;
    case "userProfile":
      return <SettingsApplicationsOutlinedIcon />;
    default:
      return <></>;
  }
};

export default function SidebarCustom(props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { accountingFirmId, clientSpaceId, entityId } = useParams();
  const { open } = props;
  const role = localStorage.getItem("role");
  const labels = {
    // dashboard: "Dashboard",
    // cashPosition: "Cash Position",
    // budgetComparision: "Budget Comparison",
    profitAndLoss: "Profit & Loss",
    spreadSheets: "Sheets",
    // profitability: "Profitability",
    // financialPosition: "Finance Position",
    settings: "Chart of Accounts",
  };

  const adminLabels = {
    clientSpaces: "clientSpaces",
    team: "team",
    userProfile: "userProfile",
    firmProfile: role === "ADMIN" && "firmProfile",
  };
  const path = pathname.split("/");

  const [modalOpen, setModalOpen] = React.useState(false);

  return (
    <Drawer
      variant={"permanent"}
      open={open}
      onClose={() => props.setOpen(!open)}
      style={{ zIndex: 1000 }}
    >
      <DrawerHeader />
      {path.length >= 8 && !path.includes("onboarding") && (
        <List>
          {Object.keys(labels).map((value, index) => {
            let selected = pathname.split("/").includes(value);
            return (
              <ListItem
                key={value + index}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  id={`nav-${value.split(" ").join("")}`}
                  style={{
                    backgroundColor: selected ? "#0F71FD" : "#FFFFFF",
                    borderRadius: "10px",
                    margin: "10px",
                    color: selected ? "white" : "grey",
                  }}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate(
                      `accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${entityId}/${value}`
                    );
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: selected ? "white" : "grey",
                    }}
                  >
                    {renderIcon(value)}
                  </ListItemIcon>
                  {open && <ListItemText primary={labels[value]} />}
                </ListItemButton>
              </ListItem>
            );
          })}
          <Divider />
        </List>
      )}

      <List>
        {Object.values(adminLabels).map((value, index) => {
          let selected = pathname.split("/").includes(value) && path.length < 6;
          return (
            <ListItem key={value} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                style={{
                  backgroundColor: selected ? "#0F71FD" : "#FFFFFF",
                  borderRadius: "10px",
                  margin: "10px",
                  color: selected ? "white" : "grey",
                }}
                id={`nav-${value}`}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  path.length < 7 ||
                  value === "userProfile" ||
                  value === "firmProfile"
                    ? navigate(`accountingFirm/${accountingFirmId}/${value}`, {
                        state: { data: pathname.split("/")[2] },
                      })
                    : setModalOpen(true);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: selected ? "white" : "grey",
                  }}
                >
                  {renderIcon(value)}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={
                      value && value.charAt(0).toUpperCase() + value.slice(1)
                    }
                  />
                )}
              </ListItemButton>
              <ConfirmModal
                message="Do you want to return back to Admin Panel?"
                declineFunction={() => setModalOpen(false)}
                confirmFunction={() => {
                  localStorage.removeItem("currentEntityId");
                  navigate(`/accountingFirm/${accountingFirmId}/clientSpaces`);
                  setModalOpen(false);
                }}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
              />
              {index === 1 && <Divider />}
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}
