import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleRequest } from "../../helper/utils";

export const getExpenseMapping = createAsyncThunk(
  "expense/getExpenseMapping",
  async ({ entityId, accountingFirmId }, thunkAPI) => {
    const endpoint = `/accountingFirm/${accountingFirmId}/spreadsheets/get-account-mapping/expense/${entityId}`;
    return handleRequest(
      endpoint,
      null,
      "getExpenseMapping",
      thunkAPI,
      null,
      "GET",
      true
    );
  }
);

const initialState = {
  mappingData: [],
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: {
    [getExpenseMapping.fulfilled]: (state, { payload }) => {
      state.mappingData = [...payload.accountMapping];
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getExpenseMapping.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
    },
    [getExpenseMapping.pending]: (state) => {
      state.isFetching = true;
    },
  },
});
export const { resetData } = expenseSlice.actions;

export default expenseSlice.reducer;
