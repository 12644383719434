import React, { Fragment } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { Add } from "@mui/icons-material";

const CustomAccordion = ({
  summary,
  children,
  expanded,
  handleChange,
  openModal,
  hasData = false,
  disabled = false,
}) => {
  return (
    <Accordion
      sx={{ mb: 2, borderRadius: 2 }}
      expanded={expanded}
      onChange={handleChange}
      disableGutters={true}
    >
      <AccordionSummary
        expandIcon={hasData ? <ExpandMoreIcon /> : <></>}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{
          fontSize: "18px",
          backgroundColor: hasData ? "#B3D4FF" : "white",
          borderRadius: 2,
        }}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>{summary}</Grid>
          <Grid item mr={2}>
            <Button
              disabled={disabled}
              variant="text"
              onClick={(event) => {
                event.stopPropagation();
                openModal();
              }}
            >
              <Add sx={{ ml: 1 }} />
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <Grid>{children}</Grid>
    </Accordion>
  );
};

export default CustomAccordion;
