import { Grid } from "@mui/material";

export default function UnreconciledTransactionTile(props) {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={12}>
        <label className="dashboard-h2">Unreconciled Transactions</label>
      </Grid>
      <Grid item xs={12} marginTop={1}>
        <Grid container>
          <Grid item xs={6}>
            <label className="dashboard-h2" style={{ color: "grey" }}>
              14
            </label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <label className="dashboard-h2">Last Refresh Time</label>
      </Grid>
      <Grid item xs={12} marginTop={1}>
        <Grid container>
          <Grid item xs={12}>
            <label className="dashboard-h2" style={{ color: "grey" }}>
              Fri 22 Nov 19:33 pm
            </label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
