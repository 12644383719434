import { Backdrop, CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: "#fff",
    backgroundColor: "#808080c2",
  },
}));
export default function Loading(props) {
  const classes = useStyles();
  return (
    <div style={{ width: "100%", display: "flex" }}>
      {props?.wait && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress
            style={{ margin: "auto", marginTop: props.marginTop || "30vh" }}
          />
        </Backdrop>
      )}
      {!props?.wait && (
        <CircularProgress
          style={{ margin: "auto", marginTop: props.marginTop || "30vh" }}
        />
      )}
    </div>
  );
}
