import { Grid } from "@mui/material";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

export default function MarginComparisionTile(props) {
  const { responseMonth } = props;

  return (
    <Grid item xs={12} lg={12} xl={12} className="container">
      <Grid container padding={1} marginTop={1} paddingBottom={4} spacing={1}>
        <Grid item xs={12} marginBottom={2}>
          <label className="dashboard-h2">Monthly Margin Comparision</label>
        </Grid>
        <ResponsiveContainer width="99%" height={280}>
          <LineChart
            margin={{ top: 20, right: 30, left: 5, bottom: 5 }}
            style={{ marginTop: "20px" }}
            data={responseMonth}
          >
            <XAxis dataKey="name" axisLine={false} tickLine={false} />
            <YAxis
              unit={"%"}
              axisLine={false}
              tickLine={false}
              interval={2}
              tickCount={15}
            />
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />

            <Legend
              wrapperStyle={{ top: -10, left: 10 }}
              verticalAlign="top"
              align="left"
              iconType={"square"}
              formatter={(value, entry, index) => (
                <span
                  style={{
                    color: "black",
                    fontSize: "16px",
                    marginTop: "5px",
                  }}
                >
                  {value}
                </span>
              )}
            />
            <Line
              type="linear"
              dataKey="Gross Profit Margin"
              stroke="#0F71FD"
              strokeWidth={2}
              dot={false}
              unit="%"
            />
            <Line
              type="linear"
              dataKey="EBITDA Margin"
              stroke="#50B5FF"
              strokeWidth={2}
              dot={false}
              unit="%"
            />
            <Line
              type="linear"
              dataKey="Net Profit Margin"
              stroke="#00EACF"
              strokeWidth={2}
              dot={false}
              unit="%"
            />
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
}
