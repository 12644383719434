import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TableListCard from "../../../components/TableListCard/TableListCard";
import NoDataView from "../../../components/NoDataView/NoDataView";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/LoadingIndicator/Loading";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ColorPalette } from "../../../constants/colorPalette";
import { toTitleCase } from "../../../helper/titleCaseConverter";
import { Typography } from "@mui/material";
import ConfirmModal from "../../../components/CustomModal/ConfirmModal";
import EditEntityModal from "./editEntityModal/EditEntityModal";
import { useDispatch } from "react-redux";
import { deleteEntity } from "../../../redux/ClientSpace/ClientSpaceSlice";

export default function SpaceEntities({
  title,
  subtitle,
  data,
  setModalOpen,
  deleteFunction,
  setType,
  setEditValues,
  setCurrentEntityId,
  isFetching,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientSpaceId, accountingFirmId } = useParams();
  const [isLoading, setIsLoading] = useState(isFetching);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editSpaceModalOpen, setEditSpaceModalOpen] = useState(false);
  const [deleteSpaceModalOpen, setDeleteSpaceModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const handleMenuItemClick = (event, row) => {
    setAnchorEl({ [row?.id]: event.currentTarget });
    setEditData(row);
    setDeleteId(row?.id);
  };

  const handleMenuItemClose = () => {
    setAnchorEl(null);
  };

  const handleEditModal = () => {
    setEditSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDeleteModal = () => {
    setDeleteSpaceModalOpen(true);
    handleMenuItemClose();
  };

  const handleDelete = () => {
    dispatch(deleteEntity({ accountingFirmId, id: deleteId }));
    setDeleteSpaceModalOpen(false);
  };

  return (
    <div>
      {editSpaceModalOpen && (
        <EditEntityModal
          modalOpen={editSpaceModalOpen}
          setModalOpen={setEditSpaceModalOpen}
          editData={editData}
        />
      )}
      {deleteSpaceModalOpen && (
        <ConfirmModal
          message="Are you sure you want to delete the entity?"
          confirmText="Yes"
          declineText="No"
          declineFunction={() => setDeleteSpaceModalOpen(false)}
          confirmFunction={handleDelete}
          modalOpen={deleteSpaceModalOpen}
          setModalOpen={setDeleteSpaceModalOpen}
          title={"Delete Entity"}
          tooltipMessage={"Delete Impacts (Spreadsheets, Xero Data) "}
        />
      )}
      {!isLoading && data.length !== 0 && (
        <div>
          <TableListCard
            title={title}
            subtitle={subtitle}
            addTitle={"Add Entity"}
            setModalOpen={setModalOpen}
            setType={setType}
            buttonId={"addEntity"}
            titleId={"entityList"}
          >
            <Grid container>
              <Grid xs={12} item>
                <TableContainer
                  style={{
                    maxHeight: "60vh",
                    overflowY: "auto",
                    maxWidth: {
                      xs: 260,
                      sm: 290,
                      md: 580,
                      lg: "inherit",
                    },
                  }}
                >
                  <Table>
                    <TableHead
                      style={{
                        position: "sticky",
                        top: "0",
                        zIndex: 1,
                        backgroundColor: ColorPalette.white,
                      }}
                    >
                      <TableRow
                        sx={{
                          "*": {
                            paddingX: 3,
                          },
                        }}
                      >
                        <TableCell className="table-header">Name</TableCell>
                        <TableCell className="table-header">Type</TableCell>
                        <TableCell className="table-header"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row, index) => (
                        <TableRow
                          key={row.name + index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                            "*": {
                              paddingX: 3,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" width={"55%"}>
                            {toTitleCase(row.name)}
                          </TableCell>
                          <TableCell width={"25%"}>
                            {toTitleCase(row.type)}
                          </TableCell>
                          <TableCell scope="row" align="right" width={"20%"}>
                            <Button
                              id={`button-view[${row?.id}]`}
                              style={{
                                textTransform: "none",
                                marginRight: 30,
                              }}
                              disabled={
                                row.isOnboarded ? !row.xeroRefresh : false
                              }
                              size="small"
                              variant="outlined"
                              onClick={() => {
                                localStorage.removeItem("currentEntityId");
                                localStorage.setItem("currentEntityId", row.id);
                                let route = `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entity/${row.id}`;
                                row.isOnboarded
                                  ? navigate(route + "/profitAndLoss")
                                  : navigate(route + "/onboarding");
                              }}
                            >
                              View
                            </Button>
                            <IconButton
                              id="button-clientEntityView"
                              sx={{
                                height: 40,
                                width: 40,
                              }}
                              color="inherit"
                              onClick={(event) =>
                                handleMenuItemClick(event, row)
                              }
                            >
                              <SettingsOutlinedIcon
                                style={{
                                  color: "grey",
                                  fontSize: 20,
                                }}
                              />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl?.[row?.id]}
                              open={Boolean(anchorEl?.[row?.id])}
                              onClose={handleMenuItemClose}
                            >
                              <MenuItem>
                                <Typography
                                  className="menuItemTypography"
                                  onClick={handleEditModal}
                                >
                                  <EditOutlinedIcon className="mr-10" />
                                  Edit
                                </Typography>
                              </MenuItem>
                              <MenuItem>
                                <Typography
                                  style={{ color: "#D33030" }}
                                  className="menuItemTypography"
                                  onClick={handleDeleteModal}
                                >
                                  <DeleteOutlineOutlinedIcon className="mr-10" />
                                  Delete
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </TableListCard>
        </div>
      )}
      {!isLoading && data.length === 0 && (
        <NoDataView
          hintText="Sorry, you haven't added any entities"
          buttonText="Add Entity"
          addFunction={setModalOpen}
        />
      )}
      {isLoading && <Loading />}
    </div>
  );
}
