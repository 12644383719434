import React from "react";
import { getCellProperty } from "@silevis/reactgrid";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export class DateCell {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string");
    let toggleDatePicker = () => {};
    let id = "";
    try {
      toggleDatePicker = getCellProperty(
        uncertainCell,
        "toggleDatePicker",
        "function"
      );
    } catch {
      toggleDatePicker = () => {};
    }

    try {
      id = getCellProperty(uncertainCell, "id", "string");
    } catch {
      id = "";
    }

    const value = parseFloat(text);
    return {
      ...uncertainCell,
      text,
      value,
      toggleDatePicker,
      id,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
    });
  }

  render(cell, isInEditMode, onCellChanged) {
    return (
      <>
        {!cell.nonEditable && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={cell.disabled}
              value={dayjs(cell.text)}
              fixedHeight={true}
              tetherConstraints={[]}
              slotProps={{
                textField: {
                  size: "small",
                  variant: "standard",
                },
              }}
              views={["year", "month"]}
              format="MMM YYYY"
              sx={{
                "& .MuiInput-root.Mui-error::before": {
                  borderBottomColor: "transparent",
                },
                "& .MuiInput-root.Mui-error::after": {
                  borderBottomColor: "transparent",
                },
                "& .MuiInput-underline::before": {
                  borderBottomColor: "transparent",
                  borderBottom: 0,
                },
                "& .MuiInputBase-root::after": {
                  borderBottomColor: "transparent",
                },
                "& .MuiInputBase-root:hover::before": {
                  opacity: 0,
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </LocalizationProvider>
        )}
      </>
    );
  }
}
