import React from "react";
import { getCellProperty } from "@silevis/reactgrid";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";

export class AccountMappingCell {
  getCompatibleCell(uncertainCell) {
    const text = getCellProperty(uncertainCell, "text", "string");
    let toggleAccountMapping = () => {};
    let id = "";
    try {
      toggleAccountMapping = getCellProperty(
        uncertainCell,
        "toggleAccountMapping",
        "function"
      );
    } catch {
      toggleAccountMapping = () => {};
    }

    try {
      id = getCellProperty(uncertainCell, "id", "string");
    } catch {
      id = "";
    }

    const value = parseFloat(text);
    return {
      ...uncertainCell,
      text,
      value,
      toggleAccountMapping,
      id,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
    });
  }

  render(cell, isInEditMode, onCellChanged) {
    return (
      <>
        <div style={{ overflow: "clip", textOverflow: "ellipsis" }}>
          {cell.text || "-"}
        </div>

        {!cell.nonEditable && (
          <div id="basic-button" style={{ marginLeft: "auto" }}>
            <IconButton
              onClick={() => {
                cell.toggleAccountMapping(cell.id);
              }}
            >
              <KeyboardArrowRightIcon fontSize="10" />
            </IconButton>
          </div>
        )}
      </>
    );
  }
}
