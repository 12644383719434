import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const { REACT_APP_BASE_API_URL } = process.env;
export const getTenants = createAsyncThunk(
  "xero/getTenants",
  async ({ entityId, accountingFirmId }, thunkAPI) => {
    const token = localStorage.getItem("accessToken");
    try {
      const body = {
        id: entityId,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        REACT_APP_BASE_API_URL +
          `/accountingFirm/${accountingFirmId}/xero/get-tenants`,
        body,
        config
      );

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(response.data);
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const initialState = {
  tenants: [],
  isFetching: true,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const xeroSlice = createSlice({
  name: "xero",
  initialState,
  reducers: {
    resetData: () => initialState,
  },
  extraReducers: {
    [getTenants.fulfilled]: (state, { payload }) => {
      const { tenants } = payload;
      state.tenants = tenants;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [getTenants.pending]: (state) => {
      state.isFetching = true;
      return state;
    },
    [getTenants.rejected]: (state, { payload }) => {
      state.isError = true;
      state.isFetching = false;
      state.errorMessage = payload;
    },
  },
});
export const { resetData } = xeroSlice.actions;

export default xeroSlice.reducer;
