import React, { useEffect, useState } from "react";
import {
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Button,
  Typography,
  Grid,
  OutlinedInput,
  InputAdornment,
  ListItemText,
  Backdrop,
  CircularProgress,
  Box,
} from "@mui/material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { updateFinancialSetting } from "../../../../redux/ClientSpace/ClientSpaceSlice";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getExpenseMapping } from "../../../../redux/Expense/ExpenseSlice";
import moment from "moment";

const entityTypes = ["COMPANY", "TRUST", "SOLE TRADER", "PARTNERSHIP"];
const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const gstBasisList = ["Cash", "Accrual"];
const payrollTiming = ["Weekly", "Fortnightly", "Monthly"];
const timeIntervalOptions = ["Monthly", "Quarterly"];
const timeIntervalOptionsInstallement = ["Monthly", "Quarterly", "None"];
const payGTimeIntervalOptions = ["Monthly", "Quarterly", "Same time as wages"];
const payGTimingOptions = ["Tax agent extension", "Client lodges"];
const monthlyPaymentMonths = [
  "Same month as expense",
  "Month after expense",
  "Month after expense, same month for last month of FY",
];
const quarterlyPaymentMonths = [
  "Last month of quarter",
  "Month after end of quarter",
  "Month after end of quarter, same month for last month of FY",
];
const gstPaymentDate = ["Client lodges", "Tax agent extension"];

export const FinancialSettingItem = ({
  title,
  selectData,
  type,
  onChange,
  defaultValue,
  isMapping,
  isHours,
  hasError,
}) => {
  const handleChange = (value) => {
    onChange(value);
  };
  const numericRegex = /^\d*\.?\d+$/;

  return (
    <div
      style={{
        display: "flex",
        marginBottom: 20,
        marginRight: 40,
        flexDirection: "column",
      }}
    >
      <Grid item flexDirection={"row"} display={"flex"} alignItems={"center"}>
        <Typography
          display={!title ? "none" : "block"}
          variant="body3"
          color={hasError ? "error" : "#3A3A3A"}
          className="mr-20"
        >
          {title} :
        </Typography>
        {type === "select" ? (
          <div>
            <Select
              autoWidth
              style={{ minWidth: "150px" }}
              size="small"
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={defaultValue || ""}
              variant="outlined"
            >
              {!isMapping
                ? selectData.map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))
                : selectData.map((type, index) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.text}
                    </MenuItem>
                  ))}
            </Select>
          </div>
        ) : type === "radio" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={defaultValue}
                onChange={() => handleChange(true)}
              />
              <Typography>Yes</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              <Checkbox
                checked={!defaultValue}
                onChange={() => {
                  handleChange(false);
                }}
              />
              <Typography>No</Typography>
            </div>
          </div>
        ) : type === "date" ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="datepicker-full"
              value={defaultValue ? dayjs(defaultValue) : null}
              minDate={moment().subtract(2, "years")}
              slotProps={{ textField: { size: "small" } }}
              format="DD/MM/YYYY"
              onChange={(value) => {
                handleChange(dayjs(value).format("YYYY-MM-DD"));
              }}
            />
          </LocalizationProvider>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{ width: "100px" }}
                id="financial_settings"
                type="number"
                size="small"
                value={parseFloat(defaultValue).toString() || ""}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  if (
                    numericRegex.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    const value = e.target.value;
                    if (value <= (isHours ? 168 : 100)) {
                      handleChange(value);
                    }
                  }
                }}
                x
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!isHours ? "%" : "hrs"}
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
          </div>
        )}
      </Grid>
      <Grid item display={hasError ? "block" : "none"}>
        <Typography fontSize={13} color="error">
          * Required field
        </Typography>
      </Grid>
    </div>
  );
};

export default function FinancialSettings(props) {
  const {
    entityName,
    entityType,
    financialYearMonthEnd,
    gst,
    employees,
    incomeTax,
    rAndDTaxEligible,
    incomeTaxReturnMonth,
    accountingFirmId,
    _id,
    isOnboarding = false,
    orgData,
    setFinancialSettingsSaved,
  } = props;

  const [isGSTRegistered, setIsGSTRegistered] = useState(
    gst?.registered ?? false
  );
  const [isEmployees, setIsEmployees] = useState(employees?.includes ?? false);
  const [isRnDTaxEligible, setIsRnDTaxEligible] = useState(rAndDTaxEligible);
  const [errors, setErrors] = useState([]);
  const [settings, setSettings] = useState(null);
  const [initialSettings, setInitialSettings] = useState(null);
  const [paymentMonths, setPaymentMonths] = useState([]);
  const [accountMapping, setAccountMapping] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOnboarding) {
      dispatch(getExpenseMapping({ entityId: _id, accountingFirmId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountingFirmId, _id, dispatch]);

  const { mappingData: accountingMapExpense } = useSelector(
    (state) => state?.expense
  );

  useEffect(() => {
    if (accountingMapExpense) {
      const value = [
        ...new Set(
          accountingMapExpense.flatMap((data) =>
            data.children.flatMap((child) =>
              child.type === "Account Group"
                ? [
                    child,
                    ...child.children.map((subChild) => ({
                      ...subChild,
                      parent: child.id,
                    })),
                  ]
                : child
            )
          )
        ),
      ];

      const uniqueSortedValue = value
        .reduce(
          (acc, curr) =>
            acc.some((item) => item.text === curr.text) ? acc : [...acc, curr],
          []
        )
        .sort((a, b) => a.text.localeCompare(b.text));

      setAccountMapping(uniqueSortedValue);
    }
  }, [accountingMapExpense]);

  const handleCheckError = () => {
    const errors = [];
    const check = (condition, fieldName) => {
      if (!condition) errors.push(fieldName);
    };

    const {
      entityName,
      entityType,
      financialYearMonthEnd,
      gst,
      employees,
      incomeTax,
      rAndDTaxEligible,
      incomeTaxReturnMonth,
    } = settings;

    check(entityName, "entityName");
    check(entityType, "entityType");
    check(financialYearMonthEnd, "financialYearMonthEnd");
    if (gst && gst?.registered) {
      check(gst.period, "gst.period");
      check(gst.rate, "gst.rate");
      if (gst.rate === "Custom") {
        check(gst.rate !== "Custom", "gst.rate");
      }
      check(gst.basis, "gst.basis");
      check(gst.paymentDate, "gst.paymentDate");
    }

    if (employees && employees?.includes) {
      check(employees.payrollTiming, "employees.payrollTiming");
      if (accountMapping.length > 0) {
        check(
          employees.defaultPayrollMappingType,
          "employees.defaultPayrollMappingType"
        );
      }
      check(employees.standardWeeklyHours, "employees.standardWeeklyHours");
      check(employees.superannuationTiming, "employees.superannuationTiming");
      check(
        employees.nextPayrollPaymentDate,
        "employees.nextPayrollPaymentDate"
      );
      check(employees.paymentMonths, "employees.paymentMonths");
      if (
        employees.paymentMonths &&
        (employees.paymentMonths === "Custom" ||
          monthList.some((r) => employees.paymentMonths.includes(r))) &&
        employees.superannuationTiming === "Quarterly"
      ) {
        check(paymentMonths.length === 4, "employees.paymentMonths");
      }

      check(employees.superannuationRate, "employees.superannuationRate");
      if (employees.superannuationRate === "Custom") {
        check(
          employees.superannuationRate !== "Custom",
          "employees.superannuationRate"
        );
      }
      check(employees.paygOnWages, "employees.paygOnWages");
      check(employees.paygPaymentTiming, "employees.paygPaymentTiming");
    }

    check(incomeTax, "incomeTax");
    check(incomeTax.rate, "incomeTax.rate");
    if (incomeTax.rate === "Custom") {
      check(incomeTax.rate !== "Custom", "incomeTax.rate");
    }
    check(incomeTax.installments, "incomeTax.installments");

    if (rAndDTaxEligible) {
      check(incomeTaxReturnMonth, "incomeTaxReturnMonth");
    }

    setErrors(errors);
    if (errors.length <= 0) {
      setIsLoading(true);
      handleSave();
    } else {
      isOnboarding && setFinancialSettingsSaved(false);
    }
  };

  const handleCustomPaymentMonths = (event) => {
    setErrors([]);
    const {
      target: { value },
    } = event;
    if (value.length < 5) {
      setPaymentMonths(typeof value === "string" ? value.split(",") : value);
    }
  };

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialValues = () => {
    setIsEmployees(employees?.includes ?? false);
    setIsGSTRegistered(
      gst?.registered ?? (isOnboarding ? orgData?.paysTax : false)
    );
    setIsRnDTaxEligible(rAndDTaxEligible);
    setPaymentMonths([]);
    const setting = {
      entityName,
      entityType: isOnboarding ? orgData?.organisationType : entityType,
      financialYearMonthEnd: isOnboarding
        ? monthList[orgData?.financialYearEndMonth - 1]
        : financialYearMonthEnd,
      gst: isOnboarding
        ? {
            registered:
              gst?.registered ?? (isOnboarding ? orgData?.paysTax : false),
            basis: orgData?.salesTaxBasis
              ? orgData.salesTaxBasis.charAt(0).toUpperCase() +
                orgData.salesTaxBasis.slice(1).toLowerCase()
              : undefined,
          }
        : { ...gst },
      employees: { ...employees },
      incomeTax: { ...incomeTax },
      rAndDTaxEligible,
      incomeTaxReturnMonth,
    };
    setSettings(setting);
    setInitialSettings(setting);
    if (employees?.paymentMonths?.length > 1) {
      setPaymentMonths(employees?.paymentMonths);
    }
  };

  const handleInputChange = (inputName, value) => {
    setErrors([]);
    if (inputName.includes(".")) {
      const [parent, child] = inputName.split(".");
      setSettings((prevSettings) => ({
        ...prevSettings,
        [parent]: {
          ...prevSettings[parent],
          [child]: value,
        },
      }));
    } else {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [inputName]: value,
      }));
    }
  };

  const handleSave = () => {
    var financialSettings = _.cloneDeep(settings);
    if (
      financialSettings?.employees?.paymentMonths &&
      (financialSettings?.employees?.paymentMonths === "Custom" ||
        monthList.some((r) =>
          financialSettings?.employees?.paymentMonths.includes(r)
        ))
    ) {
      financialSettings.employees.paymentMonths = paymentMonths;
    }
    setInitialSettings(financialSettings);
    dispatch(
      updateFinancialSetting({
        payload: { financialSettings, id: _id },
        accountingFirmId,
      })
    );
    setTimeout(() => {
      setIsLoading(false);
      isOnboarding && setFinancialSettingsSaved(true);
    }, 500);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid
        container
        mt={isOnboarding ? 0 : 3}
        display={"flex"}
        flexDirection={"column"}
        sx={{
          bgcolor: "white",
          pr: 4,
          pl: 4,
          pt: isOnboarding ? 0 : 2,
          pb: 3,
          borderRadius: 4,
        }}
      >
        <Box
          style={{
            maxHeight: isOnboarding ? "200vh" : "60vh",
            overflow: isOnboarding ? "visible" : "auto",
          }}
        >
          <Grid item flexDirection={"column"} mt={2}>
            <Grid container mt={3} flexDirection={"column"}>
              <div>
                <Typography variant="h6" fontWeight="600" sx={{ mb: 2 }}>
                  Fill up the financial settings detail
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Integrate your Xero or sheets account and your data with a
                  click.
                </Typography>
              </div>
            </Grid>
            <Grid item display="flex" flexDirection={"row"}>
              <FinancialSettingItem
                title={"Entity Type"}
                hasError={errors.includes("entityType")}
                type={"select"}
                selectData={entityTypes}
                onChange={(value) => handleInputChange("entityType", value)}
                defaultValue={settings?.entityType}
              />

              <FinancialSettingItem
                title={"Financial Year Month End"}
                hasError={errors.includes("financialYearMonthEnd")}
                type="select"
                selectData={monthList}
                onChange={(value) =>
                  handleInputChange("financialYearMonthEnd", value)
                }
                defaultValue={settings?.financialYearMonthEnd}
              />
            </Grid>
            <Grid>
              <FinancialSettingItem
                title={"GST Registered"}
                hasError={errors.includes("gst.registered")}
                type="radio"
                onChange={(value) => {
                  setIsGSTRegistered(value);
                  handleInputChange("gst", {});
                  handleInputChange("gst.registered", value);
                }}
                defaultValue={isGSTRegistered}
              />
              {isGSTRegistered && (
                <Grid
                  container
                  style={{
                    paddingTop: 30,
                    borderRadius: 7,
                    paddingRight: 30,
                    paddingLeft: 10,
                    border: "1px solid lightGray",
                    marginBottom: 30,
                  }}
                >
                  <Grid item>
                    <FinancialSettingItem
                      title={"GST Period"}
                      hasError={errors.includes("gst.period")}
                      type="select"
                      isRadio={true}
                      selectData={timeIntervalOptions}
                      onChange={(value) =>
                        handleInputChange("gst.period", value)
                      }
                      defaultValue={settings?.gst.period}
                    />
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"GST Payment Date"}
                      hasError={errors.includes("gst.paymentDate")}
                      type="select"
                      isRadio={true}
                      selectData={gstPaymentDate}
                      onChange={(value) =>
                        handleInputChange("gst.paymentDate", value)
                      }
                      defaultValue={settings?.gst.paymentDate}
                    />
                  </Grid>
                  <Grid item flexDirection={"row"} display={"flex"}>
                    <FinancialSettingItem
                      title={"GST Rate"}
                      hasError={errors.includes("gst.rate")}
                      type="select"
                      selectData={["10%", "Custom"]}
                      onChange={(value) => handleInputChange("gst.rate", value)}
                      defaultValue={
                        settings?.gst.rate !== undefined
                          ? settings?.gst.rate !== "10%"
                            ? "Custom"
                            : "10%"
                          : null
                      }
                    />
                    {settings?.gst.rate !== undefined &&
                      settings?.gst.rate !== "10%" && (
                        <FinancialSettingItem
                          type="input"
                          selectData={0}
                          onChange={(value) => {
                            handleInputChange("gst.rate", value);
                          }}
                          defaultValue={settings?.gst?.rate}
                        />
                      )}
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"GST Basis"}
                      hasError={errors.includes("gst.basis")}
                      type="select"
                      isRadio={true}
                      selectData={gstBasisList}
                      onChange={(value) =>
                        handleInputChange("gst.basis", value)
                      }
                      defaultValue={settings?.gst.basis}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item display="flex" flexDirection={"row"}>
              <Grid item flexDirection={"row"} display={"flex"}>
                <FinancialSettingItem
                  title={"Income Tax Rate"}
                  hasError={errors.includes("incomeTax.rate")}
                  type="select"
                  onChange={(value) =>
                    handleInputChange("incomeTax.rate", value)
                  }
                  selectData={["25%", "30%", "Custom"]}
                  defaultValue={
                    settings?.incomeTax.rate !== undefined
                      ? settings?.incomeTax.rate !== "25%" &&
                        settings?.incomeTax.rate !== "30%"
                        ? "Custom"
                        : settings?.incomeTax.rate
                      : null
                  }
                />
                {settings?.incomeTax.rate !== undefined &&
                  settings?.incomeTax.rate !== "25%" &&
                  settings?.incomeTax.rate !== "30%" && (
                    <FinancialSettingItem
                      type="input"
                      selectData={0}
                      onChange={(value) => {
                        handleInputChange("incomeTax.rate", value);
                      }}
                      defaultValue={settings?.incomeTax?.rate}
                    />
                  )}
              </Grid>
              <FinancialSettingItem
                title={"Income Tax Instalments"}
                hasError={errors.includes("incomeTax.installments")}
                type="select"
                selectData={timeIntervalOptionsInstallement}
                onChange={(value) =>
                  handleInputChange("incomeTax.installments", value)
                }
                defaultValue={settings?.incomeTax.installments}
              />
            </Grid>
            <Grid>
              <FinancialSettingItem
                title={"R&D tax eligible"}
                hasError={errors.includes("rAndDTaxEligible")}
                type="radio"
                onChange={(value) => {
                  setIsRnDTaxEligible(value);
                  handleInputChange("incomeTaxReturnMonth", undefined);
                  handleInputChange("rAndDTaxEligible", value);
                }}
                defaultValue={isRnDTaxEligible}
              />
              {isRnDTaxEligible && (
                <Grid
                  container
                  style={{
                    paddingTop: 30,
                    borderRadius: 7,
                    paddingRight: 30,
                    paddingLeft: 10,
                    border: "1px solid lightGray",
                    marginBottom: 30,
                  }}
                >
                  <Grid item>
                    {" "}
                    <FinancialSettingItem
                      title={"Income Tax Return Payment/Refund Month"}
                      hasError={errors.includes("incomeTaxReturnMonth")}
                      type="select"
                      isRadio={true}
                      selectData={monthList}
                      onChange={(value) =>
                        handleInputChange("incomeTaxReturnMonth", value)
                      }
                      defaultValue={settings?.incomeTaxReturnMonth}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid>
              <FinancialSettingItem
                title={"Employees"}
                hasError={errors.includes("employees.includes")}
                type="radio"
                onChange={(value) => {
                  setIsEmployees(value);
                  handleInputChange("employees", {});
                  handleInputChange("employees.includes", value);
                }}
                defaultValue={isEmployees}
              />
              {isEmployees && (
                <Grid
                  container
                  style={{
                    paddingTop: 30,
                    borderRadius: 7,
                    paddingRight: 30,
                    paddingLeft: 10,
                    border: "1px solid lightGray",
                    marginBottom: 30,
                  }}
                >
                  <Grid item>
                    <FinancialSettingItem
                      title={"Next Payroll Payment Date"}
                      hasError={errors.includes(
                        "employees.nextPayrollPaymentDate"
                      )}
                      type="date"
                      onChange={(value) =>
                        handleInputChange(
                          "employees.nextPayrollPaymentDate",
                          value
                        )
                      }
                      defaultValue={settings?.employees.nextPayrollPaymentDate}
                    />
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"Payroll Timing"}
                      hasError={errors.includes("employees.payrollTiming")}
                      type="select"
                      selectData={payrollTiming}
                      onChange={(value) =>
                        handleInputChange("employees.payrollTiming", value)
                      }
                      defaultValue={settings?.employees.payrollTiming}
                    />
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"Standard Weekly Hours"}
                      hasError={errors.includes(
                        "employees.standardWeeklyHours"
                      )}
                      type="input"
                      isHours={true}
                      onChange={(value) =>
                        handleInputChange(
                          "employees.standardWeeklyHours",
                          value
                        )
                      }
                      defaultValue={settings?.employees.standardWeeklyHours}
                    />
                  </Grid>
                  {accountMapping.length > 0 && (
                    <Grid item>
                      <FinancialSettingItem
                        title={"Default Payroll Mapping Account"}
                        hasError={errors.includes(
                          "employees.defaultPayrollMappingType"
                        )}
                        type="select"
                        isMapping={true}
                        selectData={accountMapping}
                        onChange={(value) =>
                          handleInputChange(
                            "employees.defaultPayrollMappingType",
                            accountMapping.find((val) => val.id === value)
                          )
                        }
                        defaultValue={
                          settings?.employees?.defaultPayrollMappingType?.id
                        }
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <FinancialSettingItem
                      title={"Superannuation Payment Timing "}
                      hasError={errors.includes(
                        "employees.superannuationTiming"
                      )}
                      type="select"
                      isRadio={true}
                      selectData={timeIntervalOptions}
                      onChange={(value) => {
                        handleInputChange(
                          "employees.superannuationTiming",
                          value
                        );
                        handleInputChange("employees.paymentMonths", null);
                      }}
                      defaultValue={settings?.employees.superannuationTiming}
                    />
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"Select Payment Months"}
                      hasError={errors.includes("employees.paymentMonths")}
                      type="select"
                      selectData={
                        settings?.employees.superannuationTiming === "Quarterly"
                          ? quarterlyPaymentMonths
                          : monthlyPaymentMonths
                      }
                      onChange={(value) =>
                        handleInputChange("employees.paymentMonths", value)
                      }
                      defaultValue={
                        (Array.isArray(settings?.employees?.paymentMonths) &&
                          settings?.employees?.paymentMonths.length > 1) ||
                        settings?.employees?.paymentMonths === "Custom"
                          ? "Custom"
                          : settings?.employees?.paymentMonths
                      }
                    />
                  </Grid>
                  <Grid item>
                    {((Array.isArray(settings?.employees?.paymentMonths) &&
                      settings?.employees?.paymentMonths.length > 1) ||
                      settings?.employees?.paymentMonths === "Custom") &&
                      settings?.employees.superannuationTiming ===
                        "Quarterly" && (
                        <Grid item display={"flex"} flexDirection={"column"}>
                          <Select
                            multiple
                            size="small"
                            sx={{ mr: "20px" }}
                            displayEmpty
                            variant="outlined"
                            style={{ width: "250px" }}
                            value={paymentMonths}
                            onChange={handleCustomPaymentMonths}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select 4 Payment Months</em>;
                              }
                              return selected.join(", ");
                            }}
                          >
                            {monthList.map((month) => (
                              <MenuItem key={month} value={month}>
                                <Checkbox
                                  checked={paymentMonths.indexOf(month) > -1}
                                />
                                <ListItemText primary={month} />
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography
                            display={
                              errors.length > 0 && paymentMonths.length !== 4
                                ? "block"
                                : "none"
                            }
                            fontSize={14}
                            color="error"
                          >
                            Please select exactly 4 months for payment months
                          </Typography>
                        </Grid>
                      )}
                  </Grid>
                  <Grid item flexDirection={"row"} display={"flex"}>
                    <FinancialSettingItem
                      title={"Superannuation Rate"}
                      hasError={errors.includes("employees.superannuationRate")}
                      type="select"
                      selectData={["Statutory Rate", "Custom"]}
                      onChange={(value) => {
                        handleInputChange(
                          "employees.superannuationRate",
                          value
                        );
                      }}
                      defaultValue={
                        settings?.employees.superannuationRate !== undefined
                          ? settings?.employees.superannuationRate !==
                            "Statutory Rate"
                            ? "Custom"
                            : "Statutory Rate"
                          : null
                      }
                    />
                    {settings?.employees.superannuationRate !== undefined &&
                      settings?.employees.superannuationRate !==
                        "Statutory Rate" && (
                        <FinancialSettingItem
                          type="input"
                          selectData={0}
                          onChange={(value) =>
                            handleInputChange(
                              "employees.superannuationRate",
                              value
                            )
                          }
                          defaultValue={settings?.employees.superannuationRate}
                        />
                      )}
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"PAYG on Wages"}
                      hasError={errors.includes("employees.paygOnWages")}
                      type="select"
                      isRadio={true}
                      selectData={payGTimeIntervalOptions}
                      onChange={(value) =>
                        handleInputChange("employees.paygOnWages", value)
                      }
                      defaultValue={settings?.employees.paygOnWages}
                    />
                  </Grid>
                  <Grid item>
                    <FinancialSettingItem
                      title={"PAYG Payment Timing"}
                      hasError={errors.includes("employees.paygPaymentTiming")}
                      type="select"
                      isRadio={true}
                      selectData={payGTimingOptions}
                      onChange={(value) =>
                        handleInputChange("employees.paygPaymentTiming", value)
                      }
                      defaultValue={settings?.employees?.paygPaymentTiming}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>

        <Grid item mt={2} flexGrow={1} textAlign={"right"}>
          <Button
            onClick={handleCheckError}
            variant="contained"
            disabled={
              errors?.length > 0 || _.isEqual(settings, initialSettings)
            }
            color={"primary"}
            className="ml-10"
          >
            Save
          </Button>
          <Button
            onClick={() => {
              setErrors([]);
              setInitialValues();
            }}
            disabled={_.isEqual(settings, initialSettings)}
            variant="outlined"
            color="primary"
            className="ml-10"
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
