import React, { useEffect } from "react";
import "./InviteUser.css";
import { Formik } from "formik";
import { CircularProgress, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/formulr-logo.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addInvitedUser, reset } from "../../../redux/User/UserSlice";
import { Buffer } from "buffer";
import { ColorPalette } from "../../../constants/colorPalette";

export default function InviteUser() {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const encodedEmail = params.get("email");
  const accountingFirmId = params.get("accountingFirmId");
  const role = params.get("role");
  const email = encodedEmail
    ? Buffer.from(encodedEmail.toString(), "base64").toString("utf-8")
    : "";

  const { isSuccess, isError } = useSelector((state) => state.user);
  const onSubmit = (data, setSubmitting) => {
    dispatch(addInvitedUser(data));
  };
  useEffect(() => {
    if (isError) {
      dispatch(reset());
    }
    if (isSuccess) {
      dispatch(reset());
      navigate("../login");
    }
  }, [isError, isSuccess, dispatch, navigate]);
  return (
    <div>
      <div className="login-wrapper">
        <Formik
          initialValues={{
            password: "",
            passwordConfirm: "",
            first_name: "",
            last_name: "",
            email: email,
            accountingFirmId,
            role,
          }}
          validate={(values) => {
            const errors = {};
            if (values.password.length === 0) {
              errors.isPasswordEmpty = true;
              errors.password = "Required Field";
            }
            if (
              values.password.length !== 0 &&
              !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values.password)
            ) {
              errors.password =
                "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
            }
            if (!values.passwordConfirm) {
              errors.isPasswordConfirmEmpty = true;
              errors.passwordConfirm = "Required Field";
            }
            if (values.password && values.passwordConfirm) {
              if (values.password !== values.passwordConfirm) {
                errors.passwordConfirm = "Please enter the same password.";
              }
            }
            if (!values.first_name) {
              errors.first_name = "Required Field";
            }
            if (!values.last_name) {
              errors.last_name = "Required Field";
            }
            return errors;
          }}
          onSubmit={(data, { setSubmitting }) => {
            onSubmit(data, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              className="form-wrapper-reset-password"
              onSubmit={handleSubmit}
            >
              <div
                style={{
                  paddingTop: "8%",
                  paddingBottom: "5%",
                  alignContent: "center",
                }}
              >
                <img
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={logo}
                  alt="Logo"
                />
              </div>
              <Grid justifyContent="center" container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    disabled
                    error={!!errors.email && !!touched.email}
                    helperText={errors.email}
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.first_name && !!touched.first_name}
                    helperText={errors.first_name}
                    variant="outlined"
                    label="First name"
                    fullWidth
                    size="medium"
                    type="text"
                    name="first_name"
                    onChange={handleChange}
                    onBlur={() => {
                      values.first_name = values.first_name?.trim();
                    }}
                    value={values.first_name}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.last_name && !!touched.last_name}
                    helperText={errors.last_name}
                    variant="outlined"
                    label="Last name"
                    fullWidth
                    size="medium"
                    type="text"
                    name="last_name"
                    onChange={handleChange}
                    onBlur={() => {
                      values.last_name = values.last_name?.trim();
                    }}
                    value={values.last_name}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={!!errors.password && !!touched.password}
                    fullWidth
                    label="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                  >
                    {errors.password && !!touched.password && errors.password}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    error={
                      !!errors.passwordConfirm && !!touched.passwordConfirm
                    }
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    name="passwordConfirm"
                    onChange={handleChange}
                    value={values.passwordConfirm}
                  />
                  <Typography
                    id="error-groupRequiredField"
                    variant="caption"
                    color={ColorPalette.danger}
                    pl={1}
                  >
                    {errors.passwordConfirm &&
                      !!touched.passwordConfirm &&
                      errors.passwordConfirm}
                  </Typography>
                </Grid>

                <Grid item xs={6} marginBottom={4}>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <CircularProgress
                        size={24}
                        thickness={4}
                        sx={{ color: "white" }}
                      />
                    ) : (
                      "Register"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}
