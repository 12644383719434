import React from "react";
import { getCellProperty } from "@silevis/reactgrid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { IconButton } from "@mui/material";

export class ActionCell {
  getCompatibleCell(uncertainCell) {
    let toggleSideNav = () => {};
    let totalRow = false;
    try {
      toggleSideNav = getCellProperty(
        uncertainCell,
        "toggleSideNav",
        "function"
      );
    } catch {
      toggleSideNav = () => {};
    }

    try {
      totalRow = getCellProperty(uncertainCell, "totalRow", "boolean");
    } catch {
      totalRow = false;
    }

    return {
      ...uncertainCell,
      toggleSideNav,
      totalRow,
    };
  }

  update(cell, cellToMerge) {
    return this.getCompatibleCell({
      ...cell,
      isExpanded: cellToMerge.isExpanded,
      totalRow: cellToMerge.totalRow,
      text: cellToMerge.text,
    });
  }

  getStyle(cell) {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.4;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  render(cell) {
    return (
      <>
        {cell.active && <></>}
        {!cell.active && (
          <div>
            <IconButton
              disabled={cell.disabled}
              size="small"
              onClick={() => {
                cell.toggleSideNav();
              }}
            >
              <BorderColorIcon fontSize="10" />
            </IconButton>
          </div>
        )}
      </>
    );
  }
}
