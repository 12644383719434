/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";

import {
  Box,
  Accordion,
  Divider,
  AccordionSummary,
  Drawer,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  InputLabel,
  MenuItem,
  Tabs,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Radio,
  FormControlLabel,
  RadioGroup,
  styled,
  Switch,
} from "@mui/material";
import _ from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DEFAULT_KEYS, defaultModalValues, MONTH_MAP } from "../defaultValues";
import SpreadSheetTable from "../SpreadSheetTable";
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
  Close,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import {
  monthName,
  getTimingRange,
  handleReferenceUpdate,
  peoplePayrollCalculation,
  calculateMonthlySalary,
} from "../helper";
import { SideNavStyledTab } from "../SpreadSheet";
import CustomAccordion from "../components/CustomAccordion";
import { useDispatch, useSelector } from "react-redux";
import {
  setRefreshing,
  updateSpreadsheet,
  setSpreadsheetDataReference,
} from "../../../../redux/SpreadSheet/SpreadSheetSlice";
import ConfirmModal from "../../../../components/CustomModal/ConfirmModal";
import InfoCard from "../components/InfoCard";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { toast } from "react-toastify";
import { ColorPalette } from "../../../../constants/colorPalette";

var ObjectID = require("bson-objectid");
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 20,
  padding: 0,
  marginLeft: 10,
  marginRight: 10,
  marginTop: 2,

  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 14,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(15px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 14,
    height: 14,
    marginTop: 1,
    marginLeft: 1,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 300,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 10,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
    boxSizing: "border-box",
  },
}));

export default function People({
  durationStart,
  durationEnd,
  addEmployeeOpen,
  setAddEmployeeOpen,
  peopleData,
  entityId,
  spreadSheetId,
  refreshData,
  accountingFirmId,
  spreadSheet,
  durationData,
  setSpreadSheet,
  showTerminated,
  paymentType,
  setTabSwitch,
  tabValue,
}) {
  const numericRegex = /^\d*\.?\d+$/;
  const startDate = moment(durationStart).clone();
  const endDate = moment(durationEnd).clone();
  const [hasError, setHasError] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [headerRow, setHeaderRow] = useState(peopleData?.headers);
  const [value, setValue] = useState(peopleData?.rows);
  const [expanded, setExpanded] = useState(null);
  const [sideNavData, setSideNavData] = useState(null);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [currentSideNavTab, setCurrentSideNavTab] = useState(0);
  const [sideNavFormulaData, setSideNavFormulaData] = useState(null);
  const [changeInfoValue, setChangeInfoValue] = useState(null);
  const [isHourlyPaymentType, setIsHourlyPaymentType] = useState(true);
  const [modalValues, setModalValues] = useState(defaultModalValues);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModalId, setOpenModalId] = useState(null);
  const [changeHistory, setChangeHistory] = useState([]);
  const [currentHistory, setCurrentHistory] = useState(null);
  const [minDate, setMinDate] = useState(dayjs(startDate));
  const [terminationModalOpen, setTerminationModalOpen] = useState(false);
  const [accountMapping, setAccountMapping] = useState([]);
  const [mappingSearch, setMappingSearch] = useState("");
  const [selectedMapping, setSelectedMapping] = useState(null);
  const [currentAccountMapping, setCurrentAccountMapping] = useState({});
  const [uncollapsedMapping, setUnCollapsedMapping] = useState([]);
  const [payrollColumns, setPayrollColumns] = useState([]);
  const [payrollRows, setPayrollRows] = useState([]);
  const [reload, setReload] = useState(false);
  const [sideNavDataTemp, setSideNavDataTemp] = useState(null);

  const { mappingData: accountingMapExpense } = useSelector(
    (state) => state?.expense
  );
  const { currentEntity } = useSelector((state) => state.clientSpaces);

  useEffect(() => {
    setReload(!reload);
    // eslint-disable-next-line
  }, [rows, columns, value]);

  const dispatch = useDispatch();
  const defaultSettings = {
    offset: 0,
    type: "month",
    start: dayjs().toISOString()?.slice(0, 7),
    end: dayjs().toISOString()?.slice(0, 7),
    currentMonthKey:
      dayjs().format("MMM").toLowerCase() + dayjs().year().toString(),
    startMonthKey:
      dayjs(startDate).format("MMM").toLowerCase() +
      dayjs(startDate).year().toString(),
  };

  useEffect(() => {
    handleModalClose();
    if (peopleData && refreshData) {
      setColumns(peopleData?.columns);
      if (peopleData?.payrollInformation) {
        setPayrollColumns(peopleData?.payrollInformation?.columns ?? []);
        setPayrollRows(peopleData?.payrollInformation?.rows ?? []);
      }
      setHeaderRow(peopleData?.headers);
      setValue(peopleData?.rows);
      peopleData?.changeHistory && setChangeHistory(peopleData?.changeHistory);
      setRows(getRows(peopleData?.rows, peopleData?.headers));
      if (sideNavData) {
        setSideNavData(
          peopleData?.rows.find((row) => row.id === sideNavData?.id)
        );
      }
      setTabSwitch(false);
    }
  }, [peopleData, showTerminated]);

  //calculations are made for each row looking into their metric name
  const updatePayrollInformation = (rows, peopleData, value) => {
    const clone = peoplePayrollCalculation(
      rows ?? payrollRows,
      currentEntity,
      durationEnd,
      value,
      peopleData
    );
    return clone;
  };

  useEffect(() => {
    setSideNavOpen(addEmployeeOpen);
    if (addEmployeeOpen) {
      let mappingData = currentEntity?.employees?.defaultPayrollMappingType;
      setSideNavData({
        taxFreeThresholdApplied: true,
        stslApplied: false,
        mapping: {
          name: mappingData?.text || "",
          value: mappingData?.id || null,
          type: mappingData?.type || "",
        },
      });
      handleMappingSelect(mappingData?.id || null);
    }
  }, [addEmployeeOpen]);

  //handle minimum date for use in change history where if for example, a pay rise has been given in month of June then the min Date will be July
  useEffect(() => {
    if (sideNavData) {
      const maxDate = (changeHistory || [])
        .filter(
          (history) =>
            history?.type === openModalId && history?.type !== "bonus"
        )
        .map((history) => dayjs(history?.date))
        .reduce(
          (maxDate, historyDate) =>
            maxDate
              ? historyDate.isAfter(maxDate)
                ? historyDate
                : maxDate
              : historyDate,
          null
        );
      const initialDate = dayjs(sideNavData?.startDate);
      const minDate =
        maxDate?.isValid() && openModalId !== "bonus"
          ? maxDate.add(1, "month")
          : initialDate;
      setMinDate(minDate);
    }
  }, [sideNavData, openModalId]);

  useEffect(() => {
    if (!sideNavOpen) {
      setSideNavData(null);
      setIsHourlyPaymentType(true);
      setModalValues(defaultModalValues);
    }
  }, [sideNavOpen]);

  // to set the info value that is shown in the pay rise modal
  useEffect(() => {
    if (sideNavFormulaData?.payRise) {
      const { payRise } = sideNavFormulaData;
      const {
        value,
        changeVariant = "Increase",
        changeType = "amount",
      } = payRise;

      let changeInfoValue = 0;

      if (value > 0) {
        const factor = changeVariant === "Increase" ? 1 : -1;
        const annualSalary = parseFloat(
          sideNavData?.annualSalary ?? sideNavData?.initialValue?.annualSalary
        );
        changeInfoValue =
          changeType === "amount"
            ? annualSalary + value * factor
            : annualSalary + (value / 100) * annualSalary * factor;
      }
      setChangeInfoValue(parseFloat(changeInfoValue).toFixed(2));
    }
  }, [sideNavFormulaData?.payRise]);

  useEffect(() => {
    const { bonus } = sideNavFormulaData || {};
    const {
      value,
      changeType = "amount",
      date = dayjs(sideNavFormulaData?.bonus?.date || sideNavData?.startDate),
    } = bonus || {};
    let changeInfoValue = 0;
    let formattedDate =
      dayjs(date).format("MMM").toLowerCase() + dayjs(date).year().toString();
    if (bonus && value > 0) {
      changeInfoValue =
        changeType === "percent"
          ? (value / 100) *
            parseFloat(
              sideNavData?.[formattedDate].value -
                (sideNavData?.[formattedDate]?.bonus || 0)
            )
          : value;
    }
    setChangeInfoValue(parseFloat(changeInfoValue).toFixed(2));
  }, [sideNavFormulaData?.bonus?.value]);

  useEffect(() => {
    if (!sideNavData) return;
    const { value } = sideNavFormulaData?.weeklyHours || {};
    setChangeInfoValue(value);
  }, [sideNavFormulaData?.weeklyHours]);

  useEffect(() => {
    if (!sideNavData) return;
    const { value } = sideNavFormulaData?.hourlyRate || {};
    setChangeInfoValue(value);
  }, [sideNavFormulaData?.hourlyRate]);

  useEffect(() => {
    if (accountingMapExpense) {
      const value = [
        ...new Set(
          accountingMapExpense.flatMap((data) =>
            data.children.flatMap((child) =>
              child.type === "Account Group"
                ? [
                    child,
                    ...child.children.map((subChild) => ({
                      ...subChild,
                      parent: child.id,
                    })),
                  ]
                : child
            )
          )
        ),
      ];

      const uniqueSortedValue = value.reduce(
        (acc, curr) =>
          acc.some((item) => item.text === curr.text) ? acc : [...acc, curr],
        []
      );

      setAccountMapping(uniqueSortedValue);
    }
  }, [accountingMapExpense]);

  //functions to modify change history data
  function addChange(date, oldValue, value, changeType, changeVariant, type) {
    const order =
      sideNavData?.changeHistory?.length > 0
        ? sideNavData?.changeHistory?.length + 1
        : 1;
    let id = new ObjectID().toString();
    const updatedHistory = [
      ...(changeHistory || []),
      { date, oldValue, value, changeType, changeVariant, order, type, id },
    ];
    setChangeHistory(updatedHistory);
    return updatedHistory;
  }
  function editChange(currentHistory) {
    const {
      order,
      date,
      oldValue,
      value,
      changeType,
      changeVariant,
      type,
      id,
    } = currentHistory;
    if (
      changeHistory?.find(
        (val) =>
          val.date === date &&
          val.type === type &&
          type !== "bonus" &&
          id !== val.id
      )
    ) {
      setModalValues({
        ...modalValues,
        message:
          "There is already a change for the given month please select other date. ",
        modalType: "existingChange",
        singleButton: true,
        confirmText: "Ok",
      });
      setModalOpen(true);
      return;
    }
    const index = changeHistory?.findIndex((change) => change.order === order);
    if (index >= 0) {
      const defaultValue = parseFloat(value);
      const formattedValue = defaultValue;
      const updatedChange = {
        order,
        date,
        oldValue,
        value: formattedValue,
        changeType,
        changeVariant,
        type,
      };
      const updatedHistory = [
        ...changeHistory.slice(0, index),
        updatedChange,
        ...changeHistory.slice(index + 1),
      ];
      setChangeHistory(updatedHistory);
      handleCalculateMonthlySalary(updatedHistory, true, sideNavData, true);
    }
  }
  function deleteChange(order) {
    const index = changeHistory.findIndex((change) => change.order === order);
    if (index >= 0) {
      const deletedChanges = changeHistory.splice(index, 1);
      updateOrder(index, deletedChanges[0]);
    }
  }
  function updateOrder(startIndex, deletedChange) {
    var prevOldValue = 0;
    var currentOldValue = 0;
    for (let i = startIndex; i < changeHistory.length; i++) {
      if (i === startIndex) {
        prevOldValue = changeHistory[i].oldValue;
        changeHistory[i].oldValue = deletedChange.oldValue;
      } else {
        currentOldValue = changeHistory[i].oldValue;
        changeHistory[i].oldValue = prevOldValue;
        prevOldValue = currentOldValue;
      }
      changeHistory[i].order = i + 1;
    }
    setChangeHistory(changeHistory);
    handleCalculateMonthlySalary(changeHistory, true, sideNavData, true);
  }

  const getRows = (value, headerRow) => {
    let index = 0;
    return [
      headerRow,
      ...value.map((data, idx) => {
        if (
          showTerminated
            ? !data?.terminationDate ||
              dayjs(data?.terminationDate).isAfter(dayjs())
            : data?.terminationDate &&
              dayjs(data?.terminationDate).isBefore(dayjs())
        ) {
          return null;
        }

        index++;
        let rowData = {
          rowId: idx,
          height: 40,
          dataId: value.find((row) => row.id === data.id).id,
          cells: [
            {
              type: "text",
              text: `${index}`,
              nonEditable: true,
              style: { paddingLeft: "15px" },
            },
            {
              type: "action",
              disabled: showTerminated,
              toggleSideNav: () => {
                handleSideNavOpen(data.id);
              },
            },
            {
              type: "text",
              text: `${data.employeeName}`,
              nonEditable: true,
            },
          ],
        };
        if (peopleData?.metricSettingsOpen) {
          let metricSetting = [
            {
              type: "number",
              value: parseFloat(
                data?.weeklyHours ?? data?.initialValue?.weeklyHours
              ),
              nonEditable: true,
            },
            {
              type: "number",
              value: data.fte,
              nonEditable: true,
            },
            {
              type: "text",
              nonEditable: true,
              text: `${
                data.startDate == null
                  ? dayjs(defaultSettings?.start).format("MMM YYYY")
                  : dayjs(data?.startDate).format("MMM YYYY")
              }`,
            },
            {
              type: "text",
              nonEditable: true,
              text: `${
                data?.endDate ? dayjs(data?.endDate).format("MMM YYYY") : "-"
              }`,
            },
            {
              type: "number",
              value:
                data?.annualSalaries?.[defaultSettings.currentMonthKey] ||
                data.initialValue.annualSalary,
              nonEditable: true,
            },
            {
              type: "text",
              text: `${data?.mapping?.name ?? "Not Defined"}`,
              nonEditable: true,
            },
          ];

          metricSetting.forEach((val) => {
            rowData.cells.push(val);
          });
        }
        let dateColumns = peopleData?.columns?.reduce((acc, column) => {
          if (!DEFAULT_KEYS.includes(column.columnId)) {
            acc.push(column?.columnId);
          }
          return acc;
        }, []);
        dateColumns.forEach((date) => {
          if (!data[date]?.disabled) {
            rowData.cells.push({
              type: "number",
              value: Math.round(data[date]?.value || 0),
              nonEditable: true,
              style: {
                background: data[date]?.manualInput ? "#B4D4FF" : "white",
              },
            });
          }
        });
        return rowData;
      }),
    ].filter((row) => row !== null);
  };

  const handleModalOpen = (id, initialize = true) => {
    setChangeInfoValue(0);
    setOpenModalId(id === openModalId ? null : id);
    if (initialize) setSideNavFormulaData({ [id]: { value: 0 } });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleCloseSideNav = () => {
    setSideNavFormulaData(null);
    setSideNavData(null);
    setIsHourlyPaymentType(true);
    setHasError(false);
    setSideNavOpen(false);
    setCurrentAccountMapping({});
    setMappingSearch("");
    setSelectedMapping(null);
    setAddEmployeeOpen(false);
    setCurrentSideNavTab(0);
    setChangeInfoValue(null);
    setModalValues(defaultModalValues);
    setModalOpen(false);
    setChangeHistory([]);
    setCurrentHistory(null);
  };

  const handleSideNavOpen = (id) => {
    let row = _.cloneDeep(value.find((val) => val.id === id));
    setIsHourlyPaymentType(row?.paymentType === "hourly");
    let mappingId = row?.mapping?.value;
    handleMappingSelect(mappingId);
    setSideNavData(row);
    setChangeHistory(row?.changeHistory);
    setSideNavOpen(true);
  };

  const handleSideNavChanges = (key, value) => {
    let updatedData = _.cloneDeep(sideNavData ?? {});
    if (key === "paymentType") {
      updatedData["weeklyHours"] = 0;
      updatedData["hourlyRate"] = 0;
      updatedData["annualSalary"] = 0;
    }
    if (["weeklyHours", "hourlyRate", "annualSalary"].includes(key)) {
      if (!updatedData["initialValue"]) {
        updatedData["initialValue"] = {};
      }
      updatedData["initialValue"][key] = value;
    } else {
      updatedData[key] = value;
    }
    setSideNavData(updatedData);
  };
  const handleSideNavTempChanges = (key, value) => {
    let updatedData = _.cloneDeep(sideNavData ?? {});
    updatedData[key] = value;
    setSideNavDataTemp(updatedData);
  };

  const handleChangeCurrentSideNavTab = (e, value) => {
    setCurrentSideNavTab(value);
  };

  //side nav is being saved in two scenarios: one when the employee is added and again when the values of that employee is updated later on
  const handleSideNavSave = (sideNavDataForSave, closeSideNav = true) => {
    if (addEmployeeOpen) {
      let betweenMonths = [];

      const sideNav = sideNavDataForSave ?? sideNavData;
      if (
        startDate <= (sideNav?.terminationDate ? sideNav?.endDate : endDate)
      ) {
        var date = startDate.startOf("month");
        while (
          date <=
          moment(sideNav?.terminationDate ? sideNav?.endDate : endDate).startOf(
            "month"
          )
        ) {
          betweenMonths.push(moment(date.format("YYYY-MM")));
          date.add(1, "month");
        }
      }
      let data = betweenMonths.map((date) => {
        return { year: date.year(), month: monthName[date.month() + 1] };
      });

      let annualSalary = isHourlyPaymentType
        ? (sideNav?.weeklyHours || sideNav?.initialValue?.weeklyHours) *
          (sideNav.hourlyRate || sideNav?.initialValue?.hourlyRate) *
          52
        : sideNav.annualSalary || sideNav?.initialValue?.annualSalary;
      let annualSalaries = {};
      data?.forEach((date) => {
        let key = date.month.toLowerCase() + date.year;
        annualSalaries[key] = annualSalary;
      });
      let rowData = {
        employeeName: sideNav.employeeName,
        fte:
          parseFloat(
            sideNav.weeklyHours ?? sideNav?.initialValue?.weeklyHours
          ) / currentEntity?.employees?.standardWeeklyHours,
        startDate: sideNav.startDate || dayjs().toISOString()?.slice(0, 7),
        endDate: sideNav.endDate || null,
        paymentType: sideNav?.paymentType || "hourly",
        taxFreeThresholdApplied: sideNav?.taxFreeThresholdApplied || true,
        stslApplied: sideNav?.stslApplied || false,
        annualSalary: annualSalary,
        annualSalaries,
        mapping: sideNav?.mapping,
        initialValue: {
          weeklyHours: parseFloat(
            sideNav.weeklyHours || sideNav?.initialValue?.weeklyHours
          ),
          annualSalary: annualSalary,
          hourlyRate: isHourlyPaymentType
            ? parseFloat(
                sideNav?.hourlyRate || sideNav?.initialValue?.hourlyRate
              )
            : undefined,
        },
        id: addEmployeeOpen ? new ObjectID().toString() : sideNav.id,
        disabled: false,
      };
      if (data.length > 0) {
        let clone = _.cloneDeep(rowData);
        let payrollTiming = currentEntity?.employees?.payrollTiming;

        data.forEach((date) => {
          let key = date.month.toLowerCase() + date.year;
          let cashValueData =
            (annualSalary /
              (payrollTiming === "Weekly"
                ? 52
                : payrollTiming === "Monthly"
                ? 12
                : 26)) *
            (payrollTiming === "Monthly" ? 1 : payrollRows[2][key]);
          let valueData = paymentType ? annualSalary / 12 : cashValueData;
          clone[date] = {
            value: dayjs(key).isBefore(rowData?.startDate) ? 0 : valueData,
            cashValue: dayjs(key).isBefore(rowData?.startDate)
              ? 0
              : cashValueData,
            disabled: false,
            manualInput: false,
          };
          rowData[key] = clone[date];
        });
      }
      setValue([...value, rowData]);

      if (refreshData) {
        dispatch(setRefreshing());
      }
      if (!changeHistory && !addEmployeeOpen) {
        const index = value.findIndex((element) => element.id === sideNav.id);
        const updatedValue = [...value];
        updatedValue[index] = rowData;
        handleUpdate(updatedValue, true, true, payrollRows);
        setValue(updatedValue);
      } else {
        let payRollData = updatePayrollInformation(
          payrollRows,
          {
            rows: [...value, rowData],
          },
          [...value, rowData]
        );
        handleCloseSideNav();
        handleUpdate([...value, rowData], true, true, payRollData);
      }
    } else {
      handleCalculateMonthlySalary(
        changeHistory,
        true,
        sideNavDataForSave,
        true
      );
    }
  };

  const handleFormulaData = (key, value) => {
    setSideNavFormulaData((prevFormulaData) => ({
      ...prevFormulaData,
      [key]: {
        ...((prevFormulaData && prevFormulaData[key]) || {}),
        ...value,
      },
    }));
  };

  const handleUpdate = (
    rows,
    refreshData = false,
    notifyChange = false,
    payrollRows
  ) => {
    let { spreadsheet } = handleReferenceUpdate(
      {
        ...spreadSheet,
        people: {
          ...spreadSheet.people,
          payrollInformation: {
            ...spreadSheet.people.payrollInformation,
            rows: payrollRows,
          },
        },
      },
      rows,
      "people",
      durationData,
      dispatch,
      setSpreadsheetDataReference,
      currentEntity
    );
    setSpreadSheet(spreadsheet);
    if (refreshData) {
      dispatch(setRefreshing());
    }
    dispatch(
      updateSpreadsheet({
        payload: {
          value: {
            ...spreadsheet,
            people: {
              ...spreadsheet.people,
              paymentType: paymentType ? "Accrual" : "Cash",
              payrollInformation: {
                ...spreadsheet.people.payrollInformation,
                rows: payrollRows,
              },
            },
            activeTab: tabValue,
          },
          refreshData,
          entityId,
        },
        spreadSheetId,
        accountingFirmId,
        notifyChange,
      })
    );
  };

  //function to add the history from the side Nav
  const handleAddFormula = (type, overrideManual, check = true) => {
    if (
      checkManualInputs(
        sideNavData.startDate ?? minDate,
        dayjs(sideNavData?.endDate ?? endDate).format("YYYY-MM")
      ) &&
      check
    ) {
      setModalValues({
        ...modalValues,
        message:
          "There are currently values that have been manually input. Do you want to retain the manual input values?",
        modalType: type,
      });
      setModalOpen(true);
      return;
    }

    const date = dayjs(sideNavFormulaData?.[type]?.date || minDate);
    const nextMonthDate = dayjs(
      sideNavFormulaData?.[type]?.date || minDate
    ).add(1, "month");
    const formatDate =
      dayjs(date).format("MMM").toLowerCase() + dayjs(date).year().toString();
    const keys = Object.keys(sideNavData);
    const startIndex = keys.findIndex((key) => key === formatDate);
    const nextMonthIndex = keys.findIndex(
      (key) =>
        key ===
        dayjs(nextMonthDate).format("MMM").toLowerCase() +
          dayjs(nextMonthDate).year().toString()
    );
    const oldValue =
      type === "bonus"
        ? sideNavData[keys[startIndex]]?.bonus ?? 0
        : type === "payRise"
        ? sideNavData[keys[startIndex]].manualInput
          ? sideNavData[keys[nextMonthIndex]]?.value -
            (sideNavData[keys[nextMonthIndex]]?.bonus || 0)
          : sideNavData[keys[startIndex]]?.value -
              (sideNavData[keys[startIndex]]?.bonus || 0) ?? 0
        : type === "hourlyRate"
        ? sideNavData[keys[startIndex]]?.hourlyRate ?? 0
        : sideNavData[keys[startIndex]]?.weeklyHours ?? 0;
    const changeType = sideNavFormulaData?.[type]?.changeType || "amount";
    const changeVariant =
      sideNavFormulaData?.[type]?.changeVariant || "Increase";
    const defaultValue = parseFloat(sideNavFormulaData?.[type]?.value);
    const value = defaultValue;
    const updatedHistory = addChange(
      dayjs(formatDate).format("YYYY-MM"),
      oldValue,
      value,
      changeType,
      changeVariant,
      type
    );
    handleCalculateMonthlySalary(
      updatedHistory,
      overrideManual,
      sideNavData,
      true
    );
  };

  const handleCalculateMonthlySalary = (
    updatedHistory,
    overrideManual,
    modifiedSideNavData,
    refreshData = false
  ) => {
    let { updatedHistory: updatedHistoryValue, updatedSideNavData } =
      calculateMonthlySalary(
        updatedHistory,
        overrideManual,
        modifiedSideNavData,
        currentEntity,
        durationEnd,
        sideNavData,
        endDate,
        minDate,
        payrollRows,
        paymentType
      );
    let payrollRowsUpdated = _.cloneDeep(payrollRows);

    setChangeHistory(updatedHistoryValue);
    updatedSideNavData.changeHistory = updatedHistoryValue;

    const index = value.findIndex(
      (element) => element.id === updatedSideNavData.id
    );
    if (index !== -1) {
      const updatedValue = [...value];
      updatedValue[index] = updatedSideNavData;
      if (payrollRows.length > 0 && currentEntity?.employees?.includes) {
        payrollRowsUpdated = updatePayrollInformation(
          payrollRows,
          {
            rows: updatedValue,
          },
          updatedValue
        );
      }
      handleUpdate(updatedValue, refreshData, false, payrollRowsUpdated);
      setValue(updatedValue);
    }
  };

  const checkManualInputs = (start, end) => {
    let range = getTimingRange(
      dayjs(start).format("YYYY-MM"),
      dayjs(end).format("YYYY-MM")
    );
    let manualInputExists = false;
    range.forEach((date) => {
      if (sideNavData?.[date]?.["manualInput"]) {
        manualInputExists = true;
      }
    });
    return manualInputExists;
  };

  const handleModalClose = () => {
    setChangeInfoValue(0);
    setSideNavFormulaData(null);
    setModalError(false);
    setMinDate(dayjs(sideNavData?.startDate));
    setCurrentHistory(null);
    setOpenModalId(null);
    setModalValues(defaultModalValues);
  };

  const handleDeleteHistory = (order) => {
    setModalValues({
      ...modalValues,
      message:
        "Are you sure you want to delete this change? This will revert back the values affected by this change.",
      modalType: "delete",
      idValue: order,
    });
    setModalOpen(true);
  };

  const handleChangeStartDate = (date) => {
    let range = getTimingRange(
      sideNavData?.startDate,
      dayjs(durationEnd).format("YYYY-MM")
    );
    let fullRange = getTimingRange(date, dayjs(durationEnd).format("YYYY-MM"));
    let historyData;
    while (true) {
      historyData = changeHistory ? _.cloneDeep(changeHistory) : [];
      let outOfRangeOrders = [];
      historyData.forEach((history) => {
        history.date =
          MONTH_MAP[parseInt(history?.date.split("-")[1])] +
          history?.date.split("-")[0];
      });
      for (const entry of historyData) {
        if (!range.includes(entry.date)) {
          outOfRangeOrders.push(entry.order);
        }
      }
      if (outOfRangeOrders.length === 0) {
        break;
      }
      deleteChange(outOfRangeOrders.shift());
    }

    const outOfRangeDates = fullRange.filter((value) => !range.includes(value));

    const updatedSideNavData = _.cloneDeep(sideNavData);
    for (const key in updatedSideNavData) {
      if (outOfRangeDates.includes(key)) {
        updatedSideNavData[key].value = 0;
        updatedSideNavData[key].bonus = 0;
        updatedSideNavData[key].manualInput = false;
      }
    }
    setSideNavData(updatedSideNavData);
    handleCalculateMonthlySalary(changeHistory, true, updatedSideNavData);
  };

  const handleEmployeeTermination = () => {
    const updatedSideNavData = _.cloneDeep(sideNavData);
    if (sideNavData?.terminationDate) {
      updatedSideNavData.terminationDate = null;
    } else {
      const terminationDate = dayjs(
        sideNavDataTemp?.terminationDate ?? sideNavData?.endDate ?? durationEnd
      ).format("YYYY-MM");

      const calculationDate = dayjs(terminationDate).format("YYYY-MM");

      let range = getTimingRange(durationStart, calculationDate);
      let fullRange = getTimingRange(
        durationStart,
        dayjs(durationEnd).format("YYYY-MM")
      );

      const outOfRangeDates = fullRange.filter(
        (value) => !range.includes(value)
      );

      updatedSideNavData.terminationDate = terminationDate;
      updatedSideNavData.endDate = terminationDate;
      for (const key in updatedSideNavData) {
        if (outOfRangeDates.includes(key)) {
          updatedSideNavData[key].value = 0;
          updatedSideNavData[key].bonus = 0;
        }
      }
    }

    setSideNavData(updatedSideNavData);
    handleSideNavSave(updatedSideNavData, true);
    const toastId = !sideNavData?.terminationDate
      ? `employeeTermination`
      : "employeeTerminationReverted";
    if (!toast.isActive(toastId)) {
      !sideNavData?.terminationDate
        ? toast.error(
            `${sideNavData?.employeeName}
              ${
                sideNavData?.terminationDate &&
                dayjs(sideNavData?.terminationDate).isBefore(dayjs())
                  ? " has been "
                  : " will be "
              }
              terminated from${" "}
              ${dayjs(updatedSideNavData?.terminationDate).format(
                "MMMM, YYYY"
              )} `,
            {
              toastId: toastId,
              autoClose: 3000,
            }
          )
        : toast.success(
            `${sideNavData?.employeeName}'s termination has been revereted`,
            {
              toastId: toastId,
              autoClose: 3000,
            }
          );
    }
  };

  const handleCellChange = (val) => {
    let row = rows.find((row) => row.rowId === val[0].rowId);
    let clone = _.cloneDeep(value);
    let index = value.map((value) => value.id).indexOf(row.dataId);
    clone[index][val[0].columnId].value = val[0].newCell.value;
    clone[index][val[0].columnId].manualInput = true;
    setValue(clone);
    setRows(getRows(clone, headerRow));
    handleUpdate(clone, false, false, payrollRows);
  };

  const handleConfirmModalSubmit = (type, methodSubmitType, idValue) => {
    let shouldOverride = methodSubmitType === "confirm" ? false : true;
    switch (type) {
      case "payRise":
      case "bonus":
      case "weeklyHours":
      case "hourlyRate":
        return handleAddFormula(type, shouldOverride, false);
      default:
        break;
    }
  };

  const handleMappingSelect = (id) => {
    setCurrentAccountMapping({ ...currentAccountMapping, id });
  };

  const handleCollapsibleMapping = (id) => {
    let index = uncollapsedMapping.indexOf(id);
    if (index >= 0) {
      let clone = _.cloneDeep(uncollapsedMapping);
      clone.splice(index, 1);
      setUnCollapsedMapping(clone);
    } else {
      setUnCollapsedMapping([...uncollapsedMapping, id]);
    }
  };

  const getAccountMappingContent = () => {
    return (
      <Grid container className="p-10">
        {hasError && !sideNavData?.mapping && (
          <Typography mb={2} color={"error"}>
            Please select a mapping
          </Typography>
        )}
        <Grid item xs={12}>
          <TextField
            onChange={({ target }) => {
              setMappingSearch(target.value);
            }}
            value={mappingSearch}
            fullWidth
            size="small"
            InputProps={{
              endAdornment: (
                <>
                  {mappingSearch !== "" && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setMappingSearch("")}>
                        <CloseIcon></CloseIcon>
                      </IconButton>
                    </InputAdornment>
                  )}
                  {mappingSearch === "" && <></>}
                  <Search />
                </>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className="mt-20"
          style={{ height: "65vh", overflow: "auto" }}
        >
          <RadioGroup className="ml-10">
            {accountMapping
              .filter((mapping) => {
                let regex = new RegExp(
                  `(${_.escapeRegExp(mappingSearch)})`,
                  "gi"
                );
                return regex.test(mapping.text);
              })
              .map((mapping) => {
                if (!mapping.parent) {
                  return (
                    <div style={{ display: "flex" }} key={mapping.id}>
                      <FormControlLabel
                        className={mapping?.parent ? "pl-20" : ""}
                        checked={currentAccountMapping?.id === mapping.id}
                        value={mapping.id}
                        control={<Radio />}
                        label={
                          <span style={{ fontWeight: "bold" }}>
                            {mapping.text}
                          </span>
                        }
                        onClick={({ target }) => {
                          if (selectedMapping === target.value) {
                            setSelectedMapping(null);
                          } else {
                            handleSideNavChanges("mapping", {
                              name: mapping.text,
                              value: mapping.id,
                              type: mapping.type,
                            });
                            handleMappingSelect(target.value);
                          }
                        }}
                      />
                      {accountMapping.filter(
                        (child) => child.parent === mapping.id
                      ).length !== 0 && (
                        <IconButton
                          style={{ marginLeft: "auto" }}
                          onClick={() => {
                            handleCollapsibleMapping(mapping.id);
                          }}
                        >
                          {!uncollapsedMapping.includes(mapping.id) && (
                            <KeyboardArrowDown />
                          )}
                          {uncollapsedMapping.includes(mapping.id) && (
                            <KeyboardArrowUp />
                          )}
                        </IconButton>
                      )}
                    </div>
                  );
                } else {
                  return uncollapsedMapping.includes(mapping.parent) ||
                    mappingSearch !== "" ? (
                    <FormControlLabel
                      key={mapping.id}
                      className={mappingSearch === "" ? "pl-20" : ""}
                      checked={currentAccountMapping?.id === mapping.id}
                      value={mapping.id}
                      control={<Radio />}
                      label={mapping.text}
                      onClick={({ target }) => {
                        if (selectedMapping === target.value) {
                          setSelectedMapping(null);
                        } else {
                          if (addEmployeeOpen) {
                            handleSideNavChanges("mapping", {
                              name: mapping.text,
                              value: mapping.id,
                              type: mapping.type,
                            });
                          } else {
                          }
                          handleMappingSelect(target.value);
                        }
                      }}
                    />
                  ) : (
                    <Fragment key={mapping.id}></Fragment>
                  );
                }
              })}
          </RadioGroup>
        </Grid>
      </Grid>
    );
  };

  const handleDeleteEmployee = () => {
    let valueClone = _.cloneDeep(value);
    let index = value.findIndex((val) => val.id === sideNavData.id);
    valueClone.splice(index, 1);
    let payRollData = updatePayrollInformation(
      payrollRows,
      {
        rows: valueClone,
      },
      valueClone
    );
    handleUpdate(valueClone, true, false, payRollData);
    handleCloseSideNav();
  };

  const getMetricSettingContent = () => {
    return (
      <Grid item xs={12} className="p-10">
        <Grid container mb={4}>
          <Grid item xs={12}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item xs={4}>
                <Button
                  onClick={() => {
                    setModalValues({
                      ...modalValues,
                      message: `Are you sure you want to delete the user? 
                              This change is irreversible!`,
                      modalType: "deleteEmployee",
                      idValue: sideNavData.id,
                    });
                    setModalOpen(true);
                  }}
                  variant="outlined"
                  color="error"
                >
                  {" "}
                  Delete Employee
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            mb={1}
            display={sideNavData?.terminationDate ? "block" : "none"}
          >
            <Typography color="error">
              The employee
              {sideNavData?.terminationDate &&
              dayjs(sideNavData?.terminationDate).isBefore(dayjs())
                ? " has been "
                : " will be "}
              terminated from{" "}
              {dayjs(sideNavData?.terminationDate).format("MMMM, YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className="font-label">Employee Name</InputLabel>
            <TextField
              disabled={!!sideNavData?.terminationDate}
              fullWidth
              size="small"
              value={sideNavData?.employeeName}
              onChange={({ target }) => {
                const reg = /^[a-zA-Z0-9 -]+$/;
                if (reg.test(target.value) || target.value === "") {
                  handleSideNavChanges("employeeName", target.value);
                }
              }}
              error={
                hasError &&
                (!sideNavData?.employeeName ||
                  !/^[a-zA-Z0-9 -]+$/.test(sideNavData?.employeeName) ||
                  sideNavData?.employeeName?.length < 3)
              }
              helperText={
                (hasError &&
                  (!sideNavData?.employeeName ||
                    !/^[a-zA-Z0-9 -]+$/.test(sideNavData?.employeeName))) ||
                sideNavData?.employeeName?.length < 3
                  ? sideNavData?.employeeName?.length < 3
                    ? "Name length must be greater than 3 characters"
                    : "Employee Name is required"
                  : ""
              }
            ></TextField>
            {addEmployeeOpen && (
              <Grid container mt={3}>
                <Typography>Hourly Rate Basis</Typography>
                <CustomSwitch
                  check={!isHourlyPaymentType}
                  handleCheck={() => {
                    setIsHourlyPaymentType(!isHourlyPaymentType);
                    setHasError(false);
                    handleSideNavChanges(
                      "paymentType",
                      isHourlyPaymentType ? "salary" : "hourly"
                    );
                  }}
                />
                <Typography>Salary Basis</Typography>
              </Grid>
            )}

            <Grid item xs={12} className="mt-25">
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">PAYG settings</Typography>
                </Grid>
                <Grid item xs={12} className="mt-10">
                  <Grid container>
                    <Typography>Tax Free Threshold Applied</Typography>
                    <AntSwitch
                      checked={
                        sideNavData?.taxFreeThresholdApplied ? false : true
                      }
                      onChange={() =>
                        handleSideNavChanges(
                          "taxFreeThresholdApplied",
                          !sideNavData?.taxFreeThresholdApplied
                        )
                      }
                    />
                    <Typography>Not Applied</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="mt-20">
                  <Grid container>
                    <Typography>STSL Applied</Typography>
                    <AntSwitch
                      checked={sideNavData?.stslApplied ? false : true}
                      onChange={() =>
                        handleSideNavChanges(
                          "stslApplied",
                          !sideNavData?.stslApplied
                        )
                      }
                    />
                    <Typography>Not Applied</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} className="mt-20">
              <Grid container spacing={4}>
                <Grid item xs={isHourlyPaymentType ? 6 : 12}>
                  <InputLabel sx={{ mt: 2 }} className="font-label mb-10">
                    Initial Weekly Hours (hrs)
                  </InputLabel>
                  <TextField
                    fullWidth
                    disabled={changeHistory && changeHistory.length > 0}
                    size="small"
                    onFocus={(e) => e.target.select()}
                    defaultValue={sideNavData?.initialValue?.weeklyHours}
                    value={parseFloat(
                      sideNavData?.initialValue?.weeklyHours
                    ).toString()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">hrs</InputAdornment>
                      ),
                      type: "number",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    error={
                      addEmployeeOpen &&
                      hasError &&
                      !sideNavData?.initialValue?.weeklyHours
                    }
                    helperText={
                      addEmployeeOpen &&
                      hasError &&
                      !sideNavData?.initialValue?.weeklyHours
                        ? "Weekly Hours is required"
                        : ""
                    }
                    onChange={(e) => {
                      if (e.target.value <= 168) {
                        handleSideNavChanges("weeklyHours", e.target.value);
                      }
                    }}
                  ></TextField>
                </Grid>
                <Grid
                  display={isHourlyPaymentType ? "block" : "none"}
                  item
                  xs={6}
                >
                  <InputLabel sx={{ mt: 2 }} className="font-label mb-10">
                    Initial Hourly Rate ($/hr)
                  </InputLabel>
                  <TextField
                    fullWidth
                    disabled={changeHistory && changeHistory.length > 0}
                    size="small"
                    onFocus={(e) => e.target.select()}
                    defaultValue={sideNavData?.initialValue?.hourlyRate}
                    value={parseFloat(
                      sideNavData?.initialValue?.hourlyRate
                    ).toString()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">$/hr</InputAdornment>
                      ),
                      type: "number",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    error={
                      addEmployeeOpen &&
                      hasError &&
                      !sideNavData?.initialValue?.hourlyRate
                    }
                    helperText={
                      addEmployeeOpen &&
                      hasError &&
                      !sideNavData?.initialValue?.hourlyRate
                        ? "Hourly Rate is required"
                        : ""
                    }
                    onChange={(e) => {
                      if (e.target.value <= 99999) {
                        handleSideNavChanges("hourlyRate", e.target.value);
                      }
                    }}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid item display={isHourlyPaymentType ? "none" : "block"}>
              <InputLabel sx={{ mt: 2 }} className="font-label mb-10">
                Initial Annual Salary
              </InputLabel>
              <TextField
                fullWidth
                disabled={
                  !addEmployeeOpen && changeHistory && changeHistory.length > 0
                }
                onFocus={(e) => e.target.select()}
                size="small"
                defaultValue={sideNavData?.initialValue?.annualSalary}
                value={parseFloat(
                  sideNavData?.initialValue?.annualSalary
                ).toString()}
                onChange={(e) => {
                  if (e.target.value <= 999999999) {
                    handleSideNavChanges("annualSalary", e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">AUD$</InputAdornment>
                  ),
                  type: "number",
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={hasError && !sideNavData?.initialValue?.annualSalary}
                helperText={
                  hasError && !sideNavData?.initialValue?.annualSalary
                    ? "Annual Salary is required"
                    : ""
                }
              ></TextField>
            </Grid>
            {!addEmployeeOpen && (
              <InfoCard
                text={
                  sideNavData?.paymentType === "salary"
                    ? `The latest salary of the employee is <strong>$${
                        parseFloat(
                          sideNavData?.annualSalary
                        ).toLocaleString() ??
                        parseFloat(
                          sideNavData?.initialValue?.annualSalary
                        )?.toLocaleString()
                      }</strong> per annum. `
                    : `The latest hourly rate of the employee is <strong>$${
                        sideNavData?.hourlyRate ??
                        sideNavData?.initialValue?.hourlyRate
                      }/hr</strong> and the annual salary is <strong>$${
                        parseFloat(
                          sideNavData?.annualSalary
                        ).toLocaleString() ??
                        parseFloat(
                          sideNavData?.initialValue?.annualSalary
                        ).toLocaleString()
                      }</strong> per annum.`
                }
              />
            )}
          </Grid>
          <Grid item xs={12} className="mt-20">
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <InputLabel className="font-label mb-10">Start Date</InputLabel>
                <DatePicker
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  disabled={!!sideNavData?.terminationDate}
                  format={"MMMM YYYY"}
                  minDate={dayjs(startDate)}
                  maxDate={dayjs(durationEnd)}
                  allowClear={false}
                  size="large"
                  value={dayjs(sideNavData?.startDate)}
                  picker="month"
                  onChange={(date, dateString) => {
                    if (!addEmployeeOpen) {
                      setModalValues({
                        ...modalValues,
                        message: `Are you sure you want to change the start date? 
                                  This change will remove all the change history before ${dateString} and revert monthly values to 0.`,
                        modalType: "changeStartDate",
                        idValue: sideNavData?.startDate,
                      });
                    }
                    setModalOpen(true);
                    handleSideNavChanges(
                      "startDate",
                      dayjs(dateString).format("YYYY-MM")
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel className="font-label mb-10">
                  End Date (Optional)
                </InputLabel>
                <DatePicker
                  minDate={dayjs(sideNavData?.startDate)}
                  maxDate={dayjs(durationEnd)}
                  disabled={!!sideNavData?.terminationDate}
                  style={{ width: "100%" }}
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  format={"MMMM YYYY"}
                  allowClear={true}
                  size="large"
                  value={
                    sideNavData?.endDate ? dayjs(sideNavData?.endDate) : null
                  }
                  status={
                    dayjs(sideNavData?.startDate) > dayjs(sideNavData?.endDate)
                      ? "error"
                      : ""
                  }
                  picker="month"
                  onChange={(date, dateString) => {
                    handleSideNavChanges(
                      "endDate",
                      date ? dayjs(dateString).format("YYYY-MM") : null
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!addEmployeeOpen && (
          <div>
            <Grid
              display={sideNavData?.paymentType === "salary" ? "block" : "none"}
              mb={2}
            >
              <CustomAccordion
                summary="Give Pay Rise"
                expanded={expanded === "payRise"}
                handleChange={handleChange("payRise")}
                openModal={() => handleModalOpen("payRise")}
                disabled={!!sideNavData?.terminationDate}
                hasData={
                  changeHistory &&
                  changeHistory.some((item) => item?.type === "payRise")
                }
              >
                <Divider />
                {changeHistory &&
                  changeHistory.some((item) => item?.type === "payRise") && (
                    <Grid
                      sx={{ backgroundColor: "#EDF4FE", p: 2, color: "white" }}
                    >
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Raise Details</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {changeHistory &&
                              changeHistory
                                .filter(
                                  (history) => history?.type === "payRise"
                                )
                                .map((history, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <strong>
                                        {" "}
                                        {`${dayjs(history?.date).format(
                                          "YYYY MMMM"
                                        )}`}{" "}
                                      </strong>{" "}
                                      {`- Salary ${history?.changeVariant}d by `}
                                      <strong>{`${
                                        history?.changeType === "amount"
                                          ? (
                                              (history.value /
                                                (history.oldValue * 12)) *
                                              100
                                            ).toFixed(2)
                                          : history?.value
                                      }%`}</strong>
                                      {` (from `}
                                      <strong>{`${
                                        history.oldValue * 12
                                      }`}</strong>
                                      {` to `}
                                      <strong>{`${
                                        history?.changeType === "amount"
                                          ? history.oldValue * 12 +
                                            history.value *
                                              (history?.changeVariant ===
                                              "Decrease"
                                                ? -1
                                                : 1)
                                          : history.oldValue * 12 +
                                            ((history.value *
                                              (history?.changeVariant ===
                                              "Decrease"
                                                ? -1
                                                : 1)) /
                                              100) *
                                              history.oldValue *
                                              12
                                      }`}</strong>
                                      {`)`}
                                    </TableCell>
                                    <TableCell>
                                      <Grid
                                        display={"flex"}
                                        flexDirection={"row"}
                                      >
                                        <IconButton
                                          color="primary"
                                          size="small"
                                          disabled={
                                            !!sideNavData?.terminationDate
                                          }
                                          onClick={() => {
                                            setCurrentHistory(history);
                                            setSideNavFormulaData({
                                              payRise: { ...history },
                                            });
                                            handleModalOpen("payRise", false);
                                          }}
                                        >
                                          <Edit fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          color="error"
                                          size="small"
                                          disabled={
                                            !!sideNavData?.terminationDate
                                          }
                                          onClick={() =>
                                            handleDeleteHistory(history?.order)
                                          }
                                        >
                                          <Delete fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
              </CustomAccordion>
            </Grid>
            <CustomAccordion
              summary={"Give Bonus"}
              expanded={expanded === "bonus"}
              handleChange={handleChange("bonus")}
              disabled={!!sideNavData?.terminationDate}
              openModal={() => handleModalOpen("bonus")}
              hasData={
                changeHistory &&
                changeHistory.some((item) => item?.type === "bonus")
              }
            >
              <Divider />
              {changeHistory &&
                changeHistory.some((item) => item?.type === "bonus") && (
                  <Grid sx={{ p: 2, color: "white" }}>
                    <TableContainer style={{ maxHeight: 200 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow
                            sx={{
                              justifyContent: "space-between",
                            }}
                          >
                            <TableCell>Bonus Details</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {changeHistory &&
                            changeHistory
                              .filter((history) => history?.type === "bonus")
                              .map((history, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <Grid item xs={9}>
                                      <strong>
                                        {dayjs(history?.date).format(
                                          "YYYY MMM"
                                        )}
                                      </strong>{" "}
                                      - Bonus given by{" "}
                                      <strong>
                                        {history?.changeType === "amount"
                                          ? `$${history.value}`
                                          : `${history.value}%`}
                                      </strong>
                                    </Grid>
                                  </TableCell>
                                  <TableCell>
                                    <Grid
                                      container
                                      alignItems="flex-end"
                                      justifyContent="flex-end"
                                      flexDirection="row"
                                    >
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          !!sideNavData?.terminationDate
                                        }
                                        size="small"
                                        onClick={() => {
                                          setCurrentHistory(history);
                                          setSideNavFormulaData({
                                            bonus: { ...history },
                                          });
                                          handleModalOpen("bonus", false);
                                        }}
                                      >
                                        <Edit fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        disabled={
                                          !!sideNavData?.terminationDate
                                        }
                                        size="small"
                                        onClick={() =>
                                          handleDeleteHistory(history?.order)
                                        }
                                      >
                                        <Delete fontSize="small" />
                                      </IconButton>
                                    </Grid>
                                    {/* </Grid> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
            </CustomAccordion>
            <CustomAccordion
              summary={"Change Working Hours (weekly)"}
              expanded={expanded === "weeklyHours"}
              handleChange={handleChange("weeklyHours")}
              openModal={() => handleModalOpen("weeklyHours")}
              disabled={!!sideNavData?.terminationDate}
              hasData={
                changeHistory &&
                changeHistory.some((item) => item?.type === "weeklyHours")
              }
            >
              <Divider />
              {changeHistory &&
                changeHistory.some((item) => item?.type === "weeklyHours") && (
                  <Grid sx={{ p: 2, color: "white" }}>
                    <TableContainer style={{ maxHeight: 200 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Working Hour Details</TableCell>
                            <TableCell sx={{ textAlign: "right" }}>
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {changeHistory &&
                            changeHistory
                              .filter(
                                (history) => history?.type === "weeklyHours"
                              )
                              .map((history, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    <strong>
                                      {" "}
                                      {`${dayjs(history?.date).format(
                                        "YYYY MMM"
                                      )}`}{" "}
                                    </strong>
                                    {"- "}
                                    <strong>{`${history?.value} hrs `}</strong>
                                    {`per week`}
                                  </TableCell>
                                  <TableCell>
                                    <Grid
                                      container
                                      alignItems="flex-end"
                                      justifyContent="flex-end"
                                      flexDirection="row"
                                    >
                                      <IconButton
                                        color="primary"
                                        disabled={
                                          !!sideNavData?.terminationDate
                                        }
                                        size="small"
                                        onClick={() => {
                                          setCurrentHistory(history);
                                          setSideNavFormulaData({
                                            weeklyHours: { ...history },
                                          });
                                          handleModalOpen("weeklyHours", false);
                                        }}
                                      >
                                        <Edit fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        color="error"
                                        disabled={
                                          !!sideNavData?.terminationDate
                                        }
                                        size="small"
                                        onClick={() =>
                                          handleDeleteHistory(history?.order)
                                        }
                                      >
                                        <Delete fontSize="small" />
                                      </IconButton>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
            </CustomAccordion>
            {/* Change Hourly Rate */}
            <Grid
              mb={2}
              display={sideNavData?.paymentType === "salary" ? "none" : "block"}
            >
              <CustomAccordion
                summary={"Change Hourly Rate(AUD$/hr)"}
                expanded={expanded === "hourlyRate"}
                disabled={!!sideNavData?.terminationDate}
                handleChange={handleChange("hourlyRate")}
                openModal={() => handleModalOpen("hourlyRate")}
                hasData={
                  changeHistory &&
                  changeHistory.some((item) => item?.type === "hourlyRate")
                }
              >
                <Divider />
                {changeHistory &&
                  changeHistory.some((item) => item?.type === "hourlyRate") && (
                    <Grid sx={{ p: 2, color: "white" }}>
                      <TableContainer style={{ maxHeight: "200px" }}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell>Hourly Rate Details</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {changeHistory &&
                              changeHistory
                                .filter(
                                  (history) => history?.type === "hourlyRate"
                                )
                                .map((history, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <strong>
                                        {" "}
                                        {`${dayjs(history?.date).format(
                                          "YYYY MMM"
                                        )}`}
                                        {" - "}
                                      </strong>{" "}
                                      <strong>{`AUD$${history.value}`}</strong>
                                      {` per week`}
                                    </TableCell>
                                    <TableCell>
                                      <Grid
                                        container
                                        alignItems="flex-end"
                                        justifyContent="flex-end"
                                        flexDirection="row"
                                      >
                                        <IconButton
                                          disabled={
                                            !!sideNavData?.terminationDate
                                          }
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            setCurrentHistory(history);
                                            setSideNavFormulaData({
                                              hourlyRate: { ...history },
                                            });
                                            handleModalOpen(
                                              "hourlyRate",
                                              false
                                            );
                                          }}
                                        >
                                          <Edit fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          color="error"
                                          disabled={
                                            !!sideNavData?.terminationDate
                                          }
                                          size="small"
                                          onClick={() =>
                                            handleDeleteHistory(history?.order)
                                          }
                                        >
                                          <Delete fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  )}
              </CustomAccordion>
            </Grid>
          </div>
        )}
      </Grid>
    );
  };

  return (
    <div>
      <div
        className="spreadsheet-container mb-20 mt-10"
        style={{
          width: "fit-content",
          display:
            peopleData?.payrollInformation && currentEntity?.employees?.includes
              ? "block"
              : "none",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            style={{ fontSize: "18px" }}
          >
            Payroll Information
          </AccordionSummary>
          <div>
            <Divider />
            <Box p={3}>
              <TableContainer>
                <Table>
                  <TableHead>
                    {payrollColumns?.map((col, index) => {
                      return (
                        <TableCell
                          style={{
                            backgroundColor: "#0F72FD",
                            color: "#FFFFFF",
                            minWidth: col.width + "px",
                            borderRight: "1px solid #FFFFFF",
                            ...(index === 0
                              ? {
                                  position: "sticky",
                                  left: 0,
                                  zIndex: "9999 !important",
                                }
                              : {}),
                          }}
                          key={col?.field + index}
                        >
                          {col?.headerName}
                        </TableCell>
                      );
                    })}
                  </TableHead>
                  <TableBody>
                    {payrollRows?.map((row, index) => {
                      return (
                        <TableRow>
                          {Object.keys(row)
                            ?.filter((val) => val !== "id")
                            ?.map((key, rowIndex) => {
                              return (
                                <TableCell
                                  style={{
                                    minWidth:
                                      payrollColumns?.[rowIndex]?.["width"] +
                                      "px",
                                    ...(rowIndex === 0
                                      ? {
                                          position: "sticky",
                                          left: 0,
                                          zIndex: "9999 !important",
                                          background: "#FFFFFF",
                                          border: "0px solid #E0E0E0",
                                        }
                                      : {}),
                                  }}
                                  key={key + rowIndex + index}
                                >
                                  {[
                                    "Total Wage",
                                    "Superannuation Expense",
                                    "Superannuation Payment",
                                  ].includes(row.metric) && key !== "metric"
                                    ? parseFloat(row?.[key])?.toLocaleString()
                                    : row.metric === "Superannuation rate" &&
                                      key !== "metric"
                                    ? parseFloat(row?.[key]).toFixed(2)
                                    : row?.[key]}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* <StyledDataGrid
                rows={payrollRows}
                columns={payrollColumns}
                hideFooter={true}
                hidePagination={true}
                paginationMode={false}
              /> */}
            </Box>
          </div>
        </Accordion>
      </div>

      <ConfirmModal
        modalOpen={modalOpen}
        message={modalValues.message}
        confirmText={modalValues.confirmText}
        singleButton={modalValues.singleButton}
        declineText={modalValues.declineText}
        clearType={modalValues.clearType}
        setModalOpen={setModalOpen}
        confirmFunction={() => {
          if (modalValues?.modalType === "delete") {
            deleteChange(modalValues?.idValue);
          } else if (modalValues?.modalType === "changeStartDate") {
            handleChangeStartDate(modalValues?.idValue);
          } else if (modalValues?.modalType === "calculateMonthlySalary") {
            handleCalculateMonthlySalary(
              modalValues?.updatedHistory,
              false,
              modalValues?.modifiedSideNavData
            );
          } else if (modalValues?.modalType === "deleteEmployee") {
            handleDeleteEmployee();
          } else {
            handleConfirmModalSubmit(modalValues.modalType, "confirm");
          }
          setModalOpen(false);
          setModalValues(defaultModalValues);
        }}
        declineFunction={() => {
          if (modalValues?.modalType === "calculateMonthlySalary") {
            handleCalculateMonthlySalary(
              modalValues?.updatedHistory,
              true,
              modalValues?.modifiedSideNavData
            );
          } else if (
            modalValues?.modalType !== "delete" &&
            modalValues?.modalType !== "changeStartDate"
          ) {
            handleConfirmModalSubmit(
              modalValues.modalType,
              "decline",
              modalValues?.idValue
            );
          }
          setModalOpen(false);
          setModalValues(defaultModalValues);
        }}
      />
      {/* Pay Rise */}
      <CustomModal
        modalOpen={openModalId === "payRise"}
        setModalOpen={handleModalClose}
        onClose={handleModalClose}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Give Pay Rise
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>

        <Grid container p={2} mt={1}>
          <Grid item xs={3}>
            <InputLabel className="font-label mb-10">Start Date</InputLabel>
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              style={{ width: "100%" }}
              minDate={dayjs(
                currentHistory != null ? sideNavData?.startDate : minDate
              )}
              maxDate={dayjs(sideNavData?.endDate || durationEnd)}
              format={"MMMM YYYY"}
              allowClear={false}
              size="large"
              value={dayjs(sideNavFormulaData?.payRise?.date ?? minDate)}
              picker="month"
              onChange={(date, dateString) => {
                if (currentHistory != null) {
                  setCurrentHistory((prev) => ({
                    ...prev,
                    date: dayjs(dateString).format("YYYY-MM"),
                  }));
                }
                handleFormulaData("payRise", {
                  date: dayjs(dateString).format("YYYY-MM"),
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container mt={3}>
              <Typography>Increase by amount</Typography>
              <CustomSwitch
                check={
                  sideNavFormulaData?.payRise?.changeType === "percent"
                    ? true
                    : false
                }
                handleCheck={() => {
                  handleFormulaData("payRise", {
                    value: 0,
                    changeType:
                      sideNavFormulaData?.payRise?.changeType === "percent"
                        ? "amount"
                        : "percent",
                  });
                  if (currentHistory !== null) {
                    setCurrentHistory({
                      value: 0,
                    });
                  }
                  setChangeInfoValue(0);
                }}
              />
              <Typography>Increase by percentage</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <InputLabel sx={{ mt: 2 }} className="font-label">
              Increment Amount
            </InputLabel>
            <Grid item xs={12} display="flex" flexDirection="row" gap={2}>
              <TextField
                fullWidth
                size="small"
                onFocus={(e) => e.target.select()}
                value={parseFloat(
                  sideNavFormulaData?.payRise?.value
                ).toString()}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {(currentHistory != null
                        ? currentHistory?.changeType
                        : sideNavFormulaData?.payRise?.changeType) === "percent"
                        ? "%"
                        : "AUD$"}
                    </InputAdornment>
                  ),
                }}
                onChange={({ target }) => {
                  if (numericRegex.test(target.value) || target.value === "") {
                    if (
                      target.value <=
                      (sideNavFormulaData?.payRise?.changeType === "percent"
                        ? 100
                        : 999999999)
                    ) {
                      setModalError(false);
                      if (currentHistory != null) {
                        setCurrentHistory((prev) => ({
                          ...prev,
                          value: target.value,
                        }));
                      }
                      handleFormulaData("payRise", {
                        value: parseFloat(target.value).toString(),
                      });
                    }
                  }
                }}
                error={modalError}
                helperText={
                  modalError ? "Increment amount must be greater than 0" : ""
                }
              ></TextField>

              <TextField
                select
                size="small"
                value={sideNavFormulaData?.payRise?.changeVariant}
                defaultValue={
                  (currentHistory != null
                    ? currentHistory?.changeVariant
                    : sideNavFormulaData?.payRise?.changeVariant) || "Increase"
                }
                fullWidth
              >
                {["Increase", "Decrease"].map((mapping) => {
                  return (
                    <MenuItem
                      key={mapping}
                      value={mapping}
                      onClick={(e) => {
                        handleFormulaData("payRise", {
                          changeVariant: e.currentTarget.dataset.value,
                        });
                      }}
                    >
                      {mapping}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
          </Grid>
          {changeInfoValue > 0 && (
            <InfoCard
              text={`The salary of the employee will be
                      <strong>$${changeInfoValue}</strong> per annum`}
            />
          )}

          <Grid container mt={3} justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                handleFormulaData("payRise", {
                  date: dayjs(minDate),
                  value: 0,
                  changeVariant: "Increase",
                  changeType: "amount",
                });
                setCurrentHistory({
                  date: dayjs(minDate),
                  value: 0,
                  changeVariant: "Increase",
                  changeType: "amount",
                });
              }}
              variant="contained"
              color="inherit"
              className="ml-10"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                const checkError =
                  currentHistory != null
                    ? !numericRegex.test(currentHistory?.value) ||
                      parseFloat(currentHistory?.value) <= 0
                    : !numericRegex.test(sideNavFormulaData?.payRise?.value) ||
                      parseFloat(sideNavFormulaData?.payRise?.value) <= 0;
                if (checkError) {
                  setModalError(true);
                } else {
                  if (currentHistory != null) {
                    editChange(sideNavFormulaData?.payRise);
                  } else {
                    handleAddFormula("payRise", true, true);
                  }
                }
              }}
              variant="contained"
              color="primary"
              className="ml-10"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      {/* give bonus */}
      <CustomModal
        modalOpen={openModalId === "bonus"}
        setModalOpen={handleModalClose}
        onClose={handleModalClose}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Give Bonus
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>

        <Grid container p={2} mt={1}>
          <Grid item>
            <InputLabel className="font-label mb-10">Bonus Date</InputLabel>
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              style={{ width: "100%" }}
              minDate={dayjs(sideNavData?.startDate)}
              format={"MMMM YYYY"}
              maxDate={dayjs(sideNavData?.endDate || durationEnd)}
              allowClear={false}
              size="large"
              value={dayjs(
                sideNavFormulaData?.bonus?.date ?? sideNavData?.startDate
              )}
              picker="month"
              onChange={(date, dateString) => {
                handleFormulaData("bonus", {
                  date: dayjs(dateString).format("YYYY-MM"),
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container mt={3}>
              <Typography>Pay by amount</Typography>
              <CustomSwitch
                check={
                  sideNavFormulaData?.bonus?.changeType === "percent"
                    ? true
                    : false
                }
                handleCheck={() => {
                  handleFormulaData("bonus", {
                    value: 0,
                    changeType:
                      sideNavFormulaData?.bonus?.changeType === "percent"
                        ? "amount"
                        : "percent",
                  });
                  if (currentHistory !== null) {
                    setCurrentHistory({
                      value: 0,
                    });
                  }

                  setChangeInfoValue(0);
                }}
              />
              <Typography>Pay by % of selected month</Typography>
            </Grid>
          </Grid>
          <Grid pt={2}>
            <InputLabel className="font-label mb-10">
              Bonus
              {sideNavFormulaData?.bonus?.changeType === "percent"
                ? "Percent"
                : "Amount"}
            </InputLabel>
            <Grid alignItems={"center"}>
              <TextField
                fullWidth
                size="small"
                type="number"
                value={parseFloat(sideNavFormulaData?.bonus?.value).toString()}
                onFocus={(e) => e.target.select()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {sideNavFormulaData?.bonus?.changeType === "percent"
                        ? "%"
                        : "AUD$"}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (
                    numericRegex.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    if (
                      e.target.value <=
                      (sideNavFormulaData?.bonus?.changeType === "percent"
                        ? 100
                        : 999999999)
                    ) {
                      setModalError(false);
                      if (currentHistory !== null) {
                        setCurrentHistory((prev) => ({
                          ...prev,
                          value: parseFloat(e.target.value).toString(),
                        }));
                      }
                      handleFormulaData("bonus", {
                        value: parseFloat(e.target.value).toString(),
                      });
                    }
                  }
                }}
                error={modalError}
                helperText={
                  modalError ? "Bonus value must be greater than 0" : ""
                }
              />
              {changeInfoValue > 0 && (
                <InfoCard
                  text={`Bonus amount of <strong>$${parseFloat(
                    changeInfoValue
                  )?.toLocaleString()}</strong>`}
                />
              )}
            </Grid>
          </Grid>
          <Grid container mt={2} justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                handleFormulaData("bonus", {
                  value: 0,
                  date: sideNavData?.startDate,
                  changeType: "amount",
                });
                setCurrentHistory({
                  value: 0,
                  date: sideNavData?.startDate,
                  changeType: "amount",
                });
              }}
              variant="contained"
              color="inherit"
              className="ml-10"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                const checkError =
                  currentHistory != null
                    ? !numericRegex.test(currentHistory?.value) ||
                      parseFloat(currentHistory?.value) <= 0
                    : !numericRegex.test(sideNavFormulaData?.bonus?.value) ||
                      parseFloat(sideNavFormulaData?.bonus?.value) <= 0;
                if (checkError) {
                  setModalError(true);
                } else {
                  if (currentHistory !== null) {
                    editChange(sideNavFormulaData?.bonus);
                  } else {
                    handleAddFormula("bonus", true, true);
                  }
                }
              }}
              variant="contained"
              color="primary"
              className="ml-10"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      {/* Change Working Hours */}
      <CustomModal
        modalOpen={openModalId === "weeklyHours"}
        setModalOpen={handleModalClose}
        onClose={handleModalClose}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Change Working Hours (weekly)
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Grid container p={2}>
          <Grid item xs={3} className="mt-10">
            <InputLabel className="font-label mb-10">Start Date</InputLabel>
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              style={{ width: "100%" }}
              minDate={dayjs(
                currentHistory != null ? sideNavData?.startDate : minDate
              )}
              format={"MMMM YYYY"}
              maxDate={dayjs(sideNavData?.endDate || durationEnd)}
              allowClear={false}
              size="large"
              value={dayjs(sideNavFormulaData?.weeklyHours?.date ?? minDate)}
              picker="month"
              onChange={(date, dateString) => {
                if (currentHistory != null) {
                  setCurrentHistory((prev) => ({
                    ...prev,
                    date: dayjs(dateString).format("YYYY-MM"),
                  }));
                }
                handleFormulaData("weeklyHours", {
                  date: dayjs(dateString).format("YYYY-MM"),
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ mt: 2 }} className="font-label mb-10">
              New weekly hours (Hrs/week)
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              onFocus={(e) => e.target.select()}
              type="number"
              value={parseFloat(
                sideNavFormulaData?.weeklyHours?.value
              ).toString()}
              onChange={(e) => {
                if (
                  numericRegex.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (e.target.value <= 168) {
                    setModalError(false);
                    if (currentHistory != null) {
                      setCurrentHistory((prev) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }
                    handleFormulaData("weeklyHours", {
                      value: e.target.value,
                    });
                  }
                }
              }}
              error={modalError}
              helperText={
                modalError ? "Weekly hours must be greater than 0" : ""
              }
            ></TextField>
            {changeInfoValue > 0 && (
              <InfoCard
                text={`Initial Weekly Hour = <strong>${
                  sideNavData?.initialValue?.weeklyHours
                }hrs</strong>.<br/>Current Weekly Hour = <strong>${
                  sideNavData?.weeklyHours ??
                  sideNavData?.initialValue?.weeklyHours
                }hrs</strong>.<br/> New Weekly Hour = <strong>${changeInfoValue} hrs</strong> `}
              />
            )}
          </Grid>
          <Grid container mt={3} justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                handleFormulaData("weeklyHours", {
                  value: 0,
                  date: minDate,
                });
                setCurrentHistory({
                  value: 0,
                  date: minDate,
                });
              }}
              variant="outlined"
              color="primary"
              className="ml-10"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                const checkError =
                  currentHistory != null
                    ? !numericRegex.test(currentHistory?.value) ||
                      parseFloat(currentHistory?.value) <= 0
                    : !numericRegex.test(
                        sideNavFormulaData?.weeklyHours?.value
                      ) ||
                      parseFloat(sideNavFormulaData?.weeklyHours?.value) <= 0;
                if (checkError) {
                  setModalError(true);
                } else {
                  if (currentHistory != null) {
                    editChange(sideNavFormulaData?.weeklyHours);
                  } else {
                    handleAddFormula(
                      "weeklyHours",
                      false,
                      sideNavData?.paymentType === "hourly"
                    );
                  }
                }
              }}
              variant="contained"
              color="primary"
              className="ml-10"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      {/* Change Hourly Rate */}
      <CustomModal
        modalOpen={openModalId === "hourlyRate"}
        setModalOpen={handleModalClose}
        onClose={handleModalClose}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Change Hourly Rate(AUD$/hr)
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Grid container p={2}>
          <Grid item xs={3} className="mt-20">
            <InputLabel className="font-label mb-10">Start Date</InputLabel>
            <DatePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              format={"MMMM YYYY"}
              style={{ width: "100%" }}
              minDate={dayjs(
                currentHistory != null ? sideNavData?.startDate : minDate
              )}
              maxDate={dayjs(sideNavData?.endDate || durationEnd)}
              allowClear={false}
              size="large"
              value={dayjs(sideNavFormulaData?.hourlyRate?.date ?? minDate)}
              picker="month"
              onChange={(date, dateString) => {
                if (currentHistory != null) {
                  setCurrentHistory((prev) => ({
                    ...prev,
                    date: dayjs(dateString).format("YYYY-MM"),
                  }));
                }
                handleFormulaData("hourlyRate", {
                  date: dayjs(dateString).format("YYYY-MM"),
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ mt: 2 }} className="font-label mb-10">
              New Hourly Rate ($/hr)
            </InputLabel>
            <TextField
              fullWidth
              size="small"
              onFocus={(e) => e.target.select()}
              type="number"
              value={parseFloat(
                sideNavFormulaData?.hourlyRate?.value
              ).toString()}
              onChange={(e) => {
                if (
                  numericRegex.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  if (e.target.value <= 9999999) {
                    setModalError(false);
                    if (currentHistory != null) {
                      setCurrentHistory((prev) => ({
                        ...prev,
                        value: e.target.value,
                      }));
                    }
                    handleFormulaData("hourlyRate", {
                      value: e.target.value,
                    });
                  }
                }
              }}
              error={modalError}
              helperText={
                modalError ? "Hourly rate must be greater than 0" : ""
              }
            ></TextField>
            {changeInfoValue > 0 && (
              <InfoCard
                text={`Initial Hourly Rate = <strong>${
                  sideNavData?.initialValue?.hourlyRate
                }$/hr</strong>.<br/>Current Hourly Rate = <strong>${
                  sideNavData?.hourlyRate ??
                  sideNavData?.initialValue?.hourlyRate
                }$/hr</strong>.<br/> New Hourly Rate = <strong>${changeInfoValue}$/hr</strong>`}
              />
            )}
          </Grid>
          <Grid container mt={3} justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                handleFormulaData("hourlyRate", {
                  value: 0,
                  date: minDate,
                });
                setCurrentHistory({
                  value: 0,
                  date: minDate,
                });
              }}
              variant="outlined"
              color="primary"
              className="ml-10"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                const checkError =
                  currentHistory != null
                    ? !numericRegex.test(currentHistory?.value) ||
                      parseFloat(currentHistory?.value) <= 0
                    : !numericRegex.test(
                        sideNavFormulaData?.hourlyRate?.value
                      ) ||
                      parseFloat(sideNavFormulaData?.hourlyRate?.value) <= 0;

                if (checkError) {
                  setModalError(true);
                } else {
                  if (currentHistory != null) {
                    editChange(sideNavFormulaData?.hourlyRate);
                  } else {
                    handleAddFormula("hourlyRate", false, true);
                  }
                }
              }}
              variant="contained"
              color="primary"
              className="ml-10"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      {/* Side Nav */}
      <Drawer anchor={"right"} open={sideNavOpen} onClose={handleCloseSideNav}>
        <Box sx={{ width: "600px", paddingTop: "75px" }}>
          <Grid container className="p-10">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={10}>
                  <Tabs
                    value={currentSideNavTab}
                    onChange={handleChangeCurrentSideNavTab}
                  >
                    <SideNavStyledTab label="Employee Details" />
                    {accountMapping && accountMapping.length > 0 && (
                      <SideNavStyledTab label="Account Mapping" />
                    )}
                  </Tabs>
                </Grid>
                <Grid item xs={2}>
                  <Grid container justifyContent={"flex-end"}>
                    <IconButton onClick={handleCloseSideNav}>
                      <Close />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="p-10">
              <Typography variant="h6" className="font-bold">
                {sideNavData?.metricName}
              </Typography>
            </Grid>

            {currentSideNavTab === 0 && getMetricSettingContent()}
            {currentSideNavTab === 1 &&
              accountMapping &&
              accountMapping.length > 0 &&
              getAccountMappingContent()}
            <Grid item xs={12} className="mt-20 ">
              <Grid
                container
                justifyContent={addEmployeeOpen ? "flex-end" : "space-between"}
              >
                <Grid item display={addEmployeeOpen ? "none" : "block"}>
                  <Button
                    variant="outlined"
                    color={sideNavData?.terminationDate ? "primary" : "error"}
                    disabled={sideNavData?.terminationDate}
                    onClick={() => {
                      setSideNavDataTemp(sideNavData);
                      setTerminationModalOpen(true);
                    }}
                  >
                    {sideNavData?.terminationDate
                      ? "Undo Termination"
                      : "Terminate"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleCloseSideNav}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!!sideNavData?.terminationDate}
                    onClick={() => {
                      const dateError =
                        dayjs(sideNavData?.startDate) >
                        dayjs(sideNavData?.endDate);
                      const detailsError =
                        !sideNavData?.employeeName ||
                        sideNavData?.employeeName?.length < 3 ||
                        /^\s*$/.test(sideNavData?.employeeName) ||
                        !sideNavData?.initialValue?.weeklyHours ||
                        (isHourlyPaymentType
                          ? !sideNavData?.initialValue?.hourlyRate
                          : !sideNavData?.initialValue?.annualSalary) ||
                        dateError;
                      const mappingError = !sideNavData?.mapping;

                      if (currentSideNavTab === 0) {
                        if (!detailsError && mappingError) {
                          setCurrentSideNavTab(1);
                        }
                      } else {
                        if (!mappingError && detailsError) {
                          setCurrentSideNavTab(0);
                        }
                      }

                      const checkError = addEmployeeOpen
                        ? detailsError ||
                          (accountMapping &&
                            accountMapping.length > 0 &&
                            mappingError)
                        : !/^[a-zA-Z0-9 -]+$/.test(sideNavData?.employeeName) ||
                          sideNavData?.employeeName?.length < 3 ||
                          dateError;
                      if (checkError) {
                        setHasError(checkError);
                      } else if (modalValues !== defaultModalValues) {
                        setModalOpen(true);
                      } else {
                        handleSideNavSave(sideNavData, true);
                        handleCloseSideNav();
                      }
                    }}
                    variant="contained"
                    color="primary"
                    className="ml-10"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      {/* Terminate Modal */}
      <CustomModal
        setModalOpen={() => {}}
        onClose={() => setTerminationModalOpen(false)}
        modalOpen={terminationModalOpen}
        overflow={"auto"}
        maxWidth={"md"}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontSize={20} fontWeight={"bold"}>
              Change Hourly Rate(AUD$/hr)
            </Typography>
            <IconButton onClick={handleModalClose}>
              <Close />
            </IconButton>
          </Grid>
          <Divider sx={{ width: "100%", color: ColorPalette.danger }} />
        </Grid>
        <Grid container p={2} pt={2}>
          <Grid item xs={12}>
            <Typography mb={2} variant="body1">
              Are you sure you want to{" "}
              {sideNavData?.terminationDate
                ? "undo termination for "
                : "terminate "}
              <strong>{sideNavData?.employeeName ?? "the employee"}</strong>?
            </Typography>
          </Grid>
          {!sideNavData?.terminationDate && (
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              my={1}
            >
              <Typography pr={2} fontWeight={"bold"} variant="body1">
                Terminated from
              </Typography>
              <DatePicker
                fullWidth
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                format={"MMMM YYYY"}
                style={{ width: "25%" }}
                minDate={dayjs(startDate)}
                maxDate={dayjs(durationEnd)}
                allowClear={false}
                size="large"
                value={dayjs(
                  sideNavDataTemp?.terminationDate ??
                    sideNavDataTemp?.endDate ??
                    durationEnd
                )}
                picker="month"
                onChange={(date) => {
                  handleSideNavTempChanges(
                    "terminationDate",
                    date.format("YYYY-MM-DDT00:00:000")
                  );
                }}
              />
            </Grid>
          )}
          <Grid container mt={3} display={"flex"} justifyContent={"flex-end"}>
            <Button
              onClick={() => {
                setTerminationModalOpen(false);
              }}
              sx={{ width: "80px" }}
              variant="contained"
              color="inherit"
              className="ml-10"
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleEmployeeTermination();
                setTerminationModalOpen(false);
              }}
              sx={{ width: "80px" }}
              variant="contained"
              color="error"
              className="ml-10"
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      <Grid mb={10}>
        {columns?.length !== 0 &&
          headerRow !== null &&
          value?.length > 0 &&
          rows.length >= 2 && (
            <SpreadSheetTable
              rows={rows}
              columns={columns}
              handleCellChange={handleCellChange}
              type="people"
            />
          )}
      </Grid>
    </div>
  );
}
