import { Grid } from "@mui/material";
import {
  XAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
} from "recharts";

export default function HistoryCashFlowBarChart(props) {
  const data = [
    { name: "Opening", uv: 1800, pv: 0 },
    {
      name: "Revenue",
      uv: 800, // uv is the part of the graph we want to show
      pv: 1800, // pv is the floating part (transparent)
    },
    {
      name: "Direct Costs",
      uv: -400,
      pv: 2600, // to get this pv, we use 01/2020's uv + pv
    },
    {
      name: "Other Expenses",
      uv: -400,
      pv: 2200, // use 02/2020's uv + pv, and so forth
    },
    {
      name: "Dividents Paid",
      uv: -300,
      pv: 2600, // to get this pv, we use 01/2020's uv + pv
    },
    {
      name: "Fixed Asset Purchases/Sales",
      uv: -1600,
      pv: 2200, // use 02/2020's uv + pv, and so forth
    },
    { name: "Change in Payables", uv: 800, pv: 1800 },

    { name: "Closing", uv: 2600, pv: 0 },
  ];
  return (
    <Grid item xs={12} sm={12} lg={6.8}>
      <Grid container marginBottom={2}>
        <Grid item xs={12} className="container">
          <ResponsiveContainer width="95%" height={500}>
            <BarChart
              height={500}
              data={data}
              margin={{
                top: 5,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="name"
                style={{
                  fontSize: "0.5rem",
                }}
                textAnchor="end"
                axisLine={false}
                tickLine={false}
              />
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey="pv" stackId="a" fill="transparent" />
              <Bar dataKey="uv" stackId="a" fill="#82ca9d">
                {data.map((item, index) => {
                  if (item.uv < 0) return <Cell key={index} fill="#FC5A5A" />;
                  if (item.name === "Opening" || item.name === "Closing")
                    return <Cell key={index} fill="#0F71FD" />;
                  return <Cell key={index} fill="#27AE60" />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
