import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme, type }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: type ? "#0F71FD" : "#F0142F",
  },
}));

export default function CustomerTile(props) {
  const { topFiveCreditors, averageCollectionDays, totalDueAmount } = props;
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item className="container" xs={12}>
        <Grid container spacing={1} padding={1}>
          <Grid item xs={12}>
            <label className="dashboard-h2">Customers</label>
          </Grid>
          <Grid container padding={1} spacing={4}>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Grid container justifyContent={"space-between"}>
                <Grid item xs={5} md={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <label style={{ color: "grey" }}>Overdue Invoices</label>
                    </Grid>
                    <Grid item xs={12} marginTop={1}>
                      <label className="dashboard-h1">
                        {(totalDueAmount / 1000).toFixed(2)}K
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5} md={12}>
                  <Grid
                    container
                    marginTop={{ xs: 0, sm: 0, md: 4, lg: 4, xl: 4 }}
                  >
                    <Grid item xs={12}>
                      <label style={{ color: "grey" }}>
                        Avg Collection Days
                      </label>
                    </Grid>
                    <Grid item xs={12} marginTop={1}>
                      <label className="dashboard-h1">
                        {parseInt(averageCollectionDays)}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <Grid container>
                <Grid item marginBottom={1}>
                  <label style={{ color: "grey" }}>Top 5 current Debtors</label>
                </Grid>
              </Grid>

              {topFiveCreditors?.map((data) => {
                return (
                  <Grid container marginTop={1} key={data._id}>
                    <Grid item xs={3} sm={1.8} md={1.8} lg={3.5}>
                      <label style={{ color: "grey" }}>{data._id}</label>
                    </Grid>

                    <Grid
                      item
                      xs={9}
                      sm={4.8}
                      md={4.8}
                      lg={4.3}
                      // marginRight={2}
                    >
                      <BorderLinearProgress
                        variant="determinate"
                        value={parseInt(
                          data.dueCount !== 0
                            ? (data.dueCount / data.invoiceCount) * 100
                            : 100
                        )}
                        type={data.dueCount === 0 ? 1 : 0}
                        style={{
                          margin: "5px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sm={4.8}
                      md={4.8}
                      lg={3.8}
                      marginLeft={{ xs: 10, sm: 2 }}
                    >
                      <label
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          marginLeft: "5px",
                          alignSelf: "right",
                        }}
                      >
                        ${data?.invoiceAmount.toFixed(2)} &nbsp;
                      </label>
                      <label style={{ color: "grey", fontSize: "14px" }}>
                        ({data.invoiceCount} Invoices, {data.dueCount} Overdue)
                      </label>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
