import { Grid } from "@mui/material";
import {
  ResponsiveContainer,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { makeStyles } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { createTheme } from "@mui/material/styles";
import noData from "../../../assets/no-data-found.png";

import Paper from "@mui/material/Paper";
import "./BudgetComparision.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router-dom";
import Loading from "../../../components/LoadingIndicator/Loading";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
export default function BudgetComparision() {
  //   const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(budgetComparisionData());
  }, []);
  //   const { isFetching, dataRows: rows } = useSelector(
  //     (state) => state.budgetComparision
  //   );
  const sideBarOpen = useOutletContext().open;

  const xeroConnectionStatus =
    // JSON.parse(
    //   localStorage.getItem("xeroConnectionStatus")
    // );
    true;
  const data1 = [
    /* Add the gap key to each data line; could also be done in a forloop */
    { name: "Jan", uv: 2780, pv: 3908, amt: 2000, gap: 50 },
    { name: "Feb", uv: 1890, pv: 4800, amt: 2181, gap: 50 },
    { name: "Mar", uv: 3000, pv: 1398, amt: 2210, gap: 50 },
    { name: "Apr", uv: 2000, pv: 9800, amt: 2290, gap: 50 },
    { name: "May", uv: 2780, pv: 3908, amt: 2000, gap: 50 },
    { name: "Jun", uv: 4000, pv: 2400, amt: 2400, gap: 50 },
    { name: "Jul", uv: 3000, pv: 1398, amt: 2210, gap: 50 },
    { name: "Aug", uv: 2000, pv: 9800, amt: 2290, gap: 50 },
    { name: "Sep", uv: 2780, pv: 3908, amt: 2000, gap: 50 },
    { name: "Oct", uv: 1890, pv: 4800, amt: 2181, gap: 50 },
    { name: "Nov", uv: 2390, pv: 3800, amt: 2500, gap: 50 },
    { name: "Dec", uv: 3490, pv: 4300, amt: 2100, gap: 50 },
  ];
  const monthFilter = [
    { option: "1 Month", value: 1 },
    { option: "3 Months", value: 3 },
    { option: "6 Months", value: 6 },
    { option: "12 Months", value: 12 },
  ];
  const isFetching = false;
  function createData(
    index,
    name,
    type,
    actual,
    budget,
    variance1,
    variance2,
    pl
  ) {
    return { index, name, type, actual, budget, variance1, variance2, pl };
  }
  const rows = [
    createData(0),
    createData(1, "Revenue", "header"),
    createData(2, "Sales Stream One", "", 159, 6.0, 24, 4.0, "profit"),
    createData(3, "Sales Stream Two", "", 237, 9.0, 37, 4.3, "loss"),
    createData(4, "Sales Stream Three", "", 262, 16.0, 24, 6.0, "profit"),
    createData(5, "Total", "total", 356, 16.0, 49, 3.9, "loss"),
    createData(6, "Direct Costs", "header"),
    createData(7, "Cost One", "", 159, 6.0, 24, 4.0, "profit"),
    createData(8, "Cost Two", "", 237, 9.0, 37, 4.3, "loss"),
    createData(9, "Cost Stream Three", "", 262, 16.0, 24, 6.0, "profit"),
    createData(10, "Total", "total", 356, 16.0, 49, 3.9, "loss"),
    createData(11, "Gross Profit", "header", 356, 16.0, 49, 3.9, "profit"),
    createData(12),
    createData(13, "Other Income", "header"),
    createData(14, "CostThree", "", 262, 16.0, 24, 6.0, "profit"),
    createData(15, "Total", "total", 356, 16.0, 49, 3.9, "loss"),
    createData(16, "Expenses", "header"),
    createData(17, "Expense One", "", 262, 16.0, 24, 6.0, "profit"),
    createData(18, "Expense Two", "", 262, 16.0, 24, 6.0, "loss"),
    createData(19, "Expense Three", "", 262, 16.0, 24, 6.0, "profit"),
    createData(20, "Expense Four", "", 262, 16.0, 24, 6.0, "loss"),
    createData(21, "Expense Five", "", 262, 16.0, 24, 6.0, "profit"),
    createData(22, "Expense Six", "", 262, 16.0, 24, 6.0, "loss"),
    createData(23, "Total", "total", 356, 16.0, 49, 3.9, "profit"),
    createData(24, "Net Profit", "header", 356, 16.0, 49, 3.9, "loss"),
  ];

  const keys = ["uv", "pv"];
  const colors = ["#0F71FD", "#0F71FD"];
  // const theme = createTheme();

  return (
    <div>
      <ToastContainer />
      {!isFetching && xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <h1 id="text-budgetComparision" style={{ color: "#062D65" }}>
                Budget Comparision
              </h1>
            </Grid>
            <Grid item display={"flex"} justifyContent={"center"}>
              <h3>Filter: &nbsp;</h3>
              <FormControl
                sx={{ minWidth: 125, alignSelf: "center" }}
                hiddenLabel
              >
                <InputLabel id="select-label" size="small">
                  Month
                </InputLabel>
                <Select
                  labelId="select-label"
                  size="small"
                  label="Filter"
                  defaultValue={1}
                >
                  {monthFilter.map((data) => {
                    return (
                      <MenuItem key={`month${data.value}`} value={data.value}>
                        {data.option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={4} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid className="container" item xs={12}>
                  <Grid container padding={{ xs: 0, sm: 2 }}>
                    <div className="budget-table">
                      <Paper
                        sx={{
                          border: 0,
                          borderTop: 0,
                          borderRadius: 0,
                          boxShadow: "none",
                        }}
                      >
                        <TableContainer className="table-width">
                          <Table
                            className="table"
                            aria-label="simple table"
                            size={"small"}
                            sx={{
                              [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell
                                  align="right"
                                  className="table-cell-header"
                                >
                                  Budget
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="table-cell-header"
                                >
                                  Actual
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="table-cell-header"
                                >
                                  Variance&nbsp;$
                                </TableCell>
                                <TableCell
                                  align="right"
                                  className="table-cell-header"
                                >
                                  Variance&nbsp;%
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows?.map((row) => (
                                <TableRow
                                  key={row.index}
                                  sx={{
                                    "td, th": {
                                      fontSize:
                                        row.type === "header"
                                          ? "medium"
                                          : "normal",
                                      fontWeight:
                                        row.type === "total" ||
                                        row.type === "header"
                                          ? 700
                                          : 500,
                                    },
                                  }}
                                >
                                  {row.type === "header" && (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      align="left"
                                      className="sticky"
                                    >
                                      {row.name}
                                    </TableCell>
                                  )}
                                  {row.type !== "header" && (
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="sticky"
                                      sx={{ color: "grey", fontWeight: "bold" }}
                                    >
                                      {row.name}
                                    </TableCell>
                                  )}
                                  <TableCell align="right">
                                    {row.actual}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.budget}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.variance1}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.variance2}{" "}
                                    {!!row.variance2 && (
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          display: "inline",
                                          marginBottom: "-3px",

                                          borderRadius: "4px",
                                          alignItems: "center",
                                        }}
                                      >
                                        {row.pl === "profit" && (
                                          <ArrowDropUpIcon
                                            style={{
                                              marginLeft: "2px",
                                              fontSize: "16px",
                                              color: "#00EACF",
                                            }}
                                          />
                                        )}
                                        {row.pl === "loss" && (
                                          <ArrowDropDownIcon
                                            style={{
                                              marginLeft: "2px",
                                              fontSize: "16px",
                                              color: "#F0142F",
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container marginBottom={4}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  xl={12}
                  md={12}
                  className="container"
                  padding={2}
                >
                  <Grid container padding={1}>
                    <Grid item xs={12} md={12}>
                      <label className="dashboard-h2">
                        Monthly Comparision
                      </label>
                    </Grid>
                    <ResponsiveContainer width={"99%"} height={800}>
                      <BarChart
                        data={data1}
                        margin={{ top: 20, bottom: 5 }}
                        layout="vertical"
                      >
                        <Tooltip />

                        <XAxis
                          type="number"
                          axisLine={false}
                          tickLine={false}
                        />
                        <YAxis
                          type="category"
                          dataKey="name"
                          axisLine={false}
                          tickLine={false}
                        />
                        {keys.map((key, index) => {
                          const bars = [];
                          bars.push(
                            <Bar
                              dataKey={key}
                              stackId="a"
                              fill={colors[index]}
                              isAnimationActive={false}
                            />
                          );
                          if (key === "uv") {
                            bars.push(
                              <Bar
                                dataKey="gap"
                                stackId="a"
                                fill="#FFC700"
                                isAnimationActive={false}
                              />
                            );
                          }
                          return bars;
                        })}
                      </BarChart>
                    </ResponsiveContainer>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      {!isFetching && !xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            spacing={4}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={noData}
                alt="Logo"
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Typography variant="h5">No Data Found</Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {isFetching && <Loading sideBarOpen={sideBarOpen} />}
    </div>
  );
}
