import mixpanel from "mixpanel-browser";

let actions = {
  track: (name, props) => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    mixpanel.track(name, props);
  },
};

export const Mixpanel = actions;
