import { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useOutletContext } from "react-router-dom";
// import { cashPositionData } from "../../../redux/CashPosition/CashPositionSlice";
import Loading from "../../../components/LoadingIndicator/Loading";
import EstimatedWithPlusMinus from "./CashPositionTiles/EstimatedFutureCashTiles/EstimatedWithPlusMinus";
import HistoryCashFlowBarChart from "./CashPositionTiles/HistoricalCashFlowGraphTile/HistoryCashFlowBarChart";
import noData from "../../../assets/no-data-found.png";

import "./CashPosition.css";
import { useEffect } from "react";

export default function CashPosition() {
  const [filter] = useState(0);

  const isFetching = false;

  const monthFilter = [
    { option: "1 Month", value: 1 },
    { option: "3 Months", value: 3 },
    { option: "6 Months", value: 6 },
    { option: "12 Months", value: 12 },
  ];
  const xeroConnectionStatus =
    // JSON.parse(
    //   localStorage.getItem("xeroConnectionStatus")
    // );
    true;
  const sideBarOpen = useOutletContext().open;
  // const data = [
  //   { name: "Opening", uv: 1800, pv: 0 },
  //   {
  //     name: "Revenue",
  //     uv: 800,
  //     pv: 1800,
  //   },
  //   {
  //     name: "Direct Costs",
  //     uv: -400,
  //     pv: 2600,
  //   },
  //   {
  //     name: "Other Expenses",
  //     uv: -400,
  //     pv: 2200,
  //   },
  //   {
  //     name: "Dividents Paid",
  //     uv: -300,
  //     pv: 2600,
  //   },
  //   {
  //     name: "Fixed Asset Purchases/Sales",
  //     uv: -1600,
  //     pv: 2200,
  //   },
  //   { name: "Change in Payables", uv: 800, pv: 1800 },

  //   { name: "Closing", uv: 2600, pv: 0 },
  // ];

  useEffect(() => {
    // dispatch(cashPositionData(filter));
  }, [filter]);

  return (
    <div>
      {!isFetching && xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <div>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <h1 id="text-estimatedFutureCashPosition" style={{ color: "#062D65" }}>
                  Estimated Future Cash Position
                </h1>
              </Grid>
              <Grid item display={"flex"} justifyContent={"center"}>
                <h3>Filter: &nbsp;</h3>
                <FormControl
                  sx={{ minWidth: 125, alignSelf: "center" }}
                  hiddenLabel
                >
                  <InputLabel id="select-label" size="small">
                    Month
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    size="small"
                    label="Filter"
                    defaultValue={1}
                  >
                    {monthFilter.map((data) => {
                      return (
                        <MenuItem key={`month${data.value}`} value={data.value}>
                          {data.option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <EstimatedWithPlusMinus />
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <h1 style={{ color: "#062D65" }}>Historical Cashflow</h1>
              </Grid>
              <Grid item display={"flex"} justifyContent={"center"}>
                <h3>Filter: &nbsp;</h3>
                <FormControl
                  sx={{ minWidth: 125, alignSelf: "center" }}
                  hiddenLabel
                >
                  <InputLabel id="select-label" size="small">
                    Month
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    size="small"
                    label="Filter"
                    defaultValue={1}
                  >
                    {monthFilter.map((data) => {
                      return (
                        <MenuItem key={`month${data.value}`} value={data.value}>
                          {data.option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container marginTop={1} justifyContent={"space-between"}>
              <HistoryCashFlowBarChart />
              <Grid item xs={12} sm={12} lg={5}>
                <Grid
                  container
                  // marginLeft={{ lg: 2, xs: 0 }}
                  // spacing={2}
                  marginBottom={2}
                >
                  <Grid item xs={12} className="container" padding={2}>
                    <Grid container xs={12}>
                      <label className="dashboard-h2">Action Points</label>
                    </Grid>
                    <p style={{ color: "grey" }}>
                      If you do not receive any more income, you will run out of
                      cash in <b>32 Days</b>.
                    </p>
                    <p style={{ color: "grey" }}>
                      There are currently 6 debtors which are outside their
                      regular payment terms. If these had been paid on time then
                      you would have another <b>$42,768</b>. Click on 'Estimated
                      Income' above to view the outstanding amounts.
                    </p>
                    <p style={{ color: "grey" }}>
                      You have 8 upcoming payments due in the next 13 days which
                      total <b>$34.000</b>. You also have 3 overdue payments
                      which total $3,000. Click on 'Estimated Payables' above to
                      view the upcoming payments.
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {!isFetching && !xeroConnectionStatus && (
        <div style={{ marginLeft: "5px" }}>
          <Grid
            container
            spacing={4}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={noData}
                alt="Logo"
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <Typography variant="h5">No Data Found</Typography>
            </Grid>
          </Grid>
        </div>
      )}

      {isFetching && <Loading sideBarOpen={sideBarOpen} />}
    </div>
  );
}
