import { Grid } from "@mui/material";

export default function ExpenseTile() {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={12}>
        <label className="dashboard-h2">Expense Watchlist</label>
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={5}>
            <label style={{ color: "grey" }}>Advertising</label>
          </Grid>
          <Grid item xs={5}>
            <label style={{ color: "grey" }}>$6302.49</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={5}>
            <label style={{ color: "grey" }}>Wages</label>
          </Grid>
          <Grid item xs={5}>
            <label style={{ color: "grey" }}>$16302.49</label>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={4}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={5}>
            <label style={{ color: "grey" }}>Subscriptions</label>
          </Grid>
          <Grid item xs={5}>
            <label style={{ color: "grey" }}>$1302.49</label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
