const colors = [
  "#00EACF",
  "#0F71FD",
  "#050331",
  "#7E84A3",
  "#FFC700",
  "#E6E8F6",
  "#F0142F",
  "#6FAAFE",
  "#33EED9",
  "#3795BD",
  "#FC2947",
  "#002F29",
  "#696883",
  "#A5D7E8",
  "#C9EEFF",
  "#020114",
  "#06A561",
  "#062D65",
  "#FC5A5A",
  "#062D65",
  "#576CBC",
  "#AA77FF",
  "#3A1078",
];

module.exports = { colors };
