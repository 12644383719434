import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { Grid } from "@mui/material";

export default function RevenueLineChart(props) {
  const { plDataChart, filter } = props;
  const type = {
    revenue: "Revenue",
    grossProfit: "Gross Profit",
    netProfit: "Net Profit",
  };
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={12} className="container">
        <Grid container padding={1} marginTop={1} paddingBottom={4}>
          <Grid item xs={12} marginBottom={4}>
            <label className="dashboard-h2">{type[filter]}</label>
          </Grid>
          <ResponsiveContainer width="99%" height={280}>
            <LineChart
              margin={{ top: 20, right: 30, left: 5, bottom: 5 }}
              data={plDataChart[filter]}
            >
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} />
              <Tooltip />
              <CartesianGrid strokeDasharray="3 3" />

              <Legend
                wrapperStyle={{ top: -10 }}
                verticalAlign="top"
                align="left"
                iconType={"square"}
                formatter={(value, entry, index) => (
                  <span
                    style={{
                      color: "black",
                      fontSize: "16px",
                      marginTop: "5px",
                    }}
                  >
                    {value}
                  </span>
                )}
              />
              <Line
                type="linear"
                dataKey="This Year"
                stroke="#0F71FD"
                strokeWidth={2}
                dot={false}
              />
              <Line
                type="linear"
                dataKey="Last Year"
                stroke="#00EACF"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
