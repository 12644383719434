import { Grid } from "@mui/material";

export default function HighlightedExpenseTile(props) {
  const { highlightedExpenses } = props;

  return (
    <Grid
      item
      className="container"
      container
      lg={6}
      marginBottom={2}
      justifyContent={"space-between"}
    >
      <Grid container marginTop={2} padding={1} spacing={1}>
        <Grid item xs={12}>
          <label className="dashboard-h2">Highlighted Expenses</label>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12} lg={12} marginTop={6}></Grid>

          {highlightedExpenses.map((data, index) => {
            return (
              <Grid
                key={`${data} ${index}header`}
                item
                xs={11}
                marginTop={2}
                lg={12}
              >
                <label style={{ color: "grey" }}>{data.type}</label>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={11} marginTop={1} style={{ textAlign: "right" }}>
            <label style={{ color: "grey" }}>This Month</label>
          </Grid>
          {highlightedExpenses.map((data, index) => {
            return (
              <Grid
                key={`${data}${index}ThisMonth`}
                item
                xs={12}
                marginTop={2}
                style={{ textAlign: "right" }}
              >
                <h6>$ {data.value}</h6>
              </Grid>
            );
          })}
        </Grid>
        <Grid item xs={5}>
          <Grid item xs={11} marginTop={1} style={{ textAlign: "right" }}>
            <label style={{ color: "grey" }}>% of Revenue</label>
          </Grid>
          {highlightedExpenses.map((data, index) => {
            return (
              <Grid
                key={`${data}${index}revenue`}
                item
                xs={11}
                marginTop={1}
                style={{ textAlign: "right" }}
              >
                <h6>{data.percentile}%</h6>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}
