import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./User/UserSlice";
import profitabilityReducer from "./Profitability/ProfitabilitySlice";
import dashboardReducer from "./Dashboard/DashboardSlice";
import budgetComparisionReducer from "./BudgetComparision/BudgetComparisionSlice";
import settingReducer from "./Setting/SettingSlice";
import xeroReducer from "./Xero/XeroSlice";
import clientSpaceReducer from "./ClientSpace/ClientSpaceSlice";
import notificationReducer from "./Notification/NotificationSlice";
import revenueReducer from "./Revenue/RevenueSlice";
import expenseReducer from "./Expense/ExpenseSlice";
import spreadsheetReducer from "./SpreadSheet/SpreadSheetSlice";
import profitAndLossReducer from "./ProfitandLoss/ProfitandLossSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    profitability: profitabilityReducer,
    dashboard: dashboardReducer,
    budgetComparision: budgetComparisionReducer,
    setting: settingReducer,
    xero: xeroReducer,
    clientSpaces: clientSpaceReducer,
    notification: notificationReducer,
    revenue: revenueReducer,
    expense: expenseReducer,
    spreadsheet: spreadsheetReducer,
    profitAndLoss: profitAndLossReducer,
  },
});

export default store;
