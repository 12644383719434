import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import xeroLogo from "../../../assets/xero.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEntity,
  updateOnboardingStep,
} from "../../../redux/ClientSpace/ClientSpaceSlice";
import { getTenants } from "../../../redux/Xero/XeroSlice";
import axios from "axios";
import Loading from "../../../components/LoadingIndicator/Loading";
import CheckIcon from "@mui/icons-material/Check";
import XeroAuthentication from "../../xero_auth/XeroAuthentication/XeroAuthentication";
import FinancialSettings from "../Settings/components/FinancialSettings";

export default function Onboarding() {
  const dispatch = useDispatch();
  const { clientSpaceId, entityId, accountingFirmId } = useParams();
  const [tenantId, setTenantId] = useState("");
  const [financialSettingsSaved, setFinancialSettingsSaved] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getEntity({ entityId, accountingFirmId }));
    dispatch(getTenants({ entityId, accountingFirmId }));
  }, [entityId, dispatch, accountingFirmId]);

  const { currentEntity: data, isFetching } = useSelector(
    (state) => state.clientSpaces
  );
  const { tenants: tenantData, isFetching: tenantDataFetching } = useSelector(
    (state) => state.xero
  );

  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    setActiveStep(data?.onBoardingStep ?? 0);
  }, [data?.onBoardingStep]);
  const steps = [
    "Xero Connection",
    "Select Organisation",
    "Financial Settings",
    "Completed",
  ];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    let email = localStorage.getItem("email");
    dispatch(
      updateOnboardingStep({
        step: activeStep + 1,
        entityId,
        tenant: tenantId,
        email,
        accountingFirmId,
        clientSpaceId,
      })
    );
    if (activeStep === 3) {
      navigate(
        `/accountingFirm/${accountingFirmId}/clientSpace/${clientSpaceId}/entities`,
        { state: { isLoggedOut: true } }
      );
    }
  };

  const xero = async () => {
    const token = localStorage.getItem("accessToken");
    localStorage.setItem("entityId", entityId);
    localStorage.setItem("clientSpaceId", clientSpaceId);
    localStorage.setItem("accountingFirmId", accountingFirmId);
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios({
      method: "post",
      url:
        process.env.REACT_APP_BASE_API_URL +
        `/accountingFirm/${accountingFirmId}/xero/connect`,
      headers: headers,
    });

    window.location.href = response.data.url;
  };

  const XeroConnectionView = () => {
    return (
      <Grid container mt={3}>
        <div>
          <Typography variant="h6" fontWeight="600" sx={{ mb: 2 }}>
            Connect your Xero Account
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Integrate your Xero account and connect your data with a click.
          </Typography>
        </div>
        <Grid container marginTop={5}>
          <Paper
            elevation={3}
            sx={{ padding: 2, textAlign: "center", marginRight: 7 }}
          >
            <Avatar
              src={xeroLogo}
              alt="Image 1"
              sx={{ width: 80, height: 80, mx: "auto", mb: 2 }}
            />
            {!data?.xeroTokens && (
              <Button
                style={{ border: "2px solid #0F71FD" }}
                color="primary"
                size="small"
                aria-label="Add"
                onClick={xero}
              >
                <Typography variant="body5">Connect &nbsp;</Typography>
                <AddIcon fontSize={"20"} />
              </Button>
            )}
            {data?.xeroTokens && (
              <Button
                style={{ border: "2px solid #07bc0c" }}
                color="success"
                size="small"
                aria-label="Add"
              >
                <Typography variant="body5">Connected &nbsp;</Typography>
                <CheckIcon fontSize={"20"} />
              </Button>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  };

  const OnboardingFinalView = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ marginTop: "200px", textAlign: "center" }}>
            Onboarding completed. You will be notified once the data is
            successfully added to the database.
            <br />
            Thank you for your patience.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ backgroundColor: "#008080", color: "white" }}
            onClick={handleNext}
          >
            Finish
          </Button>
        </div>
      </>
    );
  };

  const FetchXeroView = () => {
    return <XeroAuthentication setTenantId={setTenantId} tenantId={tenantId} />;
  };

  const getViewBasedOnStep = (step) => {
    switch (step) {
      case 0:
        return <XeroConnectionView />;
      case 1:
        return <FetchXeroView />;
      case 2:
        return (
          <>
            {tenantDataFetching ? (
              <Loading />
            ) : (
              <FinancialSettings
                {...data}
                setFinancialSettingsSaved={setFinancialSettingsSaved}
                orgData={tenantData[0]?.orgData}
                isOnboarding={true}
              />
            )}
          </>
        );
      case 3:
        return <OnboardingFinalView />;
      default:
        return null;
    }
  };

  return (
    <div>
      {!isFetching && (
        <div style={{ margin: "3%" }}>
          <Grid container>
            <Grid
              item
              borderRadius={2}
              sm={12}
              height="70vh"
              overflow={"auto"}
              className="container"
              padding={5}
            >
              {getViewBasedOnStep(activeStep)}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              marginTop: 5,
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              style={{ borderRadius: 0, fontSize: 16 }}
              size="small"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              <ArrowBackIosNewIcon style={{ marginRight: 10 }} fontSize="20" />
              Back
            </IconButton>
            {(activeStep === 0 || activeStep === 1 || activeStep === 2) && (
              <>
                <Stepper
                  sx={{
                    width: "70%",
                  }}
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <IconButton
                  style={{ borderRadius: 0, fontSize: 16 }}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    !data?.xeroTokens ||
                    (activeStep === 1 && tenantId === "") ||
                    (activeStep === 2 && !financialSettingsSaved)
                  }
                >
                  Next
                  <ArrowForwardIosIcon
                    style={{ marginLeft: 10 }}
                    fontSize="20"
                  />
                </IconButton>
              </>
            )}
          </Grid>
        </div>
      )}
      {isFetching && <Loading />}
    </div>
  );
}
