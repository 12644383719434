import React from "react";

const SortableGrid = ({ children, columns }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        maxWidth: "700px",
        maxHeight: "200px",

        // margin: "100px auto",
      }}
    >
      {children}
    </div>
  );
};

export default SortableGrid;
