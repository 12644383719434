import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { forwardRef } from "react";

const Item = forwardRef(
  (
    { id, disabled, onDelete, name, withOpacity, isDragging, style, ...props },
    ref
  ) => {
    const inlineStyles = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      height: "40px",
      maxWidth: "200px",
      minWidth: "30px",
      borderRadius: "10px",
      cursor: disabled ? "default" : isDragging ? "grabbing" : "grab",
      backgroundColor: "#ffffff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px",
      transform: isDragging ? "scale(1.05)" : "scale(1)",
      ...style,
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <p
          style={{
            marginLeft: "10px",
            width: "150px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </p>
        <IconButton
          disabled={!disabled}
          onClick={() => {
            onDelete();
          }}
        >
          <Close />
        </IconButton>
      </div>
    );
  }
);

export default Item;
